import { makeStyles } from '@mui/styles';

const bottomNavBarStyles = makeStyles((theme) => ({
  root: {
    top: 'auto',
    bottom: 0,
    padding: theme.spacing(3),
    background: theme.palette.specifics.bottomNavbarRootBackground,
    // zIndex: 999,
  },
  copyright: {
    fontDisplay: 'swap',
    fontFamily: 'Roboto,sans-serif',
    color: '#636363',
    '& a': {
      color: '#a4a4a4',
      textDecoration: 'none',
    },
  },
}));

export default bottomNavBarStyles;
