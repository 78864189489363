import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  purposes: [],
  purposesFetched: false,
  loadingPurposes: false,
  errorPurposes: false,
  errorMessagePurposes: '',
  sourceOfIncomes: [],
  sourceOfIncomesFetched: false,
  loadingSourceOfIncomes: false,
  errorSourceOfIncomes: false,
  errorMessageSourceOfIncomes: '',
  idTypes: [],
  idTypesFetched: false,
  loadingIdTypes: false,
  errorIdTypes: false,
  errorMessageIdTypes: '',
  loading: false,
  error: false,
  errorMessage: '',
};

const listingSlice = createSlice({
  name: 'listing',
  initialState,
  reducers: {
    setLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.error = false;
        state.errorMessage = '';
      }
      state.loading = isLoading;
    },
    setError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    setPurposes(state, action) {
      state.purposes = action.payload;
      state.purposesFetched = true;
      state.errorPurposes = false;
      state.errorMessagePurposes = '';
      state.loadingPurposes = false;
    },
    setPurposesLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.errorPurposes = false;
        state.errorMessagePurposes = '';
      }
      state.loadingPurposes = isLoading;
    },
    setPurposesError(state, action) {
      state.purposes = [];
      state.purposesFetched = true;
      state.errorPurposes = true;
      state.errorMessagePurposes = action.payload;
      state.loadingPurposes = false;
    },
    setSourceOfIncomes(state, action) {
      state.sourceOfIncomes = action.payload;
      state.sourceOfIncomesFetched = true;
      state.errorSourceOfIncomes = false;
      state.errorMessageSourceOfIncomes = '';
      state.loadingSourceOfIncomes = false;
    },
    setSourceOfIncomesLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.errorSourceOfIncomes = false;
        state.errorMessageSourceOfIncomes = '';
      }
      state.loadingSourceOfIncomes = isLoading;
    },
    setSourceOfIncomesError(state, action) {
      state.sourceOfIncomes = [];
      state.sourceOfIncomesFetched = true;
      state.errorSourceOfIncomes = true;
      state.errorMessageSourceOfIncomes = action.payload;
      state.loadingSourceOfIncomes = false;
    },
    setIdTypes(state, action) {
      state.idTypes = action.payload;
      state.idTypesFetched = true;
      state.errorIdTypes = false;
      state.errorMessageIdTypes = '';
      state.loadingIdTypes = false;
    },
    setIdTypesLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.errorIdTypes = false;
        state.errorMessageIdTypes = '';
      }
      state.loadingIdTypes = isLoading;
    },
    setIdTypesError(state, action) {
      state.idTypes = [];
      state.idTypesFetched = true;
      state.errorIdTypes = true;
      state.errorMessageIdTypes = action.payload;
      state.loadingIdTypes = false;
    },
    resetListings: (state) => initialState,
    logout: (state) => initialState,
  },
});

export const listingActions = listingSlice.actions;
export default listingSlice;
