import Jsona from 'jsona';
import axios from 'axios';

import { appActions } from '../slices/app-slice';
import http from '../api/http';
import config from '../../config';

export const getClientIpAddress = () => {
  const request = axios.get('https://api.ipify.org?format=json');

  return (dispatch) => {
    return request.then(
      ({ data }) => {
        dispatch(appActions.setClientIpAddress(data.ip));
      },
      (error) => {
        let errorMessage = config.generalError;

        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (error.response.data.message && typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (error.response.data.errors.message && typeof error.response.data.errors.message === 'string') {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Get client ip address failed!';
          }
        }

        console.log('App getClientIpAddress error: ' + errorMessage);
      }
    );
  };
};

export const checkTransactionsInComplianceStatus = () => {
  const apiBaseUrl = config.REACT_APP_IDENTITY_BASE_URL + config.REACT_APP_REMITTANCE;

  const params = {
    page: {
      size: 1000,
      number: 1,
    },
    filter: {
      statusid: '512,513,514',
    },
  };

  const request = http.get(apiBaseUrl, { params });

  return (dispatch) => {
    return request.then(
      ({ data }) => {
        const transactionsData = new Jsona().deserialize(data);

        dispatch(
          appActions.setTransactionsInCompliancePending(!!transactionsData && transactionsData.filter((t) => t.statusId === 514).length > 0)
        );
        dispatch(appActions.setTransactionsInComplianceHold(!!transactionsData && transactionsData.length > 0));
      },
      (error) => {
        let errorMessage = config.generalError;

        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (error.response.data.message && typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (error.response.data.errors.message && typeof error.response.data.errors.message === 'string') {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Fetch transaction failed!';
          }
        }

        console.log('App checkTransactionsInComplianceStatus error: ' + errorMessage);

        // dispatch(appActions.setError(errorMessage));
      }
    );
  };
};

export const setAppIsMobile = (isMobile) => {
  return (dispatch) => {
    dispatch(appActions.setIsMobile(isMobile));
  };
};

export const setAppNotification = (notificationMessage, notificationSeverity) => {
  return (dispatch) => {
    dispatch(appActions.setNotification({ notificationMessage, notificationSeverity }));
  };
};

export const resetAppNotification = (isMobile) => {
  return (dispatch) => {
    dispatch(appActions.resetNotification());
  };
};
