import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  createdUser: {},
  loading: false,
  error: false,
  errorMessage: '',
  userCreated: false,
  userUpdated: false,
  documentUploaded: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.error = false;
        state.errorMessage = '';
      }
      state.loading = isLoading;
    },
    setError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    setCreatedUser(state, action) {
      state.createdUser = action.payload;
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
      state.userCreated = true;
    },
    setUpdatedUser(state, action) {
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
      state.userUpdated = true;
    },
    setDocumentUploaded(state, action) {
      state.documentUploaded = true;
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    clearCRUD(state, action) {
      state.userCreated = {};
      state.userCreated = false;
      state.userUpdated = false;
      state.documentUploaded = false;
    },
    clearError(state, action) {
      state.error = false;
      state.errorMessage = '';
    },
    clearUser: (state) => initialState,
  },
});

export const userActions = userSlice.actions;
export default userSlice;
