import config from '../config';

export const Favicon = () => {
  const tenant = config.REACT_APP_TENANT + config.REACT_APP_TENANT_SUFFIX;
  return require(`../assets/tenants/${tenant}/favicon.ico`).default;
};

export const HeaderLogo = (light = false) => {
  const tenant = config.REACT_APP_TENANT + config.REACT_APP_TENANT_SUFFIX;
  return require(`../assets/tenants/${tenant}/header_logo${light ? '_light' : ''}.png`).default;
};

export const MobileHeaderLogo = (light = false) => {
  const tenant = config.REACT_APP_TENANT + config.REACT_APP_TENANT_SUFFIX;
  return require(`../assets/tenants/${tenant}/mobile_header_logo${light ? '_light' : ''}.png`).default;
};

export const LoginRootBackground = () => {
  const tenant = config.REACT_APP_TENANT + config.REACT_APP_TENANT_SUFFIX;
  return require(`../assets/tenants/${tenant}/images/login_root_background.jpg`).default;
};

export const RegisterRootBackground = () => {
  const tenant = config.REACT_APP_TENANT + config.REACT_APP_TENANT_SUFFIX;
  return require(`../assets/tenants/${tenant}/images/register_root_background.jpg`).default;
};

export const HomeRootBackground = (isLoggedIn) => {
  const tenant = config.REACT_APP_TENANT + config.REACT_APP_TENANT_SUFFIX;
  return require(`../assets/tenants/${tenant}/images/${isLoggedIn ? 'logged_' : ''}home_root_background.jpg`).default;
};

export const SendMoneyRootBackground = () => {
  const tenant = config.REACT_APP_TENANT + config.REACT_APP_TENANT_SUFFIX;
  return require(`../assets/tenants/${tenant}/images/sendMoney_root_background.jpg`).default;
};

export const RecipientsRootBackground = () => {
  const tenant = config.REACT_APP_TENANT + config.REACT_APP_TENANT_SUFFIX;
  return require(`../assets/tenants/${tenant}/images/recipients_root_background.jpg`).default;
};

export const TransactionsRootBackground = () => {
  const tenant = config.REACT_APP_TENANT + config.REACT_APP_TENANT_SUFFIX;
  return require(`../assets/tenants/${tenant}/images/transactions_root_background.jpg`).default;
};

export const PrivacyPolicyRootBackground = () => {
  const tenant = config.REACT_APP_TENANT + config.REACT_APP_TENANT_SUFFIX;
  return require(`../assets/tenants/${tenant}/images/privacyPolicy_root_background.jpg`).default;
};

export const TermsAndConditionsRootBackground = () => {
  const tenant = config.REACT_APP_TENANT + config.REACT_APP_TENANT_SUFFIX;
  return require(`../assets/tenants/${tenant}/images/termsAndConditions_root_background.jpg`).default;
};

export const WhySendWithUsImage = () => {
  return require(`../assets/images/marketing/why_send_with_us.png`).default;
};

export const WhoWeAreImage = () => {
  return require(`../assets/images/marketing/who_we_are.png`).default;
};
