import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paymentLoading: false,
  paymentError: false,
  paymentErrorMessage: '',
  isPaymentPublic: false,
};

const pspSlice = createSlice({
  name: 'psp',
  initialState,
  reducers: {
    setPaymentLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.paymentError = false;
        state.paymentErrorMessage = '';
      }
      state.paymentLoading = isLoading;
    },
    setPaymentErrorMessage(state, action) {
      state.paymentErrorMessage = action.payload;
      state.paymentError = true;
      state.paymentLoading = false;
    },
    clearPaymentErrorMessage(state, action) {
      state.paymentErrorMessage = '';
      state.paymentError = false;
      state.paymentLoading = false;
    },
    setIsPaymentPublic(state, action) {
      state.isPaymentPublic = true;
    },
    clearPSP: (state) => initialState,
    logout: (state) => initialState,
  },
});

export const pspActions = pspSlice.actions;
export default pspSlice;
