import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppBar, Divider, Stack, Typography, useTheme } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';

import { fetchCountriesFromLibrary } from '../../../store/actions/location-actions';
import { fetchLocalCountries } from '../../../store/actions/rate-actions';

import bottomNavBarStyles from './bottomNavBarStyles';
import { HeaderLogo, MobileHeaderLogo } from '../../../assets/assetSelector';
import { isEmpty } from '../../../library/utils';
import config from '../../../config';

const BottomNavBar = () => {
  const dispatch = useDispatch();
  const styles = bottomNavBarStyles();
  const theme = useTheme();

  const isMobile = useSelector((state) => state.app.isMobile);
  const receivingCountriesFetched = useSelector((state) => state.location.receivingCountriesFetched);
  const receivingCountries = useSelector((state) => state.location.receivingCountries);
  const localCountriesFetched = useSelector((state) => state.rate.localCountriesFetched);
  const localCountries = useSelector((state) => state.rate.localCountries);

  const [sendToCountries, setSendToCountries] = useState([]);

  useEffect(() => {
    if (isEmpty(receivingCountries) && !receivingCountriesFetched) {
      dispatch(fetchCountriesFromLibrary('RECEIVING'));
    } else if (config.REACT_APP_FX_RATE_MODE === 'LOCAL' && isEmpty(localCountries) && !localCountriesFetched) {
      dispatch(fetchLocalCountries());
    } else if (
      !isEmpty(receivingCountries) &&
      (config.REACT_APP_FX_RATE_MODE !== 'LOCAL' || (config.REACT_APP_FX_RATE_MODE === 'LOCAL' && !isEmpty(localCountries)))
    ) {
      const filteredCountries = [];

      receivingCountries.forEach((c) => {
        if (config.REACT_APP_FX_RATE_MODE !== 'LOCAL' || localCountries.indexOf(c.id.toString()) > -1) {
          filteredCountries.push({ ...c });
        }
      });

      setSendToCountries([...filteredCountries]);
    }
  }, [receivingCountries, receivingCountriesFetched, localCountries, localCountriesFetched, dispatch]);

  return (
    <StyledEngineProvider injectFirst>
      <AppBar enableColorOnDark position={isMobile || !theme.config.isBottomNavbarFixed ? 'relative' : 'fixed'} className={styles.root}>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            divider={<Divider sx={{ borderColor: theme.palette.specifics.footerDividerColor }} flexItem />}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent={{
                xs: 'flex-start',
                sm: isEmpty(sendToCountries) ? 'space-evenly' : 'space-between',
              }}
              alignItems="flex-start"
              spacing={3}
              my={3}
              sx={{ width: '100%' }}
            >
              {!isEmpty(sendToCountries) && (
                <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: isMobile ? '100%' : 'unset' }}>
                  <Typography variant="h5" sx={{ color: theme.palette.specifics.footerItemColor }}>
                    Send Money Now
                  </Typography>
                  <Divider
                    sx={{
                      borderColor: theme.palette.specifics.footerDividerColor,
                    }}
                    flexItem
                  />
                  {sendToCountries.map((c, index) => {
                    return (
                      <Typography
                        variant="h7"
                        component={Link}
                        to={
                          '/remit/' +
                          (config.REACT_APP_REMIT_PAGE_MODE === 'PUBLIC' ? '' : 'quote/') +
                          c.countryName.split(' ').join('').toLowerCase()
                        }
                        sx={{
                          textDecoration: 'none',
                          color: theme.palette.specifics.footerItemColor,
                        }}
                        key={index}
                      >
                        Remit money to {c.countryName}
                      </Typography>
                    );
                  })}
                </Stack>
              )}
              <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: isMobile ? '100%' : 'unset' }}>
                <Typography variant="h5" sx={{ color: theme.palette.specifics.footerItemColor }}>
                  Help
                </Typography>
                <Divider
                  sx={{
                    borderColor: theme.palette.specifics.footerDividerColor,
                  }}
                  flexItem
                />
                <Typography
                  variant="h7"
                  component="a"
                  href={config.REACT_APP_FOOTER_HOW_IT_WORKS}
                  sx={{
                    textDecoration: 'none',
                    color: theme.palette.specifics.footerItemColor,
                  }}
                >
                  How it works
                </Typography>
                <Typography
                  variant="h7"
                  component="a"
                  href={config.REACT_APP_FOOTER_DOWNLOAD}
                  sx={{
                    textDecoration: 'none',
                    color: theme.palette.specifics.footerItemColor,
                  }}
                >
                  Download
                </Typography>
              </Stack>
              <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: isMobile ? '100%' : 'unset' }}>
                <Typography variant="h5" sx={{ color: theme.palette.specifics.footerItemColor }}>
                  About us
                </Typography>
                <Divider
                  sx={{
                    borderColor: theme.palette.specifics.footerDividerColor,
                  }}
                  flexItem
                />
                <Typography
                  variant="h7"
                  component="a"
                  href={config.REACT_APP_FOOTER_WHO_WE_ARE}
                  sx={{
                    textDecoration: 'none',
                    color: theme.palette.specifics.footerItemColor,
                  }}
                >
                  Who we are
                </Typography>
                <Typography
                  variant="h7"
                  component="a"
                  href={config.REACT_APP_FOOTER_CONTACT_US}
                  sx={{
                    textDecoration: 'none',
                    color: theme.palette.specifics.footerItemColor,
                  }}
                >
                  Contact us
                </Typography>
              </Stack>
              <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: isMobile ? '100%' : 'unset' }}>
                <Typography variant="h5" sx={{ color: theme.palette.specifics.footerItemColor }}>
                  Legal
                </Typography>
                <Divider
                  sx={{
                    borderColor: theme.palette.specifics.footerDividerColor,
                  }}
                  flexItem
                />
                <Typography
                  variant="h7"
                  component={Link}
                  to="/about/termsandconditions"
                  sx={{
                    textDecoration: 'none',
                    color: theme.palette.specifics.footerItemColor,
                  }}
                >
                  Terms and conditions
                </Typography>
                <Typography
                  variant="h7"
                  component={Link}
                  to="/about/privacypolicy"
                  sx={{
                    textDecoration: 'none',
                    color: theme.palette.specifics.footerItemColor,
                  }}
                >
                  Privacy policy
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="center"
              alignItems={{ xs: 'center', sm: 'flex-start' }}
              spacing={{ sm: 1, md: 5 }}
              my={3}
            >
              <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
                <Typography
                  align="center"
                  width={{ xs: '100%', sm: '450px', md: '600px', lg: '750px' }}
                  sx={{ color: theme.palette.specifics.footerItemColor }}
                >
                  {config.REACT_APP_FOOTER_ADDRESS}
                </Typography>
                <Typography
                  align="center"
                  width={{ xs: '100%', sm: '450px', md: '600px', lg: '750px' }}
                  sx={{ color: theme.palette.specifics.footerItemColor }}
                >
                  {config.REACT_APP_FOOTER_MAIN_LABEL}
                </Typography>
                {config.REACT_APP_FOOTER_CONTACT_NUMBER && (
                  <Typography
                    align="center"
                    width={{
                      xs: '100%',
                      sm: '450px',
                      md: '600px',
                      lg: '750px',
                    }}
                    sx={{ color: theme.palette.specifics.footerItemColor }}
                  >
                    {config.REACT_APP_FOOTER_CONTACT_NUMBER}
                  </Typography>
                )}
              </Stack>
              <Stack direction="column" justifyContent="center" alignItems="center" spacing={0}>
                <Link to="/">
                  {isMobile ? (
                    <img src={MobileHeaderLogo()} alt="home" height="50" />
                  ) : (
                    <img src={HeaderLogo(theme.config.headerLogoLight)} alt="home" height="50" />
                  )}
                </Link>
                {config.REACT_APP_FOOTER_SHOW_COPYRIGHT === 'true' && (
                  <div className={styles.copyright}>
                    <Typography>
                      {isMobile && 'Copyright '}©
                      <a href={config.REACT_APP_TENANT_WEBSITE} style={{ marginLeft: '5px' }}>
                        {config.REACT_APP_TENANT_TITLE}
                      </a>
                      {isMobile && ' | All rights reserved.'}
                    </Typography>
                  </div>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </AppBar>
    </StyledEngineProvider>
  );
};

export default BottomNavBar;
