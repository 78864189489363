import { configureStore, combineReducers } from '@reduxjs/toolkit';

import appSlice from './slices/app-slice';
import authSlice from './slices/auth-slice';
import bankSlice from './slices/bank-slice';
import authProcessorSlice from './slices/authProcessor-slice';
import kycSlice from './slices/kyc-slice';
import listingSlice from './slices/listing-slice';
import locationSlice from './slices/location-slice';
import otpSlice from './slices/otp-slice';
import pinSlice from './slices/pin-slice';
import productSlice from './slices/product-slice';
import pspSlice from './slices/psp-slice';
import questionnaireSlice from './slices/questionnaire-slice';
import quoteSlice from './slices/quote-slice';
import rateSlice from './slices/rate-slice';
import recipientSlice from './slices/recipient-slice';
import remitSlice from './slices/remit-slice';
import transactionSlice from './slices/transaction-slice';
import userSlice from './slices/user-slice';
import nameValidatorSlice from './slices/name-validator-slice';
import config from '../config';

const reducer = combineReducers({
    app: appSlice.reducer,
    auth: authSlice.reducer,
    bank: bankSlice.reducer,
    authProcessor: authProcessorSlice.reducer,
    kyc: kycSlice.reducer,
    listing: listingSlice.reducer,
    location: locationSlice.reducer,
    otp: otpSlice.reducer,
    pin: pinSlice.reducer,
    product: productSlice.reducer,
    psp: pspSlice.reducer,
    questionnaire: questionnaireSlice.reducer,
    quote: quoteSlice.reducer,
    rate: rateSlice.reducer,
    recipient: recipientSlice.reducer,
    remit: remitSlice.reducer,
    transaction: transactionSlice.reducer,
    user: userSlice.reducer,
    nameValidator: nameValidatorSlice.reducer,
});

const store = configureStore({
    reducer: (state, action) => {
        if (action.type === 'auth/logout') {
            state = undefined;
        }
        return reducer(state, action);
    },
    devTools: config.REACT_APP_REDUX_DEVTOOLS_ENABLED === 'true', // Hide redux in production
});

export default store;
