import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Slide, Snackbar, Toolbar, Typography, useTheme } from '@mui/material';

import TopNavBar from './TopNavBar/TopNavBar';
import BottomNavBar from './BottomNavBar/BottomNavBar';
import Router from '../../router';

import { resetAppNotification } from '../../store/actions/app-actions';

import mainLayoutStyles from './mainLayoutStyles';

const MainLayout = () => {
    const styles = mainLayoutStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const userPending = useSelector((state) => state.auth.userPending);
    const userProfilePending = useSelector((state) => state.auth.userProfilePending);
    const isPinRequired = useSelector((state) => state.app.isPinRequired);
    const isAccountPinSet = useSelector((state) => state.auth.isAccountPinSet);
    const kycPending = useSelector((state) => state.auth.kycPending);
    const kycQnAPending = useSelector((state) => state.auth.kycQnAPending);
    const nationalIdInvalid = useSelector((state) => state.auth.nationalIdInvalid);
    const forceLinkAuthProcessor = useSelector((state) => state.auth.forceLinkAuthProcessor);
    const transactionsInCompliancePending = useSelector((state) => state.app.transactionsInCompliancePending);
    const transactionsInComplianceHold = useSelector((state) => state.app.transactionsInComplianceHold);
    const notificationOpen = useSelector((state) => state.app.notificationOpen);
    const notificationSeverity = useSelector((state) => state.app.notificationSeverity);
    const notificationMessage = useSelector((state) => state.app.notificationMessage);

    const handleCloseNotification = () => {
        dispatch(resetAppNotification());
    };

    return (
        <div className={styles.root}>
            <TopNavBar />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={notificationOpen}
                autoHideDuration={5000}
                onClose={handleCloseNotification}
                TransitionComponent={Slide}
            >
                <Alert onClose={handleCloseNotification} severity={notificationSeverity} sx={{ width: '100%' }}>
                    {notificationMessage}
                </Alert>
            </Snackbar>
            {(userPending ||
                kycPending ||
                kycQnAPending ||
                userProfilePending ||
                (isPinRequired && !isAccountPinSet) ||
                nationalIdInvalid ||
                forceLinkAuthProcessor ||
                transactionsInComplianceHold) && (
                <div
                    style={{
                        position: theme.config.isTopNavbarFixed ? 'fixed' : 'relative',
                        width: '100%',
                        zIndex: '2',
                    }}
                >
                    {theme.config.isTopNavbarFixed && <Toolbar sx={{ margin: '16px' }} />}
                    <Alert severity="info">
                        {(userPending ||
                            kycPending ||
                            kycQnAPending ||
                            userProfilePending ||
                            nationalIdInvalid ||
                            forceLinkAuthProcessor) && (
                            <Typography>
                                Your account is in pending status.
                                {kycPending ? (
                                    <>
                                        {' '}
                                        Please go to <Link to="/account/documents">account/documents</Link> to upload your KYC document.
                                    </>
                                ) : kycQnAPending ? (
                                    <>
                                        {' '}
                                        Please go to <Link to="/account/questionnaire">account/questionnaire</Link> to complete KYC form.
                                    </>
                                ) : userProfilePending ? (
                                    <>
                                        {' '}
                                        Please go to <Link to="/account/profile">account/profile</Link> to update your profile.
                                    </>
                                ) : nationalIdInvalid ? (
                                    <>
                                        {' '}
                                        Invalid National ID number. Please go to <Link to="/account/documents">account/documents</Link> and
                                        upload your KYC document.
                                    </>
                                ) : forceLinkAuthProcessor ? (
                                    <>
                                        {' '}
                                        Identity provider not linked. Please go to <Link to="/account/settings">account/settings</Link> and
                                        link your account.
                                    </>
                                ) : (
                                    ' You will receive a notification once the admin approved.'
                                )}
                            </Typography>
                        )}
                        {transactionsInCompliancePending && (
                            <Typography>
                                You have a transaction requiring additional document/s. Please go to{' '}
                                <Link to="/account/transactions">account/transactions</Link> to upload the necessary document/s.
                            </Typography>
                        )}
                        {isPinRequired && !isAccountPinSet && (
                            <Typography>
                                Your PIN is not yet set. Please go to <Link to="/account/settings">account/settings</Link> to set your PIN.
                            </Typography>
                        )}
                        {transactionsInComplianceHold && !!!transactionsInCompliancePending && (
                            <Typography>You have a transaction pending compliance approval.</Typography>
                        )}
                    </Alert>
                </div>
            )}
            <Router />
            <BottomNavBar />
        </div>
    );
};

export default MainLayout;
