import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  questionnaireSubmitted: false,
  loading: false,
  error: false,
  errorMessage: '',
};

const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    setQuestionnaireSubmitted(state) {
      state.questionnaireSubmitted = true;
      state.loading = false;
      state.error = false;
      state.errorMessage = '';
    },
    setLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.error = false;
        state.errorMessage = '';
      }
      state.loading = isLoading;
    },
    setError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    clearError(state, action) {
      state.error = false;
      state.errorMessage = '';
    },
    clearQuestionnaire: (state) => initialState,
  },
});

export const questionnaireActions = questionnaireSlice.actions;
export default questionnaireSlice;
