import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  received: false,
  success: false,
  invalid: false,
};

const otpSlice = createSlice({
  name: 'otp',
  initialState,
  reducers: {
    setLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.error = false;
        state.errorMessage = '';
      }
      state.loading = isLoading;
    },
    setError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    setOTPReceived(state, action) {
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
      state.received = true;
      state.success = false;
    },
    setOTPSuccess(state, action) {
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
      state.received = true;
      state.success = true;
      state.invalid = false;
    },
    setOTPInvalid(state, action) {
      state.error = true;
      state.errorMessage = action.payload;
      state.loading = false;
      state.received = true;
      state.success = false;
      state.invalid = true;
    },
    clearOTPInvalid(state, action) {
      state.error = false;
      state.errorMessage = '';
      state.invalid = false;
    },
    clearOTP: (state) => initialState,
    logout: (state) => initialState,
  },
});

export const otpActions = otpSlice.actions;
export default otpSlice;
