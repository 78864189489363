var CountryDialIsoFlags = [
  {
    name: 'Afghanistan',
    dialCode: '+93',
    isoCode: 'AF',
    flag: 'https://www.countryflags.io/AF/flat/64.png',
  },
  {
    name: 'Aland Islands',
    dialCode: '+358',
    isoCode: 'AX',
    flag: 'https://www.countryflags.io/AX/flat/64.png',
  },
  {
    name: 'Albania',
    dialCode: '+355',
    isoCode: 'AL',
    flag: 'https://www.countryflags.io/AL/flat/64.png',
  },
  {
    name: 'Algeria',
    dialCode: '+213',
    isoCode: 'DZ',
    flag: 'https://www.countryflags.io/DZ/flat/64.png',
  },
  {
    name: 'American Samoa',
    dialCode: '+1684',
    isoCode: 'AS',
    flag: 'https://www.countryflags.io/AS/flat/64.png',
  },
  {
    name: 'Andorra',
    dialCode: '+376',
    isoCode: 'AD',
    flag: 'https://www.countryflags.io/AD/flat/64.png',
  },
  {
    name: 'Angola',
    dialCode: '+244',
    isoCode: 'AO',
    flag: 'https://www.countryflags.io/AO/flat/64.png',
  },
  {
    name: 'Anguilla',
    dialCode: '+1264',
    isoCode: 'AI',
    flag: 'https://www.countryflags.io/AI/flat/64.png',
  },
  {
    name: 'Antarctica',
    dialCode: '+672',
    isoCode: 'AQ',
    flag: 'https://www.countryflags.io/AQ/flat/64.png',
  },
  {
    name: 'Antigua and Barbuda',
    dialCode: '+1268',
    isoCode: 'AG',
    flag: 'https://www.countryflags.io/AG/flat/64.png',
  },
  {
    name: 'Argentina',
    dialCode: '+54',
    isoCode: 'AR',
    flag: 'https://www.countryflags.io/AR/flat/64.png',
  },
  {
    name: 'Armenia',
    dialCode: '+374',
    isoCode: 'AM',
    flag: 'https://www.countryflags.io/AM/flat/64.png',
  },
  {
    name: 'Aruba',
    dialCode: '+297',
    isoCode: 'AW',
    flag: 'https://www.countryflags.io/AW/flat/64.png',
  },
  {
    name: 'Ascension Island',
    dialCode: '+247',
    isoCode: 'AC',
    flag: 'https://www.countryflags.io/AC/flat/64.png',
  },
  {
    name: 'Australia',
    dialCode: '+61',
    isoCode: 'AU',
    flag: 'https://www.countryflags.io/AU/flat/64.png',
  },
  {
    name: 'Austria',
    dialCode: '+43',
    isoCode: 'AT',
    flag: 'https://www.countryflags.io/AT/flat/64.png',
  },
  {
    name: 'Azerbaijan',
    dialCode: '+994',
    isoCode: 'AZ',
    flag: 'https://www.countryflags.io/AZ/flat/64.png',
  },
  {
    name: 'Bahamas',
    dialCode: '+1242',
    isoCode: 'BS',
    flag: 'https://www.countryflags.io/BS/flat/64.png',
  },
  {
    name: 'Bahrain',
    dialCode: '+973',
    isoCode: 'BH',
    flag: 'https://www.countryflags.io/BH/flat/64.png',
  },
  {
    name: 'Bangladesh',
    dialCode: '+880',
    isoCode: 'BD',
    flag: 'https://www.countryflags.io/BD/flat/64.png',
  },
  {
    name: 'Barbados',
    dialCode: '+1246',
    isoCode: 'BB',
    flag: 'https://www.countryflags.io/BB/flat/64.png',
  },
  {
    name: 'Belarus',
    dialCode: '+375',
    isoCode: 'BY',
    flag: 'https://www.countryflags.io/BY/flat/64.png',
  },
  {
    name: 'Belgium',
    dialCode: '+32',
    isoCode: 'BE',
    flag: 'https://www.countryflags.io/BE/flat/64.png',
  },
  {
    name: 'Belize',
    dialCode: '+501',
    isoCode: 'BZ',
    flag: 'https://www.countryflags.io/BZ/flat/64.png',
  },
  {
    name: 'Benin',
    dialCode: '+229',
    isoCode: 'BJ',
    flag: 'https://www.countryflags.io/BJ/flat/64.png',
  },
  {
    name: 'Bermuda',
    dialCode: '+1441',
    isoCode: 'BM',
    flag: 'https://www.countryflags.io/BM/flat/64.png',
  },
  {
    name: 'Bhutan',
    dialCode: '+975',
    isoCode: 'BT',
    flag: 'https://www.countryflags.io/BT/flat/64.png',
  },
  {
    name: 'Bolivia',
    dialCode: '+591',
    isoCode: 'BO',
    flag: 'https://www.countryflags.io/BO/flat/64.png',
  },
  {
    name: 'Bosnia and Herzegovina',
    dialCode: '+387',
    isoCode: 'BA',
    flag: 'https://www.countryflags.io/BA/flat/64.png',
  },
  {
    name: 'Botswana',
    dialCode: '+267',
    isoCode: 'BW',
    flag: 'https://www.countryflags.io/BW/flat/64.png',
  },
  {
    name: 'Brazil',
    dialCode: '+55',
    isoCode: 'BR',
    flag: 'https://www.countryflags.io/BR/flat/64.png',
  },
  {
    name: 'British Indian Ocean Territory',
    dialCode: '+246',
    isoCode: 'IO',
    flag: 'https://www.countryflags.io/IO/flat/64.png',
  },
  {
    name: 'Brunei Darussalam',
    dialCode: '+673',
    isoCode: 'BN',
    flag: 'https://www.countryflags.io/BN/flat/64.png',
  },
  {
    name: 'Bulgaria',
    dialCode: '+359',
    isoCode: 'BG',
    flag: 'https://www.countryflags.io/BG/flat/64.png',
  },
  {
    name: 'Burkina Faso',
    dialCode: '+226',
    isoCode: 'BF',
    flag: 'https://www.countryflags.io/BF/flat/64.png',
  },
  {
    name: 'Burundi',
    dialCode: '+257',
    isoCode: 'BI',
    flag: 'https://www.countryflags.io/BI/flat/64.png',
  },
  {
    name: 'Cambodia',
    dialCode: '+855',
    isoCode: 'KH',
    flag: 'https://www.countryflags.io/KH/flat/64.png',
  },
  {
    name: 'Cameroon',
    dialCode: '+237',
    isoCode: 'CM',
    flag: 'https://www.countryflags.io/CM/flat/64.png',
  },
  {
    name: 'Canada',
    dialCode: '+1',
    isoCode: 'CA',
    flag: 'https://www.countryflags.io/CA/flat/64.png',
  },
  {
    name: 'Cape Verde',
    dialCode: '+238',
    isoCode: 'CV',
    flag: 'https://www.countryflags.io/CV/flat/64.png',
  },
  {
    name: 'Cayman Islands',
    dialCode: '+1345',
    isoCode: 'KY',
    flag: 'https://www.countryflags.io/KY/flat/64.png',
  },
  {
    name: 'Central African Republic',
    dialCode: '+236',
    isoCode: 'CF',
    flag: 'https://www.countryflags.io/CF/flat/64.png',
  },
  {
    name: 'Chad',
    dialCode: '+235',
    isoCode: 'TD',
    flag: 'https://www.countryflags.io/TD/flat/64.png',
  },
  {
    name: 'Chile',
    dialCode: '+56',
    isoCode: 'CL',
    flag: 'https://www.countryflags.io/CL/flat/64.png',
  },
  {
    name: 'China',
    dialCode: '+86',
    isoCode: 'CN',
    flag: 'https://www.countryflags.io/CN/flat/64.png',
  },
  {
    name: 'Christmas Island',
    dialCode: '+61',
    isoCode: 'CX',
    flag: 'https://www.countryflags.io/CX/flat/64.png',
  },
  {
    name: 'Cocos (Keeling) Islands',
    dialCode: '+61',
    isoCode: 'CC',
    flag: 'https://www.countryflags.io/CC/flat/64.png',
  },
  {
    name: 'Colombia',
    dialCode: '+57',
    isoCode: 'CO',
    flag: 'https://www.countryflags.io/CO/flat/64.png',
  },
  {
    name: 'Comoros',
    dialCode: '+269',
    isoCode: 'KM',
    flag: 'https://www.countryflags.io/KM/flat/64.png',
  },
  {
    name: 'Congo',
    dialCode: '+242',
    isoCode: 'CG',
    flag: 'https://www.countryflags.io/CG/flat/64.png',
  },
  {
    name: 'Cook Islands',
    dialCode: '+682',
    isoCode: 'CK',
    flag: 'https://www.countryflags.io/CK/flat/64.png',
  },
  {
    name: 'Costa Rica',
    dialCode: '+506',
    isoCode: 'CR',
    flag: 'https://www.countryflags.io/CR/flat/64.png',
  },
  {
    name: 'Croatia',
    dialCode: '+385',
    isoCode: 'HR',
    flag: 'https://www.countryflags.io/HR/flat/64.png',
  },
  {
    name: 'Cuba',
    dialCode: '+53',
    isoCode: 'CU',
    flag: 'https://www.countryflags.io/CU/flat/64.png',
  },
  {
    name: 'Cyprus',
    dialCode: '+357',
    isoCode: 'CY',
    flag: 'https://www.countryflags.io/CY/flat/64.png',
  },
  {
    name: 'Czech Republic',
    dialCode: '+420',
    isoCode: 'CZ',
    flag: 'https://www.countryflags.io/CZ/flat/64.png',
  },
  {
    name: 'Democratic Republic of the Congo',
    dialCode: '+243',
    isoCode: 'CD',
    flag: 'https://www.countryflags.io/CD/flat/64.png',
  },
  {
    name: 'Denmark',
    dialCode: '+45',
    isoCode: 'DK',
    flag: 'https://www.countryflags.io/DK/flat/64.png',
  },
  {
    name: 'Djibouti',
    dialCode: '+253',
    isoCode: 'DJ',
    flag: 'https://www.countryflags.io/DJ/flat/64.png',
  },
  {
    name: 'Dominica',
    dialCode: '+1767',
    isoCode: 'DM',
    flag: 'https://www.countryflags.io/DM/flat/64.png',
  },
  {
    name: 'Dominican Republic',
    dialCode: '+1849',
    isoCode: 'DO',
    flag: 'https://www.countryflags.io/DO/flat/64.png',
  },
  {
    name: 'Ecuador',
    dialCode: '+593',
    isoCode: 'EC',
    flag: 'https://www.countryflags.io/EC/flat/64.png',
  },
  {
    name: 'Egypt',
    dialCode: '+20',
    isoCode: 'EG',
    flag: 'https://www.countryflags.io/EG/flat/64.png',
  },
  {
    name: 'El Salvador',
    dialCode: '+503',
    isoCode: 'SV',
    flag: 'https://www.countryflags.io/SV/flat/64.png',
  },
  {
    name: 'Equatorial Guinea',
    dialCode: '+240',
    isoCode: 'GQ',
    flag: 'https://www.countryflags.io/GQ/flat/64.png',
  },
  {
    name: 'Eritrea',
    dialCode: '+291',
    isoCode: 'ER',
    flag: 'https://www.countryflags.io/ER/flat/64.png',
  },
  {
    name: 'Estonia',
    dialCode: '+372',
    isoCode: 'EE',
    flag: 'https://www.countryflags.io/EE/flat/64.png',
  },
  {
    name: 'Eswatini',
    dialCode: '+268',
    isoCode: 'SZ',
    flag: 'https://www.countryflags.io/SZ/flat/64.png',
  },
  {
    name: 'Ethiopia',
    dialCode: '+251',
    isoCode: 'ET',
    flag: 'https://www.countryflags.io/ET/flat/64.png',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    dialCode: '+500',
    isoCode: 'FK',
    flag: 'https://www.countryflags.io/FK/flat/64.png',
  },
  {
    name: 'Faroe Islands',
    dialCode: '+298',
    isoCode: 'FO',
    flag: 'https://www.countryflags.io/FO/flat/64.png',
  },
  {
    name: 'Fiji',
    dialCode: '+679',
    isoCode: 'FJ',
    flag: 'https://www.countryflags.io/FJ/flat/64.png',
  },
  {
    name: 'Finland',
    dialCode: '+358',
    isoCode: 'FI',
    flag: 'https://www.countryflags.io/FI/flat/64.png',
  },
  {
    name: 'France',
    dialCode: '+33',
    isoCode: 'FR',
    flag: 'https://www.countryflags.io/FR/flat/64.png',
  },
  {
    name: 'French Guiana',
    dialCode: '+594',
    isoCode: 'GF',
    flag: 'https://www.countryflags.io/GF/flat/64.png',
  },
  {
    name: 'French Polynesia',
    dialCode: '+689',
    isoCode: 'PF',
    flag: 'https://www.countryflags.io/PF/flat/64.png',
  },
  {
    name: 'Gabon',
    dialCode: '+241',
    isoCode: 'GA',
    flag: 'https://www.countryflags.io/GA/flat/64.png',
  },
  {
    name: 'Gambia',
    dialCode: '+220',
    isoCode: 'GM',
    flag: 'https://www.countryflags.io/GM/flat/64.png',
  },
  {
    name: 'Georgia',
    dialCode: '+995',
    isoCode: 'GE',
    flag: 'https://www.countryflags.io/GE/flat/64.png',
  },
  {
    name: 'Germany',
    dialCode: '+49',
    isoCode: 'DE',
    flag: 'https://www.countryflags.io/DE/flat/64.png',
  },
  {
    name: 'Ghana',
    dialCode: '+233',
    isoCode: 'GH',
    flag: 'https://www.countryflags.io/GH/flat/64.png',
  },
  {
    name: 'Gibraltar',
    dialCode: '+350',
    isoCode: 'GI',
    flag: 'https://www.countryflags.io/GI/flat/64.png',
  },
  {
    name: 'Greece',
    dialCode: '+30',
    isoCode: 'GR',
    flag: 'https://www.countryflags.io/GR/flat/64.png',
  },
  {
    name: 'Greenland',
    dialCode: '+299',
    isoCode: 'GL',
    flag: 'https://www.countryflags.io/GL/flat/64.png',
  },
  {
    name: 'Grenada',
    dialCode: '+1473',
    isoCode: 'GD',
    flag: 'https://www.countryflags.io/GD/flat/64.png',
  },
  {
    name: 'Guadeloupe',
    dialCode: '+590',
    isoCode: 'GP',
    flag: 'https://www.countryflags.io/GP/flat/64.png',
  },
  {
    name: 'Guam',
    dialCode: '+1671',
    isoCode: 'GU',
    flag: 'https://www.countryflags.io/GU/flat/64.png',
  },
  {
    name: 'Guatemala',
    dialCode: '+502',
    isoCode: 'GT',
    flag: 'https://www.countryflags.io/GT/flat/64.png',
  },
  {
    name: 'Guernsey',
    dialCode: '+44',
    isoCode: 'GG',
    flag: 'https://www.countryflags.io/GG/flat/64.png',
  },
  {
    name: 'Guinea',
    dialCode: '+224',
    isoCode: 'GN',
    flag: 'https://www.countryflags.io/GN/flat/64.png',
  },
  {
    name: 'Guinea-Bissau',
    dialCode: '+245',
    isoCode: 'GW',
    flag: 'https://www.countryflags.io/GW/flat/64.png',
  },
  {
    name: 'Guyana',
    dialCode: '+592',
    isoCode: 'GY',
    flag: 'https://www.countryflags.io/GY/flat/64.png',
  },
  {
    name: 'Haiti',
    dialCode: '+509',
    isoCode: 'HT',
    flag: 'https://www.countryflags.io/HT/flat/64.png',
  },
  {
    name: 'Holy See (Vatican City State)',
    dialCode: '+379',
    isoCode: 'VA',
    flag: 'https://www.countryflags.io/VA/flat/64.png',
  },
  {
    name: 'Honduras',
    dialCode: '+504',
    isoCode: 'HN',
    flag: 'https://www.countryflags.io/HN/flat/64.png',
  },
  {
    name: 'Hong Kong',
    dialCode: '+852',
    isoCode: 'HK',
    flag: 'https://www.countryflags.io/HK/flat/64.png',
  },
  {
    name: 'Hungary',
    dialCode: '+36',
    isoCode: 'HU',
    flag: 'https://www.countryflags.io/HU/flat/64.png',
  },
  {
    name: 'Iceland',
    dialCode: '+354',
    isoCode: 'IS',
    flag: 'https://www.countryflags.io/IS/flat/64.png',
  },
  {
    name: 'India',
    dialCode: '+91',
    isoCode: 'IN',
    flag: 'https://www.countryflags.io/IN/flat/64.png',
  },
  {
    name: 'Indonesia',
    dialCode: '+62',
    isoCode: 'ID',
    flag: 'https://www.countryflags.io/ID/flat/64.png',
  },
  {
    name: 'Iran',
    dialCode: '+98',
    isoCode: 'IR',
    flag: 'https://www.countryflags.io/IR/flat/64.png',
  },
  {
    name: 'Iraq',
    dialCode: '+964',
    isoCode: 'IQ',
    flag: 'https://www.countryflags.io/IQ/flat/64.png',
  },
  {
    name: 'Ireland',
    dialCode: '+353',
    isoCode: 'IE',
    flag: 'https://www.countryflags.io/IE/flat/64.png',
  },
  {
    name: 'Isle of Man',
    dialCode: '+44',
    isoCode: 'IM',
    flag: 'https://www.countryflags.io/IM/flat/64.png',
  },
  {
    name: 'Israel',
    dialCode: '+972',
    isoCode: 'IL',
    flag: 'https://www.countryflags.io/IL/flat/64.png',
  },
  {
    name: 'Italy',
    dialCode: '+39',
    isoCode: 'IT',
    flag: 'https://www.countryflags.io/IT/flat/64.png',
  },
  {
    name: "Ivory Coast / Cote d'Ivoire",
    dialCode: '+225',
    isoCode: 'CI',
    flag: 'https://www.countryflags.io/CI/flat/64.png',
  },
  {
    name: 'Jamaica',
    dialCode: '+1876',
    isoCode: 'JM',
    flag: 'https://www.countryflags.io/JM/flat/64.png',
  },
  {
    name: 'Japan',
    dialCode: '+81',
    isoCode: 'JP',
    flag: 'https://www.countryflags.io/JP/flat/64.png',
  },
  {
    name: 'Jersey',
    dialCode: '+44',
    isoCode: 'JE',
    flag: 'https://www.countryflags.io/JE/flat/64.png',
  },
  {
    name: 'Jordan',
    dialCode: '+962',
    isoCode: 'JO',
    flag: 'https://www.countryflags.io/JO/flat/64.png',
  },
  {
    name: 'Kazakhstan',
    dialCode: '+77',
    isoCode: 'KZ',
    flag: 'https://www.countryflags.io/KZ/flat/64.png',
  },
  {
    name: 'Kenya',
    dialCode: '+254',
    isoCode: 'KE',
    flag: 'https://www.countryflags.io/KE/flat/64.png',
  },
  {
    name: 'Kiribati',
    dialCode: '+686',
    isoCode: 'KI',
    flag: 'https://www.countryflags.io/KI/flat/64.png',
  },
  {
    name: 'North Korea',
    dialCode: '+850',
    isoCode: 'KP',
    flag: 'https://www.countryflags.io/KP/flat/64.png',
  },
  {
    name: 'South Korea',
    dialCode: '+82',
    isoCode: 'KR',
    flag: 'https://www.countryflags.io/KR/flat/64.png',
  },
  {
    name: 'Kosovo',
    dialCode: '+383',
    isoCode: 'XK',
    flag: 'https://www.countryflags.io/XK/flat/64.png',
  },
  {
    name: 'Kuwait',
    dialCode: '+965',
    isoCode: 'KW',
    flag: 'https://www.countryflags.io/KW/flat/64.png',
  },
  {
    name: 'Kyrgyzstan',
    dialCode: '+996',
    isoCode: 'KG',
    flag: 'https://www.countryflags.io/KG/flat/64.png',
  },
  {
    name: 'Laos',
    dialCode: '+856',
    isoCode: 'LA',
    flag: 'https://www.countryflags.io/LA/flat/64.png',
  },
  {
    name: 'Latvia',
    dialCode: '+371',
    isoCode: 'LV',
    flag: 'https://www.countryflags.io/LV/flat/64.png',
  },
  {
    name: 'Lebanon',
    dialCode: '+961',
    isoCode: 'LB',
    flag: 'https://www.countryflags.io/LB/flat/64.png',
  },
  {
    name: 'Lesotho',
    dialCode: '+266',
    isoCode: 'LS',
    flag: 'https://www.countryflags.io/LS/flat/64.png',
  },
  {
    name: 'Liberia',
    dialCode: '+231',
    isoCode: 'LR',
    flag: 'https://www.countryflags.io/LR/flat/64.png',
  },
  {
    name: 'Libya',
    dialCode: '+218',
    isoCode: 'LY',
    flag: 'https://www.countryflags.io/LY/flat/64.png',
  },
  {
    name: 'Liechtenstein',
    dialCode: '+423',
    isoCode: 'LI',
    flag: 'https://www.countryflags.io/LI/flat/64.png',
  },
  {
    name: 'Lithuania',
    dialCode: '+370',
    isoCode: 'LT',
    flag: 'https://www.countryflags.io/LT/flat/64.png',
  },
  {
    name: 'Luxembourg',
    dialCode: '+352',
    isoCode: 'LU',
    flag: 'https://www.countryflags.io/LU/flat/64.png',
  },
  {
    name: 'Macau',
    dialCode: '+853',
    isoCode: 'MO',
    flag: 'https://www.countryflags.io/MO/flat/64.png',
  },
  {
    name: 'Madagascar',
    dialCode: '+261',
    isoCode: 'MG',
    flag: 'https://www.countryflags.io/MG/flat/64.png',
  },
  {
    name: 'Malawi',
    dialCode: '+265',
    isoCode: 'MW',
    flag: 'https://www.countryflags.io/MW/flat/64.png',
  },
  {
    name: 'Malaysia',
    dialCode: '+60',
    isoCode: 'MY',
    flag: 'https://www.countryflags.io/MY/flat/64.png',
  },
  {
    name: 'Maldives',
    dialCode: '+960',
    isoCode: 'MV',
    flag: 'https://www.countryflags.io/MV/flat/64.png',
  },
  {
    name: 'Mali',
    dialCode: '+223',
    isoCode: 'ML',
    flag: 'https://www.countryflags.io/ML/flat/64.png',
  },
  {
    name: 'Malta',
    dialCode: '+356',
    isoCode: 'MT',
    flag: 'https://www.countryflags.io/MT/flat/64.png',
  },
  {
    name: 'Marshall Islands',
    dialCode: '+692',
    isoCode: 'MH',
    flag: 'https://www.countryflags.io/MH/flat/64.png',
  },
  {
    name: 'Martinique',
    dialCode: '+596',
    isoCode: 'MQ',
    flag: 'https://www.countryflags.io/MQ/flat/64.png',
  },
  {
    name: 'Mauritania',
    dialCode: '+222',
    isoCode: 'MR',
    flag: 'https://www.countryflags.io/MR/flat/64.png',
  },
  {
    name: 'Mauritius',
    dialCode: '+230',
    isoCode: 'MU',
    flag: 'https://www.countryflags.io/MU/flat/64.png',
  },
  {
    name: 'Mayotte',
    dialCode: '+262',
    isoCode: 'YT',
    flag: 'https://www.countryflags.io/YT/flat/64.png',
  },
  {
    name: 'Mexico',
    dialCode: '+52',
    isoCode: 'MX',
    flag: 'https://www.countryflags.io/MX/flat/64.png',
  },
  {
    name: 'Micronesia',
    dialCode: '+691',
    isoCode: 'FM',
    flag: 'https://www.countryflags.io/FM/flat/64.png',
  },
  {
    name: 'Moldova',
    dialCode: '+373',
    isoCode: 'MD',
    flag: 'https://www.countryflags.io/MD/flat/64.png',
  },
  {
    name: 'Monaco',
    dialCode: '+377',
    isoCode: 'MC',
    flag: 'https://www.countryflags.io/MC/flat/64.png',
  },
  {
    name: 'Mongolia',
    dialCode: '+976',
    isoCode: 'MN',
    flag: 'https://www.countryflags.io/MN/flat/64.png',
  },
  {
    name: 'Montenegro',
    dialCode: '+382',
    isoCode: 'ME',
    flag: 'https://www.countryflags.io/ME/flat/64.png',
  },
  {
    name: 'Montserrat',
    dialCode: '+1664',
    isoCode: 'MS',
    flag: 'https://www.countryflags.io/MS/flat/64.png',
  },
  {
    name: 'Morocco',
    dialCode: '+212',
    isoCode: 'MA',
    flag: 'https://www.countryflags.io/MA/flat/64.png',
  },
  {
    name: 'Mozambique',
    dialCode: '+258',
    isoCode: 'MZ',
    flag: 'https://www.countryflags.io/MZ/flat/64.png',
  },
  {
    name: 'Myanmar',
    dialCode: '+95',
    isoCode: 'MM',
    flag: 'https://www.countryflags.io/MM/flat/64.png',
  },
  {
    name: 'Namibia',
    dialCode: '+264',
    isoCode: 'NA',
    flag: 'https://www.countryflags.io/NA/flat/64.png',
  },
  {
    name: 'Nauru',
    dialCode: '+674',
    isoCode: 'NR',
    flag: 'https://www.countryflags.io/NR/flat/64.png',
  },
  {
    name: 'Nepal',
    dialCode: '+977',
    isoCode: 'NP',
    flag: 'https://www.countryflags.io/NP/flat/64.png',
  },
  {
    name: 'Netherlands',
    dialCode: '+31',
    isoCode: 'NL',
    flag: 'https://www.countryflags.io/NL/flat/64.png',
  },
  {
    name: 'Netherlands Antilles',
    dialCode: '+599',
    isoCode: 'AN',
    flag: 'https://www.countryflags.io/AN/flat/64.png',
  },
  {
    name: 'New Caledonia',
    dialCode: '+687',
    isoCode: 'NC',
    flag: 'https://www.countryflags.io/NC/flat/64.png',
  },
  {
    name: 'New Zealand',
    dialCode: '+64',
    isoCode: 'NZ',
    flag: 'https://www.countryflags.io/NZ/flat/64.png',
  },
  {
    name: 'Nicaragua',
    dialCode: '+505',
    isoCode: 'NI',
    flag: 'https://www.countryflags.io/NI/flat/64.png',
  },
  {
    name: 'Niger',
    dialCode: '+227',
    isoCode: 'NE',
    flag: 'https://www.countryflags.io/NE/flat/64.png',
  },
  {
    name: 'Nigeria',
    dialCode: '+234',
    isoCode: 'NG',
    flag: 'https://www.countryflags.io/NG/flat/64.png',
  },
  {
    name: 'Niue',
    dialCode: '+683',
    isoCode: 'NU',
    flag: 'https://www.countryflags.io/NU/flat/64.png',
  },
  {
    name: 'Norfolk Island',
    dialCode: '+672',
    isoCode: 'NF',
    flag: 'https://www.countryflags.io/NF/flat/64.png',
  },
  {
    name: 'North Macedonia',
    dialCode: '+389',
    isoCode: 'MK',
    flag: 'https://www.countryflags.io/MK/flat/64.png',
  },
  {
    name: 'Northern Mariana Islands',
    dialCode: '+1670',
    isoCode: 'MP',
    flag: 'https://www.countryflags.io/MP/flat/64.png',
  },
  {
    name: 'Norway',
    dialCode: '+47',
    isoCode: 'NO',
    flag: 'https://www.countryflags.io/NO/flat/64.png',
  },
  {
    name: 'Oman',
    dialCode: '+968',
    isoCode: 'OM',
    flag: 'https://www.countryflags.io/OM/flat/64.png',
  },
  {
    name: 'Pakistan',
    dialCode: '+92',
    isoCode: 'PK',
    flag: 'https://www.countryflags.io/PK/flat/64.png',
  },
  {
    name: 'Palau',
    dialCode: '+680',
    isoCode: 'PW',
    flag: 'https://www.countryflags.io/PW/flat/64.png',
  },
  {
    name: 'Palestine',
    dialCode: '+970',
    isoCode: 'PS',
    flag: 'https://www.countryflags.io/PS/flat/64.png',
  },
  {
    name: 'Panama',
    dialCode: '+507',
    isoCode: 'PA',
    flag: 'https://www.countryflags.io/PA/flat/64.png',
  },
  {
    name: 'Papua New Guinea',
    dialCode: '+675',
    isoCode: 'PG',
    flag: 'https://www.countryflags.io/PG/flat/64.png',
  },
  {
    name: 'Paraguay',
    dialCode: '+595',
    isoCode: 'PY',
    flag: 'https://www.countryflags.io/PY/flat/64.png',
  },
  {
    name: 'Peru',
    dialCode: '+51',
    isoCode: 'PE',
    flag: 'https://www.countryflags.io/PE/flat/64.png',
  },
  {
    name: 'Philippines',
    dialCode: '+63',
    isoCode: 'PH',
    flag: 'https://www.countryflags.io/PH/flat/64.png',
  },
  {
    name: 'Pitcairn',
    dialCode: '+872',
    isoCode: 'PN',
    flag: 'https://www.countryflags.io/PN/flat/64.png',
  },
  {
    name: 'Poland',
    dialCode: '+48',
    isoCode: 'PL',
    flag: 'https://www.countryflags.io/PL/flat/64.png',
  },
  {
    name: 'Portugal',
    dialCode: '+351',
    isoCode: 'PT',
    flag: 'https://www.countryflags.io/PT/flat/64.png',
  },
  {
    name: 'Puerto Rico',
    dialCode: '+1939',
    isoCode: 'PR',
    flag: 'https://www.countryflags.io/PR/flat/64.png',
  },
  {
    name: 'Qatar',
    dialCode: '+974',
    isoCode: 'QA',
    flag: 'https://www.countryflags.io/QA/flat/64.png',
  },
  {
    name: 'Reunion',
    dialCode: '+262',
    isoCode: 'RE',
    flag: 'https://www.countryflags.io/RE/flat/64.png',
  },
  {
    name: 'Romania',
    dialCode: '+40',
    isoCode: 'RO',
    flag: 'https://www.countryflags.io/RO/flat/64.png',
  },
  {
    name: 'Russia',
    dialCode: '+7',
    isoCode: 'RU',
    flag: 'https://www.countryflags.io/RU/flat/64.png',
  },
  {
    name: 'Rwanda',
    dialCode: '+250',
    isoCode: 'RW',
    flag: 'https://www.countryflags.io/RW/flat/64.png',
  },
  {
    name: 'Saint Barthelemy',
    dialCode: '+590',
    isoCode: 'BL',
    flag: 'https://www.countryflags.io/BL/flat/64.png',
  },
  {
    name: 'Saint Helena',
    dialCode: '+290',
    isoCode: 'SH',
    flag: 'https://www.countryflags.io/SH/flat/64.png',
  },
  {
    name: 'Saint Kitts and Nevis',
    dialCode: '+1869',
    isoCode: 'KN',
    flag: 'https://www.countryflags.io/KN/flat/64.png',
  },
  {
    name: 'Saint Lucia',
    dialCode: '+1758',
    isoCode: 'LC',
    flag: 'https://www.countryflags.io/LC/flat/64.png',
  },
  {
    name: 'Saint Martin',
    dialCode: '+590',
    isoCode: 'MF',
    flag: 'https://www.countryflags.io/MF/flat/64.png',
  },
  {
    name: 'Saint Pierre and Miquelon',
    dialCode: '+508',
    isoCode: 'PM',
    flag: 'https://www.countryflags.io/PM/flat/64.png',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dialCode: '+1784',
    isoCode: 'VC',
    flag: 'https://www.countryflags.io/VC/flat/64.png',
  },
  {
    name: 'Samoa',
    dialCode: '+685',
    isoCode: 'WS',
    flag: 'https://www.countryflags.io/WS/flat/64.png',
  },
  {
    name: 'San Marino',
    dialCode: '+378',
    isoCode: 'SM',
    flag: 'https://www.countryflags.io/SM/flat/64.png',
  },
  {
    name: 'Sao Tome and Principe',
    dialCode: '+239',
    isoCode: 'ST',
    flag: 'https://www.countryflags.io/ST/flat/64.png',
  },
  {
    name: 'Saudi Arabia',
    dialCode: '+966',
    isoCode: 'SA',
    flag: 'https://www.countryflags.io/SA/flat/64.png',
  },
  {
    name: 'Senegal',
    dialCode: '+221',
    isoCode: 'SN',
    flag: 'https://www.countryflags.io/SN/flat/64.png',
  },
  {
    name: 'Serbia',
    dialCode: '+381',
    isoCode: 'RS',
    flag: 'https://www.countryflags.io/RS/flat/64.png',
  },
  {
    name: 'Seychelles',
    dialCode: '+248',
    isoCode: 'SC',
    flag: 'https://www.countryflags.io/SC/flat/64.png',
  },
  {
    name: 'Sierra Leone',
    dialCode: '+232',
    isoCode: 'SL',
    flag: 'https://www.countryflags.io/SL/flat/64.png',
  },
  {
    name: 'Singapore',
    dialCode: '+65',
    isoCode: 'SG',
    flag: 'https://www.countryflags.io/SG/flat/64.png',
  },
  {
    name: 'Sint Maarten',
    dialCode: '+1721',
    isoCode: 'SX',
    flag: 'https://www.countryflags.io/SX/flat/64.png',
  },
  {
    name: 'Slovakia',
    dialCode: '+421',
    isoCode: 'SK',
    flag: 'https://www.countryflags.io/SK/flat/64.png',
  },
  {
    name: 'Slovenia',
    dialCode: '+386',
    isoCode: 'SI',
    flag: 'https://www.countryflags.io/SI/flat/64.png',
  },
  {
    name: 'Solomon Islands',
    dialCode: '+677',
    isoCode: 'SB',
    flag: 'https://www.countryflags.io/SB/flat/64.png',
  },
  {
    name: 'Somalia',
    dialCode: '+252',
    isoCode: 'SO',
    flag: 'https://www.countryflags.io/SO/flat/64.png',
  },
  {
    name: 'Somaliland',
    dialCode: '+252',
    isoCode: 'XX',
    flag: 'https://www.countryflags.io/SO/flat/64.png',
  },
  {
    name: 'South Africa',
    dialCode: '+27',
    isoCode: 'ZA',
    flag: 'https://www.countryflags.io/ZA/flat/64.png',
  },
  {
    name: 'South Georgia',
    dialCode: '+500',
    isoCode: 'GS',
    flag: 'https://www.countryflags.io/GS/flat/64.png',
  },
  {
    name: 'Spain',
    dialCode: '+34',
    isoCode: 'ES',
    flag: 'https://www.countryflags.io/ES/flat/64.png',
  },
  {
    name: 'Sri Lanka',
    dialCode: '+94',
    isoCode: 'LK',
    flag: 'https://www.countryflags.io/LK/flat/64.png',
  },
  {
    name: 'Sudan',
    dialCode: '+249',
    isoCode: 'SD',
    flag: 'https://www.countryflags.io/SD/flat/64.png',
  },
  {
    name: 'South Sudan',
    dialCode: '+211',
    isoCode: 'SS',
    flag: 'https://www.countryflags.io/SS/flat/64.png',
  },
  {
    name: 'Suriname',
    dialCode: '+597',
    isoCode: 'SR',
    flag: 'https://www.countryflags.io/SR/flat/64.png',
  },
  {
    name: 'Svalbard and Jan Mayen',
    dialCode: '+47',
    isoCode: 'SJ',
    flag: 'https://www.countryflags.io/SJ/flat/64.png',
  },
  {
    name: 'Sweden',
    dialCode: '+46',
    isoCode: 'SE',
    flag: 'https://www.countryflags.io/SE/flat/64.png',
  },
  {
    name: 'Switzerland',
    dialCode: '+41',
    isoCode: 'CH',
    flag: 'https://www.countryflags.io/CH/flat/64.png',
  },
  {
    name: 'Syrian Arab Republic',
    dialCode: '+963',
    isoCode: 'SY',
    flag: 'https://www.countryflags.io/SY/flat/64.png',
  },
  {
    name: 'Taiwan',
    dialCode: '+886',
    isoCode: 'TW',
    flag: 'https://www.countryflags.io/TW/flat/64.png',
  },
  {
    name: 'Tajikistan',
    dialCode: '+992',
    isoCode: 'TJ',
    flag: 'https://www.countryflags.io/TJ/flat/64.png',
  },
  {
    name: 'Tanzania',
    dialCode: '+255',
    isoCode: 'TZ',
    flag: 'https://www.countryflags.io/TZ/flat/64.png',
  },
  {
    name: 'Thailand',
    dialCode: '+66',
    isoCode: 'TH',
    flag: 'https://www.countryflags.io/TH/flat/64.png',
  },
  {
    name: 'Timor-Leste',
    dialCode: '+670',
    isoCode: 'TL',
    flag: 'https://www.countryflags.io/TL/flat/64.png',
  },
  {
    name: 'Togo',
    dialCode: '+228',
    isoCode: 'TG',
    flag: 'https://www.countryflags.io/TG/flat/64.png',
  },
  {
    name: 'Tokelau',
    dialCode: '+690',
    isoCode: 'TK',
    flag: 'https://www.countryflags.io/TK/flat/64.png',
  },
  {
    name: 'Tonga',
    dialCode: '+676',
    isoCode: 'TO',
    flag: 'https://www.countryflags.io/TO/flat/64.png',
  },
  {
    name: 'Trinidad and Tobago',
    dialCode: '+1868',
    isoCode: 'TT',
    flag: 'https://www.countryflags.io/TT/flat/64.png',
  },
  {
    name: 'Tunisia',
    dialCode: '+216',
    isoCode: 'TN',
    flag: 'https://www.countryflags.io/TN/flat/64.png',
  },
  {
    name: 'Turkey',
    dialCode: '+90',
    isoCode: 'TR',
    flag: 'https://www.countryflags.io/TR/flat/64.png',
  },
  {
    name: 'Turkmenistan',
    dialCode: '+993',
    isoCode: 'TM',
    flag: 'https://www.countryflags.io/TM/flat/64.png',
  },
  {
    name: 'Turks and Caicos Islands',
    dialCode: '+1649',
    isoCode: 'TC',
    flag: 'https://www.countryflags.io/TC/flat/64.png',
  },
  {
    name: 'Tuvalu',
    dialCode: '+688',
    isoCode: 'TV',
    flag: 'https://www.countryflags.io/TV/flat/64.png',
  },
  {
    name: 'Uganda',
    dialCode: '+256',
    isoCode: 'UG',
    flag: 'https://www.countryflags.io/UG/flat/64.png',
  },
  {
    name: 'Ukraine',
    dialCode: '+380',
    isoCode: 'UA',
    flag: 'https://www.countryflags.io/UA/flat/64.png',
  },
  {
    name: 'United Arab Emirates',
    dialCode: '+971',
    isoCode: 'AE',
    flag: 'https://www.countryflags.io/AE/flat/64.png',
  },
  {
    name: 'United Kingdom',
    dialCode: '+44',
    isoCode: 'GB',
    flag: 'https://www.countryflags.io/GB/flat/64.png',
  },
  {
    name: 'United States',
    dialCode: '+1',
    isoCode: 'US',
    flag: 'https://www.countryflags.io/US/flat/64.png',
  },
  {
    name: 'Uruguay',
    dialCode: '+598',
    isoCode: 'UY',
    flag: 'https://www.countryflags.io/UY/flat/64.png',
  },
  {
    name: 'Uzbekistan',
    dialCode: '+998',
    isoCode: 'UZ',
    flag: 'https://www.countryflags.io/UZ/flat/64.png',
  },
  {
    name: 'Vanuatu',
    dialCode: '+678',
    isoCode: 'VU',
    flag: 'https://www.countryflags.io/VU/flat/64.png',
  },
  {
    name: 'Venezuela',
    dialCode: '+58',
    isoCode: 'VE',
    flag: 'https://www.countryflags.io/VE/flat/64.png',
  },
  {
    name: 'Vietnam',
    dialCode: '+84',
    isoCode: 'VN',
    flag: 'https://www.countryflags.io/VN/flat/64.png',
  },
  {
    name: 'Virgin Islands, British',
    dialCode: '+1284',
    isoCode: 'VG',
    flag: 'https://www.countryflags.io/VG/flat/64.png',
  },
  {
    name: 'Virgin Islands, U.S.',
    dialCode: '+1340',
    isoCode: 'VI',
    flag: 'https://www.countryflags.io/VI/flat/64.png',
  },
  {
    name: 'Wallis and Futuna',
    dialCode: '+681',
    isoCode: 'WF',
    flag: 'https://www.countryflags.io/WF/flat/64.png',
  },
  {
    name: 'Yemen',
    dialCode: '+967',
    isoCode: 'YE',
    flag: 'https://www.countryflags.io/YE/flat/64.png',
  },
  {
    name: 'Zambia',
    dialCode: '+260',
    isoCode: 'ZM',
    flag: 'https://www.countryflags.io/ZM/flat/64.png',
  },
  {
    name: 'Zimbabwe',
    dialCode: '+263',
    isoCode: 'ZW',
    flag: 'https://www.countryflags.io/ZW/flat/64.png',
  },
];

var NationalityCountryCodes = [
  {
    num_code: '4',
    alpha_2_code: 'AF',
    alpha_3_code: 'AFG',
    en_short_name: 'Afghanistan',
    nationality: 'Afghan',
  },
  {
    num_code: '248',
    alpha_2_code: 'AX',
    alpha_3_code: 'ALA',
    en_short_name: '\u00c5land Islands',
    nationality: '\u00c5land Island',
  },
  {
    num_code: '8',
    alpha_2_code: 'AL',
    alpha_3_code: 'ALB',
    en_short_name: 'Albania',
    nationality: 'Albanian',
  },
  {
    num_code: '12',
    alpha_2_code: 'DZ',
    alpha_3_code: 'DZA',
    en_short_name: 'Algeria',
    nationality: 'Algerian',
  },
  {
    num_code: '16',
    alpha_2_code: 'AS',
    alpha_3_code: 'ASM',
    en_short_name: 'American Samoa',
    nationality: 'American Samoan',
  },
  {
    num_code: '20',
    alpha_2_code: 'AD',
    alpha_3_code: 'AND',
    en_short_name: 'Andorra',
    nationality: 'Andorran',
  },
  {
    num_code: '24',
    alpha_2_code: 'AO',
    alpha_3_code: 'AGO',
    en_short_name: 'Angola',
    nationality: 'Angolan',
  },
  {
    num_code: '660',
    alpha_2_code: 'AI',
    alpha_3_code: 'AIA',
    en_short_name: 'Anguilla',
    nationality: 'Anguillan',
  },
  {
    num_code: '10',
    alpha_2_code: 'AQ',
    alpha_3_code: 'ATA',
    en_short_name: 'Antarctica',
    nationality: 'Antarctic',
  },
  {
    num_code: '28',
    alpha_2_code: 'AG',
    alpha_3_code: 'ATG',
    en_short_name: 'Antigua and Barbuda',
    nationality: 'Antiguan or Barbudan',
  },
  {
    num_code: '32',
    alpha_2_code: 'AR',
    alpha_3_code: 'ARG',
    en_short_name: 'Argentina',
    nationality: 'Argentine',
  },
  {
    num_code: '51',
    alpha_2_code: 'AM',
    alpha_3_code: 'ARM',
    en_short_name: 'Armenia',
    nationality: 'Armenian',
  },
  {
    num_code: '533',
    alpha_2_code: 'AW',
    alpha_3_code: 'ABW',
    en_short_name: 'Aruba',
    nationality: 'Aruban',
  },
  {
    num_code: '36',
    alpha_2_code: 'AU',
    alpha_3_code: 'AUS',
    en_short_name: 'Australia',
    nationality: 'Australian',
  },
  {
    num_code: '40',
    alpha_2_code: 'AT',
    alpha_3_code: 'AUT',
    en_short_name: 'Austria',
    nationality: 'Austrian',
  },
  {
    num_code: '31',
    alpha_2_code: 'AZ',
    alpha_3_code: 'AZE',
    en_short_name: 'Azerbaijan',
    nationality: 'Azerbaijani, Azeri',
  },
  {
    num_code: '44',
    alpha_2_code: 'BS',
    alpha_3_code: 'BHS',
    en_short_name: 'Bahamas',
    nationality: 'Bahamian',
  },
  {
    num_code: '48',
    alpha_2_code: 'BH',
    alpha_3_code: 'BHR',
    en_short_name: 'Bahrain',
    nationality: 'Bahraini',
  },
  {
    num_code: '50',
    alpha_2_code: 'BD',
    alpha_3_code: 'BGD',
    en_short_name: 'Bangladesh',
    nationality: 'Bangladeshi',
  },
  {
    num_code: '52',
    alpha_2_code: 'BB',
    alpha_3_code: 'BRB',
    en_short_name: 'Barbados',
    nationality: 'Barbadian',
  },
  {
    num_code: '112',
    alpha_2_code: 'BY',
    alpha_3_code: 'BLR',
    en_short_name: 'Belarus',
    nationality: 'Belarusian',
  },
  {
    num_code: '56',
    alpha_2_code: 'BE',
    alpha_3_code: 'BEL',
    en_short_name: 'Belgium',
    nationality: 'Belgian',
  },
  {
    num_code: '84',
    alpha_2_code: 'BZ',
    alpha_3_code: 'BLZ',
    en_short_name: 'Belize',
    nationality: 'Belizean',
  },
  {
    num_code: '204',
    alpha_2_code: 'BJ',
    alpha_3_code: 'BEN',
    en_short_name: 'Benin',
    nationality: 'Beninese',
  },
  {
    num_code: '60',
    alpha_2_code: 'BM',
    alpha_3_code: 'BMU',
    en_short_name: 'Bermuda',
    nationality: 'Bermudian',
  },
  {
    num_code: '64',
    alpha_2_code: 'BT',
    alpha_3_code: 'BTN',
    en_short_name: 'Bhutan',
    nationality: 'Bhutanese',
  },
  {
    num_code: '68',
    alpha_2_code: 'BO',
    alpha_3_code: 'BOL',
    en_short_name: 'Bolivia (Plurinational State of)',
    nationality: 'Bolivian',
  },
  {
    num_code: '535',
    alpha_2_code: 'BQ',
    alpha_3_code: 'BES',
    en_short_name: 'Bonaire, Sint Eustatius and Saba',
    nationality: 'Bonaire',
  },
  {
    num_code: '70',
    alpha_2_code: 'BA',
    alpha_3_code: 'BIH',
    en_short_name: 'Bosnia and Herzegovina',
    nationality: 'Bosnian or Herzegovinian',
  },
  {
    num_code: '72',
    alpha_2_code: 'BW',
    alpha_3_code: 'BWA',
    en_short_name: 'Botswana',
    nationality: 'Motswana',
  },
  {
    num_code: '74',
    alpha_2_code: 'BV',
    alpha_3_code: 'BVT',
    en_short_name: 'Bouvet Island',
    nationality: 'Bouvet Island',
  },
  {
    num_code: '76',
    alpha_2_code: 'BR',
    alpha_3_code: 'BRA',
    en_short_name: 'Brazil',
    nationality: 'Brazilian',
  },
  {
    num_code: '86',
    alpha_2_code: 'IO',
    alpha_3_code: 'IOT',
    en_short_name: 'British Indian Ocean Territory',
    nationality: 'BIOT',
  },
  {
    num_code: '96',
    alpha_2_code: 'BN',
    alpha_3_code: 'BRN',
    en_short_name: 'Brunei Darussalam',
    nationality: 'Bruneian',
  },
  {
    num_code: '100',
    alpha_2_code: 'BG',
    alpha_3_code: 'BGR',
    en_short_name: 'Bulgaria',
    nationality: 'Bulgarian',
  },
  {
    num_code: '854',
    alpha_2_code: 'BF',
    alpha_3_code: 'BFA',
    en_short_name: 'Burkina Faso',
    nationality: 'Burkinab\u00e9',
  },
  {
    num_code: '108',
    alpha_2_code: 'BI',
    alpha_3_code: 'BDI',
    en_short_name: 'Burundi',
    nationality: 'Umurundi',
  },
  {
    num_code: '132',
    alpha_2_code: 'CV',
    alpha_3_code: 'CPV',
    en_short_name: 'Cabo Verde',
    nationality: 'Cabo Verdean',
  },
  {
    num_code: '116',
    alpha_2_code: 'KH',
    alpha_3_code: 'KHM',
    en_short_name: 'Cambodia',
    nationality: 'Cambodian',
  },
  {
    num_code: '120',
    alpha_2_code: 'CM',
    alpha_3_code: 'CMR',
    en_short_name: 'Cameroon',
    nationality: 'Cameroonian',
  },
  {
    num_code: '124',
    alpha_2_code: 'CA',
    alpha_3_code: 'CAN',
    en_short_name: 'Canada',
    nationality: 'Canadian',
  },
  {
    num_code: '136',
    alpha_2_code: 'KY',
    alpha_3_code: 'CYM',
    en_short_name: 'Cayman Islands',
    nationality: 'Caymanian',
  },
  {
    num_code: '140',
    alpha_2_code: 'CF',
    alpha_3_code: 'CAF',
    en_short_name: 'Central African Republic',
    nationality: 'Central African',
  },
  {
    num_code: '148',
    alpha_2_code: 'TD',
    alpha_3_code: 'TCD',
    en_short_name: 'Chad',
    nationality: 'Chadian',
  },
  {
    num_code: '152',
    alpha_2_code: 'CL',
    alpha_3_code: 'CHL',
    en_short_name: 'Chile',
    nationality: 'Chilean',
  },
  {
    num_code: '156',
    alpha_2_code: 'CN',
    alpha_3_code: 'CHN',
    en_short_name: 'China',
    nationality: 'Chinese',
  },
  {
    num_code: '162',
    alpha_2_code: 'CX',
    alpha_3_code: 'CXR',
    en_short_name: 'Christmas Island',
    nationality: 'Christmas Island',
  },
  {
    num_code: '166',
    alpha_2_code: 'CC',
    alpha_3_code: 'CCK',
    en_short_name: 'Cocos (Keeling) Islands',
    nationality: 'Cocos Island',
  },
  {
    num_code: '170',
    alpha_2_code: 'CO',
    alpha_3_code: 'COL',
    en_short_name: 'Colombia',
    nationality: 'Colombian',
  },
  {
    num_code: '174',
    alpha_2_code: 'KM',
    alpha_3_code: 'COM',
    en_short_name: 'Comoros',
    nationality: 'Comoran, Comorian',
  },
  {
    num_code: '178',
    alpha_2_code: 'CG',
    alpha_3_code: 'COG',
    en_short_name: 'Congo (Republic of the)',
    nationality: 'Congolese',
  },
  {
    num_code: '180',
    alpha_2_code: 'CD',
    alpha_3_code: 'COD',
    en_short_name: 'Congo (Democratic Republic of the)',
    nationality: 'Congolese',
  },
  {
    num_code: '184',
    alpha_2_code: 'CK',
    alpha_3_code: 'COK',
    en_short_name: 'Cook Islands',
    nationality: 'Cook Island',
  },
  {
    num_code: '188',
    alpha_2_code: 'CR',
    alpha_3_code: 'CRI',
    en_short_name: 'Costa Rica',
    nationality: 'Costa Rican',
  },
  {
    num_code: '384',
    alpha_2_code: 'CI',
    alpha_3_code: 'CIV',
    en_short_name: "C\u00f4te d'Ivoire",
    nationality: 'Ivorian',
  },
  {
    num_code: '191',
    alpha_2_code: 'HR',
    alpha_3_code: 'HRV',
    en_short_name: 'Croatia',
    nationality: 'Croatian',
  },
  {
    num_code: '192',
    alpha_2_code: 'CU',
    alpha_3_code: 'CUB',
    en_short_name: 'Cuba',
    nationality: 'Cuban',
  },
  {
    num_code: '531',
    alpha_2_code: 'CW',
    alpha_3_code: 'CUW',
    en_short_name: 'Cura\u00e7ao',
    nationality: 'Cura\u00e7aoan',
  },
  {
    num_code: '196',
    alpha_2_code: 'CY',
    alpha_3_code: 'CYP',
    en_short_name: 'Cyprus',
    nationality: 'Cypriot',
  },
  {
    num_code: '203',
    alpha_2_code: 'CZ',
    alpha_3_code: 'CZE',
    en_short_name: 'Czech Republic',
    nationality: 'Czech',
  },
  {
    num_code: '208',
    alpha_2_code: 'DK',
    alpha_3_code: 'DNK',
    en_short_name: 'Denmark',
    nationality: 'Danish',
  },
  {
    num_code: '262',
    alpha_2_code: 'DJ',
    alpha_3_code: 'DJI',
    en_short_name: 'Djibouti',
    nationality: 'Djiboutian',
  },
  {
    num_code: '212',
    alpha_2_code: 'DM',
    alpha_3_code: 'DMA',
    en_short_name: 'Dominica',
    nationality: 'Dominican',
  },
  {
    num_code: '214',
    alpha_2_code: 'DO',
    alpha_3_code: 'DOM',
    en_short_name: 'Dominican Republic',
    nationality: 'Dominican',
  },
  {
    num_code: '218',
    alpha_2_code: 'EC',
    alpha_3_code: 'ECU',
    en_short_name: 'Ecuador',
    nationality: 'Ecuadorian',
  },
  {
    num_code: '818',
    alpha_2_code: 'EG',
    alpha_3_code: 'EGY',
    en_short_name: 'Egypt',
    nationality: 'Egyptian',
  },
  {
    num_code: '222',
    alpha_2_code: 'SV',
    alpha_3_code: 'SLV',
    en_short_name: 'El Salvador',
    nationality: 'Salvadoran',
  },
  {
    num_code: '226',
    alpha_2_code: 'GQ',
    alpha_3_code: 'GNQ',
    en_short_name: 'Equatorial Guinea',
    nationality: 'Equatorial Guinean, Equatoguinean',
  },
  {
    num_code: '232',
    alpha_2_code: 'ER',
    alpha_3_code: 'ERI',
    en_short_name: 'Eritrea',
    nationality: 'Eritrean',
  },
  {
    num_code: '233',
    alpha_2_code: 'EE',
    alpha_3_code: 'EST',
    en_short_name: 'Estonia',
    nationality: 'Estonian',
  },
  {
    num_code: '231',
    alpha_2_code: 'ET',
    alpha_3_code: 'ETH',
    en_short_name: 'Ethiopia',
    nationality: 'Ethiopian',
  },
  {
    num_code: '238',
    alpha_2_code: 'FK',
    alpha_3_code: 'FLK',
    en_short_name: 'Falkland Islands (Malvinas)',
    nationality: 'Falkland Island',
  },
  {
    num_code: '234',
    alpha_2_code: 'FO',
    alpha_3_code: 'FRO',
    en_short_name: 'Faroe Islands',
    nationality: 'Faroese',
  },
  {
    num_code: '242',
    alpha_2_code: 'FJ',
    alpha_3_code: 'FJI',
    en_short_name: 'Fiji',
    nationality: 'Fijian',
  },
  {
    num_code: '246',
    alpha_2_code: 'FI',
    alpha_3_code: 'FIN',
    en_short_name: 'Finland',
    nationality: 'Finnish',
  },
  {
    num_code: '250',
    alpha_2_code: 'FR',
    alpha_3_code: 'FRA',
    en_short_name: 'France',
    nationality: 'French',
  },
  {
    num_code: '254',
    alpha_2_code: 'GF',
    alpha_3_code: 'GUF',
    en_short_name: 'French Guiana',
    nationality: 'French Guianese',
  },
  {
    num_code: '258',
    alpha_2_code: 'PF',
    alpha_3_code: 'PYF',
    en_short_name: 'French Polynesia',
    nationality: 'French Polynesian',
  },
  {
    num_code: '260',
    alpha_2_code: 'TF',
    alpha_3_code: 'ATF',
    en_short_name: 'French Southern Territories',
    nationality: 'French Southern Territories',
  },
  {
    num_code: '266',
    alpha_2_code: 'GA',
    alpha_3_code: 'GAB',
    en_short_name: 'Gabon',
    nationality: 'Gabonese',
  },
  {
    num_code: '270',
    alpha_2_code: 'GM',
    alpha_3_code: 'GMB',
    en_short_name: 'Gambia',
    nationality: 'Gambian',
  },
  {
    num_code: '268',
    alpha_2_code: 'GE',
    alpha_3_code: 'GEO',
    en_short_name: 'Georgia',
    nationality: 'Georgian',
  },
  {
    num_code: '276',
    alpha_2_code: 'DE',
    alpha_3_code: 'DEU',
    en_short_name: 'Germany',
    nationality: 'German',
  },
  {
    num_code: '288',
    alpha_2_code: 'GH',
    alpha_3_code: 'GHA',
    en_short_name: 'Ghana',
    nationality: 'Ghanaian',
  },
  {
    num_code: '292',
    alpha_2_code: 'GI',
    alpha_3_code: 'GIB',
    en_short_name: 'Gibraltar',
    nationality: 'Gibraltar',
  },
  {
    num_code: '300',
    alpha_2_code: 'GR',
    alpha_3_code: 'GRC',
    en_short_name: 'Greece',
    nationality: 'Greek',
  },
  {
    num_code: '304',
    alpha_2_code: 'GL',
    alpha_3_code: 'GRL',
    en_short_name: 'Greenland',
    nationality: 'Greenlandic',
  },
  {
    num_code: '308',
    alpha_2_code: 'GD',
    alpha_3_code: 'GRD',
    en_short_name: 'Grenada',
    nationality: 'Grenadian',
  },
  {
    num_code: '312',
    alpha_2_code: 'GP',
    alpha_3_code: 'GLP',
    en_short_name: 'Guadeloupe',
    nationality: 'Guadeloupe',
  },
  {
    num_code: '316',
    alpha_2_code: 'GU',
    alpha_3_code: 'GUM',
    en_short_name: 'Guam',
    nationality: 'Guamanian',
  },
  {
    num_code: '320',
    alpha_2_code: 'GT',
    alpha_3_code: 'GTM',
    en_short_name: 'Guatemala',
    nationality: 'Guatemalan',
  },
  {
    num_code: '831',
    alpha_2_code: 'GG',
    alpha_3_code: 'GGY',
    en_short_name: 'Guernsey',
    nationality: 'Channel Island',
  },
  {
    num_code: '324',
    alpha_2_code: 'GN',
    alpha_3_code: 'GIN',
    en_short_name: 'Guinea',
    nationality: 'Guinean',
  },
  {
    num_code: '624',
    alpha_2_code: 'GW',
    alpha_3_code: 'GNB',
    en_short_name: 'Guinea-Bissau',
    nationality: 'Bissau-Guinean',
  },
  {
    num_code: '328',
    alpha_2_code: 'GY',
    alpha_3_code: 'GUY',
    en_short_name: 'Guyana',
    nationality: 'Guyanese',
  },
  {
    num_code: '332',
    alpha_2_code: 'HT',
    alpha_3_code: 'HTI',
    en_short_name: 'Haiti',
    nationality: 'Haitian',
  },
  {
    num_code: '334',
    alpha_2_code: 'HM',
    alpha_3_code: 'HMD',
    en_short_name: 'Heard Island and McDonald Islands',
    nationality: 'Heard Island or McDonald Islands',
  },
  {
    num_code: '336',
    alpha_2_code: 'VA',
    alpha_3_code: 'VAT',
    en_short_name: 'Vatican City State',
    nationality: 'Vatican',
  },
  {
    num_code: '340',
    alpha_2_code: 'HN',
    alpha_3_code: 'HND',
    en_short_name: 'Honduras',
    nationality: 'Honduran',
  },
  {
    num_code: '344',
    alpha_2_code: 'HK',
    alpha_3_code: 'HKG',
    en_short_name: 'Hong Kong',
    nationality: 'Hongkonger',
  },
  {
    num_code: '348',
    alpha_2_code: 'HU',
    alpha_3_code: 'HUN',
    en_short_name: 'Hungary',
    nationality: 'Hungarian',
  },
  {
    num_code: '352',
    alpha_2_code: 'IS',
    alpha_3_code: 'ISL',
    en_short_name: 'Iceland',
    nationality: 'Icelandic',
  },
  {
    num_code: '356',
    alpha_2_code: 'IN',
    alpha_3_code: 'IND',
    en_short_name: 'India',
    nationality: 'Indian',
  },
  {
    num_code: '360',
    alpha_2_code: 'ID',
    alpha_3_code: 'IDN',
    en_short_name: 'Indonesia',
    nationality: 'Indonesian',
  },
  {
    num_code: '364',
    alpha_2_code: 'IR',
    alpha_3_code: 'IRN',
    en_short_name: 'Iran',
    nationality: 'Iranian',
  },
  {
    num_code: '368',
    alpha_2_code: 'IQ',
    alpha_3_code: 'IRQ',
    en_short_name: 'Iraq',
    nationality: 'Iraqi',
  },
  {
    num_code: '372',
    alpha_2_code: 'IE',
    alpha_3_code: 'IRL',
    en_short_name: 'Ireland',
    nationality: 'Irish',
  },
  {
    num_code: '833',
    alpha_2_code: 'IM',
    alpha_3_code: 'IMN',
    en_short_name: 'Isle of Man',
    nationality: 'Manx',
  },
  {
    num_code: '376',
    alpha_2_code: 'IL',
    alpha_3_code: 'ISR',
    en_short_name: 'Israel',
    nationality: 'Israeli',
  },
  {
    num_code: '380',
    alpha_2_code: 'IT',
    alpha_3_code: 'ITA',
    en_short_name: 'Italy',
    nationality: 'Italian',
  },
  {
    num_code: '388',
    alpha_2_code: 'JM',
    alpha_3_code: 'JAM',
    en_short_name: 'Jamaica',
    nationality: 'Jamaican',
  },
  {
    num_code: '392',
    alpha_2_code: 'JP',
    alpha_3_code: 'JPN',
    en_short_name: 'Japan',
    nationality: 'Japanese',
  },
  {
    num_code: '832',
    alpha_2_code: 'JE',
    alpha_3_code: 'JEY',
    en_short_name: 'Jersey',
    nationality: 'Channel Island',
  },
  {
    num_code: '400',
    alpha_2_code: 'JO',
    alpha_3_code: 'JOR',
    en_short_name: 'Jordan',
    nationality: 'Jordanian',
  },
  {
    num_code: '398',
    alpha_2_code: 'KZ',
    alpha_3_code: 'KAZ',
    en_short_name: 'Kazakhstan',
    nationality: 'Kazakhstani',
  },
  {
    num_code: '404',
    alpha_2_code: 'KE',
    alpha_3_code: 'KEN',
    en_short_name: 'Kenya',
    nationality: 'Kenyan',
  },
  {
    num_code: '296',
    alpha_2_code: 'KI',
    alpha_3_code: 'KIR',
    en_short_name: 'Kiribati',
    nationality: 'I-Kiribati',
  },
  {
    num_code: '408',
    alpha_2_code: 'KP',
    alpha_3_code: 'PRK',
    en_short_name: "Korea (Democratic People's Republic of)",
    nationality: 'North Korean',
  },
  {
    num_code: '410',
    alpha_2_code: 'KR',
    alpha_3_code: 'KOR',
    en_short_name: 'Korea (Republic of)',
    nationality: 'South Korean',
  },
  {
    num_code: '414',
    alpha_2_code: 'KW',
    alpha_3_code: 'KWT',
    en_short_name: 'Kuwait',
    nationality: 'Kuwaiti',
  },
  {
    num_code: '417',
    alpha_2_code: 'KG',
    alpha_3_code: 'KGZ',
    en_short_name: 'Kyrgyzstan',
    nationality: 'Kyrgyz',
  },
  {
    num_code: '418',
    alpha_2_code: 'LA',
    alpha_3_code: 'LAO',
    en_short_name: "Lao People's Democratic Republic",
    nationality: 'Lao, Laotian',
  },
  {
    num_code: '428',
    alpha_2_code: 'LV',
    alpha_3_code: 'LVA',
    en_short_name: 'Latvia',
    nationality: 'Latvian',
  },
  {
    num_code: '422',
    alpha_2_code: 'LB',
    alpha_3_code: 'LBN',
    en_short_name: 'Lebanon',
    nationality: 'Lebanese',
  },
  {
    num_code: '426',
    alpha_2_code: 'LS',
    alpha_3_code: 'LSO',
    en_short_name: 'Lesotho',
    nationality: 'Basotho',
  },
  {
    num_code: '430',
    alpha_2_code: 'LR',
    alpha_3_code: 'LBR',
    en_short_name: 'Liberia',
    nationality: 'Liberian',
  },
  {
    num_code: '434',
    alpha_2_code: 'LY',
    alpha_3_code: 'LBY',
    en_short_name: 'Libya',
    nationality: 'Libyan',
  },
  {
    num_code: '438',
    alpha_2_code: 'LI',
    alpha_3_code: 'LIE',
    en_short_name: 'Liechtenstein',
    nationality: 'Liechtenstein',
  },
  {
    num_code: '440',
    alpha_2_code: 'LT',
    alpha_3_code: 'LTU',
    en_short_name: 'Lithuania',
    nationality: 'Lithuanian',
  },
  {
    num_code: '442',
    alpha_2_code: 'LU',
    alpha_3_code: 'LUX',
    en_short_name: 'Luxembourg',
    nationality: 'Luxembourger, Luxembourgish',
  },
  {
    num_code: '446',
    alpha_2_code: 'MO',
    alpha_3_code: 'MAC',
    en_short_name: 'Macao',
    nationality: 'Macau',
  },
  {
    num_code: '807',
    alpha_2_code: 'MK',
    alpha_3_code: 'MKD',
    en_short_name: 'Macedonia (the former Yugoslav Republic of)',
    nationality: 'Macedonian',
  },
  {
    num_code: '450',
    alpha_2_code: 'MG',
    alpha_3_code: 'MDG',
    en_short_name: 'Madagascar',
    nationality: 'Malagasy',
  },
  {
    num_code: '454',
    alpha_2_code: 'MW',
    alpha_3_code: 'MWI',
    en_short_name: 'Malawi',
    nationality: 'Malawian',
  },
  {
    num_code: '458',
    alpha_2_code: 'MY',
    alpha_3_code: 'MYS',
    en_short_name: 'Malaysia',
    nationality: 'Malaysian',
  },
  {
    num_code: '462',
    alpha_2_code: 'MV',
    alpha_3_code: 'MDV',
    en_short_name: 'Maldives',
    nationality: 'Maldivian',
  },
  {
    num_code: '466',
    alpha_2_code: 'ML',
    alpha_3_code: 'MLI',
    en_short_name: 'Mali',
    nationality: 'Malian',
  },
  {
    num_code: '470',
    alpha_2_code: 'MT',
    alpha_3_code: 'MLT',
    en_short_name: 'Malta',
    nationality: 'Maltese',
  },
  {
    num_code: '584',
    alpha_2_code: 'MH',
    alpha_3_code: 'MHL',
    en_short_name: 'Marshall Islands',
    nationality: 'Marshallese',
  },
  {
    num_code: '474',
    alpha_2_code: 'MQ',
    alpha_3_code: 'MTQ',
    en_short_name: 'Martinique',
    nationality: 'Martiniquais, Martinican',
  },
  {
    num_code: '478',
    alpha_2_code: 'MR',
    alpha_3_code: 'MRT',
    en_short_name: 'Mauritania',
    nationality: 'Mauritanian',
  },
  {
    num_code: '480',
    alpha_2_code: 'MU',
    alpha_3_code: 'MUS',
    en_short_name: 'Mauritius',
    nationality: 'Mauritian',
  },
  {
    num_code: '175',
    alpha_2_code: 'YT',
    alpha_3_code: 'MYT',
    en_short_name: 'Mayotte',
    nationality: 'Mahoran',
  },
  {
    num_code: '484',
    alpha_2_code: 'MX',
    alpha_3_code: 'MEX',
    en_short_name: 'Mexico',
    nationality: 'Mexican',
  },
  {
    num_code: '583',
    alpha_2_code: 'FM',
    alpha_3_code: 'FSM',
    en_short_name: 'Micronesia (Federated States of)',
    nationality: 'Micronesian',
  },
  {
    num_code: '498',
    alpha_2_code: 'MD',
    alpha_3_code: 'MDA',
    en_short_name: 'Moldova (Republic of)',
    nationality: 'Moldovan',
  },
  {
    num_code: '492',
    alpha_2_code: 'MC',
    alpha_3_code: 'MCO',
    en_short_name: 'Monaco',
    nationality: 'Mon\u00e9gasque, Monacan',
  },
  {
    num_code: '496',
    alpha_2_code: 'MN',
    alpha_3_code: 'MNG',
    en_short_name: 'Mongolia',
    nationality: 'Mongolian',
  },
  {
    num_code: '499',
    alpha_2_code: 'ME',
    alpha_3_code: 'MNE',
    en_short_name: 'Montenegro',
    nationality: 'Montenegrin',
  },
  {
    num_code: '500',
    alpha_2_code: 'MS',
    alpha_3_code: 'MSR',
    en_short_name: 'Montserrat',
    nationality: 'Montserratian',
  },
  {
    num_code: '504',
    alpha_2_code: 'MA',
    alpha_3_code: 'MAR',
    en_short_name: 'Morocco',
    nationality: 'Moroccan',
  },
  {
    num_code: '508',
    alpha_2_code: 'MZ',
    alpha_3_code: 'MOZ',
    en_short_name: 'Mozambique',
    nationality: 'Mozambican',
  },
  {
    num_code: '104',
    alpha_2_code: 'MM',
    alpha_3_code: 'MMR',
    en_short_name: 'Myanmar',
    nationality: 'Burmese',
  },
  {
    num_code: '516',
    alpha_2_code: 'NA',
    alpha_3_code: 'NAM',
    en_short_name: 'Namibia',
    nationality: 'Namibian',
  },
  {
    num_code: '520',
    alpha_2_code: 'NR',
    alpha_3_code: 'NRU',
    en_short_name: 'Nauru',
    nationality: 'Nauruan',
  },
  {
    num_code: '524',
    alpha_2_code: 'NP',
    alpha_3_code: 'NPL',
    en_short_name: 'Nepal',
    nationality: 'Nepali, Nepalese',
  },
  {
    num_code: '528',
    alpha_2_code: 'NL',
    alpha_3_code: 'NLD',
    en_short_name: 'Netherlands',
    nationality: 'Dutch',
  },
  {
    num_code: '540',
    alpha_2_code: 'NC',
    alpha_3_code: 'NCL',
    en_short_name: 'New Caledonia',
    nationality: 'New Caledonian',
  },
  {
    num_code: '554',
    alpha_2_code: 'NZ',
    alpha_3_code: 'NZL',
    en_short_name: 'New Zealand',
    nationality: 'Kiwi',
  },
  {
    num_code: '558',
    alpha_2_code: 'NI',
    alpha_3_code: 'NIC',
    en_short_name: 'Nicaragua',
    nationality: 'Nicaraguan',
  },
  {
    num_code: '562',
    alpha_2_code: 'NE',
    alpha_3_code: 'NER',
    en_short_name: 'Niger',
    nationality: 'Nigerien',
  },
  {
    num_code: '566',
    alpha_2_code: 'NG',
    alpha_3_code: 'NGA',
    en_short_name: 'Nigeria',
    nationality: 'Nigerian',
  },
  {
    num_code: '570',
    alpha_2_code: 'NU',
    alpha_3_code: 'NIU',
    en_short_name: 'Niue',
    nationality: 'Niuean',
  },
  {
    num_code: '574',
    alpha_2_code: 'NF',
    alpha_3_code: 'NFK',
    en_short_name: 'Norfolk Island',
    nationality: 'Norfolk Island',
  },
  {
    num_code: '580',
    alpha_2_code: 'MP',
    alpha_3_code: 'MNP',
    en_short_name: 'Northern Mariana Islands',
    nationality: 'Northern Marianan',
  },
  {
    num_code: '578',
    alpha_2_code: 'NO',
    alpha_3_code: 'NOR',
    en_short_name: 'Norway',
    nationality: 'Norwegian',
  },
  {
    num_code: '512',
    alpha_2_code: 'OM',
    alpha_3_code: 'OMN',
    en_short_name: 'Oman',
    nationality: 'Omani',
  },
  {
    num_code: '586',
    alpha_2_code: 'PK',
    alpha_3_code: 'PAK',
    en_short_name: 'Pakistan',
    nationality: 'Pakistani',
  },
  {
    num_code: '585',
    alpha_2_code: 'PW',
    alpha_3_code: 'PLW',
    en_short_name: 'Palau',
    nationality: 'Palauan',
  },
  {
    num_code: '275',
    alpha_2_code: 'PS',
    alpha_3_code: 'PSE',
    en_short_name: 'Palestine, State of',
    nationality: 'Palestinian',
  },
  {
    num_code: '591',
    alpha_2_code: 'PA',
    alpha_3_code: 'PAN',
    en_short_name: 'Panama',
    nationality: 'Panamanian',
  },
  {
    num_code: '598',
    alpha_2_code: 'PG',
    alpha_3_code: 'PNG',
    en_short_name: 'Papua New Guinea',
    nationality: 'Papua New Guinean',
  },
  {
    num_code: '600',
    alpha_2_code: 'PY',
    alpha_3_code: 'PRY',
    en_short_name: 'Paraguay',
    nationality: 'Paraguayan',
  },
  {
    num_code: '604',
    alpha_2_code: 'PE',
    alpha_3_code: 'PER',
    en_short_name: 'Peru',
    nationality: 'Peruvian',
  },
  {
    num_code: '608',
    alpha_2_code: 'PH',
    alpha_3_code: 'PHL',
    en_short_name: 'Philippines',
    nationality: 'Filipino',
  },
  {
    num_code: '612',
    alpha_2_code: 'PN',
    alpha_3_code: 'PCN',
    en_short_name: 'Pitcairn',
    nationality: 'Pitcairn Island',
  },
  {
    num_code: '616',
    alpha_2_code: 'PL',
    alpha_3_code: 'POL',
    en_short_name: 'Poland',
    nationality: 'Polish',
  },
  {
    num_code: '620',
    alpha_2_code: 'PT',
    alpha_3_code: 'PRT',
    en_short_name: 'Portugal',
    nationality: 'Portuguese',
  },
  {
    num_code: '630',
    alpha_2_code: 'PR',
    alpha_3_code: 'PRI',
    en_short_name: 'Puerto Rico',
    nationality: 'Puerto Rican',
  },
  {
    num_code: '634',
    alpha_2_code: 'QA',
    alpha_3_code: 'QAT',
    en_short_name: 'Qatar',
    nationality: 'Qatari',
  },
  {
    num_code: '638',
    alpha_2_code: 'RE',
    alpha_3_code: 'REU',
    en_short_name: 'R\u00e9union',
    nationality: 'R\u00e9unionese, R\u00e9unionnais',
  },
  {
    num_code: '642',
    alpha_2_code: 'RO',
    alpha_3_code: 'ROU',
    en_short_name: 'Romania',
    nationality: 'Romanian',
  },
  {
    num_code: '643',
    alpha_2_code: 'RU',
    alpha_3_code: 'RUS',
    en_short_name: 'Russian Federation',
    nationality: 'Russian',
  },
  {
    num_code: '646',
    alpha_2_code: 'RW',
    alpha_3_code: 'RWA',
    en_short_name: 'Rwanda',
    nationality: 'Rwandan',
  },
  {
    num_code: '652',
    alpha_2_code: 'BL',
    alpha_3_code: 'BLM',
    en_short_name: 'Saint Barth\u00e9lemy',
    nationality: 'Barth\u00e9lemois',
  },
  {
    num_code: '654',
    alpha_2_code: 'SH',
    alpha_3_code: 'SHN',
    en_short_name: 'Saint Helena, Ascension and Tristan da Cunha',
    nationality: 'Saint Helenian',
  },
  {
    num_code: '659',
    alpha_2_code: 'KN',
    alpha_3_code: 'KNA',
    en_short_name: 'Saint Kitts and Nevis',
    nationality: 'Kittitian or Nevisian',
  },
  {
    num_code: '662',
    alpha_2_code: 'LC',
    alpha_3_code: 'LCA',
    en_short_name: 'Saint Lucia',
    nationality: 'Saint Lucian',
  },
  {
    num_code: '663',
    alpha_2_code: 'MF',
    alpha_3_code: 'MAF',
    en_short_name: 'Saint Martin (French part)',
    nationality: 'Saint-Martinoise',
  },
  {
    num_code: '666',
    alpha_2_code: 'PM',
    alpha_3_code: 'SPM',
    en_short_name: 'Saint Pierre and Miquelon',
    nationality: 'Saint-Pierrais or Miquelonnais',
  },
  {
    num_code: '670',
    alpha_2_code: 'VC',
    alpha_3_code: 'VCT',
    en_short_name: 'Saint Vincent and the Grenadines',
    nationality: 'Saint Vincentian, Vincentian',
  },
  {
    num_code: '882',
    alpha_2_code: 'WS',
    alpha_3_code: 'WSM',
    en_short_name: 'Samoa',
    nationality: 'Samoan',
  },
  {
    num_code: '674',
    alpha_2_code: 'SM',
    alpha_3_code: 'SMR',
    en_short_name: 'San Marino',
    nationality: 'Sammarinese',
  },
  {
    num_code: '678',
    alpha_2_code: 'ST',
    alpha_3_code: 'STP',
    en_short_name: 'Sao Tome and Principe',
    nationality: 'S\u00e3o Tom\u00e9an',
  },
  {
    num_code: '682',
    alpha_2_code: 'SA',
    alpha_3_code: 'SAU',
    en_short_name: 'Saudi Arabia',
    nationality: 'Saudi, Saudi Arabian',
  },
  {
    num_code: '686',
    alpha_2_code: 'SN',
    alpha_3_code: 'SEN',
    en_short_name: 'Senegal',
    nationality: 'Senegalese',
  },
  {
    num_code: '688',
    alpha_2_code: 'RS',
    alpha_3_code: 'SRB',
    en_short_name: 'Serbia',
    nationality: 'Serbian',
  },
  {
    num_code: '690',
    alpha_2_code: 'SC',
    alpha_3_code: 'SYC',
    en_short_name: 'Seychelles',
    nationality: 'Seychellois',
  },
  {
    num_code: '694',
    alpha_2_code: 'SL',
    alpha_3_code: 'SLE',
    en_short_name: 'Sierra Leone',
    nationality: 'Sierra Leonean',
  },
  {
    num_code: '702',
    alpha_2_code: 'SG',
    alpha_3_code: 'SGP',
    en_short_name: 'Singapore',
    nationality: 'Singaporean',
  },
  {
    num_code: '534',
    alpha_2_code: 'SX',
    alpha_3_code: 'SXM',
    en_short_name: 'Sint Maarten (Dutch part)',
    nationality: 'Sint Maarten',
  },
  {
    num_code: '703',
    alpha_2_code: 'SK',
    alpha_3_code: 'SVK',
    en_short_name: 'Slovakia',
    nationality: 'Slovak',
  },
  {
    num_code: '705',
    alpha_2_code: 'SI',
    alpha_3_code: 'SVN',
    en_short_name: 'Slovenia',
    nationality: 'Slovenian, Slovene',
  },
  {
    num_code: '90',
    alpha_2_code: 'SB',
    alpha_3_code: 'SLB',
    en_short_name: 'Solomon Islands',
    nationality: 'Solomon Island',
  },
  {
    num_code: '706',
    alpha_2_code: 'SO',
    alpha_3_code: 'SOM',
    en_short_name: 'Somalia',
    nationality: 'Somali',
  },
  {
    num_code: '000',
    alpha_2_code: 'XX',
    alpha_3_code: 'XXX',
    en_short_name: 'Somaliland',
    nationality: 'Somalilander',
  },
  {
    num_code: '710',
    alpha_2_code: 'ZA',
    alpha_3_code: 'ZAF',
    en_short_name: 'South Africa',
    nationality: 'South African',
  },
  {
    num_code: '239',
    alpha_2_code: 'GS',
    alpha_3_code: 'SGS',
    en_short_name: 'South Georgia and the South Sandwich Islands',
    nationality: 'South Georgia or South Sandwich Islands',
  },
  {
    num_code: '724',
    alpha_2_code: 'ES',
    alpha_3_code: 'ESP',
    en_short_name: 'Spain',
    nationality: 'Spanish',
  },
  {
    num_code: '144',
    alpha_2_code: 'LK',
    alpha_3_code: 'LKA',
    en_short_name: 'Sri Lanka',
    nationality: 'Sri Lankan',
  },
  {
    num_code: '729',
    alpha_2_code: 'SD',
    alpha_3_code: 'SDN',
    en_short_name: 'Sudan',
    nationality: 'Sudanese',
  },
  {
    num_code: '728',
    alpha_2_code: 'SS',
    alpha_3_code: 'SSD',
    en_short_name: 'South Sudan',
    nationality: 'South Sudanese',
  },
  {
    num_code: '740',
    alpha_2_code: 'SR',
    alpha_3_code: 'SUR',
    en_short_name: 'Suriname',
    nationality: 'Surinamese',
  },
  {
    num_code: '744',
    alpha_2_code: 'SJ',
    alpha_3_code: 'SJM',
    en_short_name: 'Svalbard and Jan Mayen',
    nationality: 'Svalbard',
  },
  {
    num_code: '748',
    alpha_2_code: 'SZ',
    alpha_3_code: 'SWZ',
    en_short_name: 'Swaziland',
    nationality: 'Swazi',
  },
  {
    num_code: '752',
    alpha_2_code: 'SE',
    alpha_3_code: 'SWE',
    en_short_name: 'Sweden',
    nationality: 'Swedish',
  },
  {
    num_code: '756',
    alpha_2_code: 'CH',
    alpha_3_code: 'CHE',
    en_short_name: 'Switzerland',
    nationality: 'Swiss',
  },
  {
    num_code: '760',
    alpha_2_code: 'SY',
    alpha_3_code: 'SYR',
    en_short_name: 'Syrian Arab Republic',
    nationality: 'Syrian',
  },
  {
    num_code: '158',
    alpha_2_code: 'TW',
    alpha_3_code: 'TWN',
    en_short_name: 'Taiwan, Province of China',
    nationality: 'Chinese, Taiwanese',
  },
  {
    num_code: '762',
    alpha_2_code: 'TJ',
    alpha_3_code: 'TJK',
    en_short_name: 'Tajikistan',
    nationality: 'Tajikistani',
  },
  {
    num_code: '834',
    alpha_2_code: 'TZ',
    alpha_3_code: 'TZA',
    en_short_name: 'Tanzania, United Republic of',
    nationality: 'Tanzanian',
  },
  {
    num_code: '764',
    alpha_2_code: 'TH',
    alpha_3_code: 'THA',
    en_short_name: 'Thailand',
    nationality: 'Thai',
  },
  {
    num_code: '626',
    alpha_2_code: 'TL',
    alpha_3_code: 'TLS',
    en_short_name: 'Timor-Leste',
    nationality: 'Timorese',
  },
  {
    num_code: '768',
    alpha_2_code: 'TG',
    alpha_3_code: 'TGO',
    en_short_name: 'Togo',
    nationality: 'Togolese',
  },
  {
    num_code: '772',
    alpha_2_code: 'TK',
    alpha_3_code: 'TKL',
    en_short_name: 'Tokelau',
    nationality: 'Tokelauan',
  },
  {
    num_code: '776',
    alpha_2_code: 'TO',
    alpha_3_code: 'TON',
    en_short_name: 'Tonga',
    nationality: 'Tongan',
  },
  {
    num_code: '780',
    alpha_2_code: 'TT',
    alpha_3_code: 'TTO',
    en_short_name: 'Trinidad and Tobago',
    nationality: 'Trinidadian or Tobagonian',
  },
  {
    num_code: '788',
    alpha_2_code: 'TN',
    alpha_3_code: 'TUN',
    en_short_name: 'Tunisia',
    nationality: 'Tunisian',
  },
  {
    num_code: '792',
    alpha_2_code: 'TR',
    alpha_3_code: 'TUR',
    en_short_name: 'Turkey',
    nationality: 'Turkish',
  },
  {
    num_code: '795',
    alpha_2_code: 'TM',
    alpha_3_code: 'TKM',
    en_short_name: 'Turkmenistan',
    nationality: 'Turkmen',
  },
  {
    num_code: '796',
    alpha_2_code: 'TC',
    alpha_3_code: 'TCA',
    en_short_name: 'Turks and Caicos Islands',
    nationality: 'Turks and Caicos Island',
  },
  {
    num_code: '798',
    alpha_2_code: 'TV',
    alpha_3_code: 'TUV',
    en_short_name: 'Tuvalu',
    nationality: 'Tuvaluan',
  },
  {
    num_code: '800',
    alpha_2_code: 'UG',
    alpha_3_code: 'UGA',
    en_short_name: 'Uganda',
    nationality: 'Ugandan',
  },
  {
    num_code: '804',
    alpha_2_code: 'UA',
    alpha_3_code: 'UKR',
    en_short_name: 'Ukraine',
    nationality: 'Ukrainian',
  },
  {
    num_code: '784',
    alpha_2_code: 'AE',
    alpha_3_code: 'ARE',
    en_short_name: 'United Arab Emirates',
    nationality: 'Emirati',
  },
  {
    num_code: '826',
    alpha_2_code: 'GB',
    alpha_3_code: 'GBR',
    en_short_name: 'United Kingdom of Great Britain and Northern Ireland',
    nationality: 'British',
  },
  {
    num_code: '581',
    alpha_2_code: 'UM',
    alpha_3_code: 'UMI',
    en_short_name: 'United States Minor Outlying Islands',
    nationality: 'American',
  },
  {
    num_code: '840',
    alpha_2_code: 'US',
    alpha_3_code: 'USA',
    en_short_name: 'United States of America',
    nationality: 'American',
  },
  {
    num_code: '858',
    alpha_2_code: 'UY',
    alpha_3_code: 'URY',
    en_short_name: 'Uruguay',
    nationality: 'Uruguayan',
  },
  {
    num_code: '860',
    alpha_2_code: 'UZ',
    alpha_3_code: 'UZB',
    en_short_name: 'Uzbekistan',
    nationality: 'Uzbek',
  },
  {
    num_code: '548',
    alpha_2_code: 'VU',
    alpha_3_code: 'VUT',
    en_short_name: 'Vanuatu',
    nationality: 'Ni-Vanuatu, Vanuatuan',
  },
  {
    num_code: '862',
    alpha_2_code: 'VE',
    alpha_3_code: 'VEN',
    en_short_name: 'Venezuela (Bolivarian Republic of)',
    nationality: 'Venezuelan',
  },
  {
    num_code: '704',
    alpha_2_code: 'VN',
    alpha_3_code: 'VNM',
    en_short_name: 'Vietnam',
    nationality: 'Vietnamese',
  },
  {
    num_code: '92',
    alpha_2_code: 'VG',
    alpha_3_code: 'VGB',
    en_short_name: 'Virgin Islands (British)',
    nationality: 'British Virgin Island',
  },
  {
    num_code: '850',
    alpha_2_code: 'VI',
    alpha_3_code: 'VIR',
    en_short_name: 'Virgin Islands (U.S.)',
    nationality: 'U.S. Virgin Island',
  },
  {
    num_code: '876',
    alpha_2_code: 'WF',
    alpha_3_code: 'WLF',
    en_short_name: 'Wallis and Futuna',
    nationality: 'Wallis and Futuna, Wallisian or Futunan',
  },
  {
    num_code: '732',
    alpha_2_code: 'EH',
    alpha_3_code: 'ESH',
    en_short_name: 'Western Sahara',
    nationality: 'Sahrawi, Sahrawian, Sahraouian',
  },
  {
    num_code: '887',
    alpha_2_code: 'YE',
    alpha_3_code: 'YEM',
    en_short_name: 'Yemen',
    nationality: 'Yemeni',
  },
  {
    num_code: '894',
    alpha_2_code: 'ZM',
    alpha_3_code: 'ZMB',
    en_short_name: 'Zambia',
    nationality: 'Zambian',
  },
  {
    num_code: '716',
    alpha_2_code: 'ZW',
    alpha_3_code: 'ZWE',
    en_short_name: 'Zimbabwe',
    nationality: 'Zimbabwean',
  },
];

var SovereignCountriesList = [
  { id: 4, alpha2: 'af', alpha3: 'afg', name: 'Afghanistan' },
  { id: 8, alpha2: 'al', alpha3: 'alb', name: 'Albania' },
  { id: 12, alpha2: 'dz', alpha3: 'dza', name: 'Algeria' },
  // { id: 20, alpha2: 'ad', alpha3: 'and', name: 'Andorra' },
  { id: 24, alpha2: 'ao', alpha3: 'ago', name: 'Angola' },
  // { id: 28, alpha2: 'ag', alpha3: 'atg', name: 'Antigua and Barbuda' },
  { id: 32, alpha2: 'ar', alpha3: 'arg', name: 'Argentina' },
  { id: 51, alpha2: 'am', alpha3: 'arm', name: 'Armenia' },
  { id: 36, alpha2: 'au', alpha3: 'aus', name: 'Australia' },
  { id: 40, alpha2: 'at', alpha3: 'aut', name: 'Austria' },
  { id: 31, alpha2: 'az', alpha3: 'aze', name: 'Azerbaijan' },
  // { id: 44, alpha2: 'bs', alpha3: 'bhs', name: 'Bahamas' },
  { id: 48, alpha2: 'bh', alpha3: 'bhr', name: 'Bahrain' },
  { id: 50, alpha2: 'bd', alpha3: 'bgd', name: 'Bangladesh' },
  // { id: 52, alpha2: 'bb', alpha3: 'brb', name: 'Barbados' },
  { id: 112, alpha2: 'by', alpha3: 'blr', name: 'Belarus' },
  { id: 56, alpha2: 'be', alpha3: 'bel', name: 'Belgium' },
  // { id: 84, alpha2: 'bz', alpha3: 'blz', name: 'Belize' },
  { id: 204, alpha2: 'bj', alpha3: 'ben', name: 'Benin' },
  // { id: 64, alpha2: 'bt', alpha3: 'btn', name: 'Bhutan' },
  {
    id: 68,
    alpha2: 'bo',
    alpha3: 'bol',
    name: 'Bolivia (Plurinational State of)',
  },
  { id: 70, alpha2: 'ba', alpha3: 'bih', name: 'Bosnia and Herzegovina' },
  { id: 72, alpha2: 'bw', alpha3: 'bwa', name: 'Botswana' },
  { id: 76, alpha2: 'br', alpha3: 'bra', name: 'Brazil' },
  { id: 96, alpha2: 'bn', alpha3: 'brn', name: 'Brunei Darussalam' },
  { id: 100, alpha2: 'bg', alpha3: 'bgr', name: 'Bulgaria' },
  { id: 854, alpha2: 'bf', alpha3: 'bfa', name: 'Burkina Faso' },
  { id: 108, alpha2: 'bi', alpha3: 'bdi', name: 'Burundi' },
  // { id: 132, alpha2: 'cv', alpha3: 'cpv', name: 'Cabo Verde' },
  { id: 116, alpha2: 'kh', alpha3: 'khm', name: 'Cambodia' },
  { id: 120, alpha2: 'cm', alpha3: 'cmr', name: 'Cameroon' },
  { id: 124, alpha2: 'ca', alpha3: 'can', name: 'Canada' },
  { id: 140, alpha2: 'cf', alpha3: 'caf', name: 'Central African Republic' },
  { id: 148, alpha2: 'td', alpha3: 'tcd', name: 'Chad' },
  { id: 152, alpha2: 'cl', alpha3: 'chl', name: 'Chile' },
  { id: 156, alpha2: 'cn', alpha3: 'chn', name: 'China' },
  { id: 170, alpha2: 'co', alpha3: 'col', name: 'Colombia' },
  { id: 174, alpha2: 'km', alpha3: 'com', name: 'Comoros' },
  { id: 178, alpha2: 'cg', alpha3: 'cog', name: 'Congo' },
  {
    id: 180,
    alpha2: 'cd',
    alpha3: 'cod',
    name: 'Congo, Democratic Republic of the',
  },
  { id: 188, alpha2: 'cr', alpha3: 'cri', name: 'Costa Rica' },
  // { id: 384, alpha2: 'ci', alpha3: 'civ', name: "Côte d'Ivoire" },
  { id: 191, alpha2: 'hr', alpha3: 'hrv', name: 'Croatia' },
  { id: 192, alpha2: 'cu', alpha3: 'cub', name: 'Cuba' },
  { id: 196, alpha2: 'cy', alpha3: 'cyp', name: 'Cyprus' },
  { id: 203, alpha2: 'cz', alpha3: 'cze', name: 'Czechia' },
  { id: 208, alpha2: 'dk', alpha3: 'dnk', name: 'Denmark' },
  { id: 262, alpha2: 'dj', alpha3: 'dji', name: 'Djibouti' },
  { id: 212, alpha2: 'dm', alpha3: 'dma', name: 'Dominica' },
  { id: 214, alpha2: 'do', alpha3: 'dom', name: 'Dominican Republic' },
  { id: 218, alpha2: 'ec', alpha3: 'ecu', name: 'Ecuador' },
  { id: 818, alpha2: 'eg', alpha3: 'egy', name: 'Egypt' },
  { id: 222, alpha2: 'sv', alpha3: 'slv', name: 'El Salvador' },
  { id: 226, alpha2: 'gq', alpha3: 'gnq', name: 'Equatorial Guinea' },
  { id: 232, alpha2: 'er', alpha3: 'eri', name: 'Eritrea' },
  { id: 233, alpha2: 'ee', alpha3: 'est', name: 'Estonia' },
  // { id: 748, alpha2: 'sz', alpha3: 'swz', name: 'Eswatini' },
  { id: 231, alpha2: 'et', alpha3: 'eth', name: 'Ethiopia' },
  { id: 242, alpha2: 'fj', alpha3: 'fji', name: 'Fiji' },
  { id: 246, alpha2: 'fi', alpha3: 'fin', name: 'Finland' },
  { id: 250, alpha2: 'fr', alpha3: 'fra', name: 'France' },
  { id: 266, alpha2: 'ga', alpha3: 'gab', name: 'Gabon' },
  { id: 270, alpha2: 'gm', alpha3: 'gmb', name: 'Gambia' },
  { id: 268, alpha2: 'ge', alpha3: 'geo', name: 'Georgia' },
  { id: 276, alpha2: 'de', alpha3: 'deu', name: 'Germany' },
  { id: 288, alpha2: 'gh', alpha3: 'gha', name: 'Ghana' },
  { id: 300, alpha2: 'gr', alpha3: 'grc', name: 'Greece' },
  // { id: 308, alpha2: 'gd', alpha3: 'grd', name: 'Grenada' },
  { id: 320, alpha2: 'gt', alpha3: 'gtm', name: 'Guatemala' },
  { id: 324, alpha2: 'gn', alpha3: 'gin', name: 'Guinea' },
  { id: 624, alpha2: 'gw', alpha3: 'gnb', name: 'Guinea-Bissau' },
  // { id: 328, alpha2: 'gy', alpha3: 'guy', name: 'Guyana' },
  // { id: 332, alpha2: 'ht', alpha3: 'hti', name: 'Haiti' },
  { id: 340, alpha2: 'hn', alpha3: 'hnd', name: 'Honduras' },
  { id: 348, alpha2: 'hu', alpha3: 'hun', name: 'Hungary' },
  { id: 352, alpha2: 'is', alpha3: 'isl', name: 'Iceland' },
  { id: 356, alpha2: 'in', alpha3: 'ind', name: 'India' },
  { id: 360, alpha2: 'id', alpha3: 'idn', name: 'Indonesia' },
  { id: 364, alpha2: 'ir', alpha3: 'irn', name: 'Iran (Islamic Republic of)' },
  { id: 368, alpha2: 'iq', alpha3: 'irq', name: 'Iraq' },
  { id: 372, alpha2: 'ie', alpha3: 'irl', name: 'Ireland' },
  { id: 376, alpha2: 'il', alpha3: 'isr', name: 'Israel' },
  { id: 380, alpha2: 'it', alpha3: 'ita', name: 'Italy' },
  { id: 388, alpha2: 'jm', alpha3: 'jam', name: 'Jamaica' },
  { id: 392, alpha2: 'jp', alpha3: 'jpn', name: 'Japan' },
  { id: 400, alpha2: 'jo', alpha3: 'jor', name: 'Jordan' },
  { id: 398, alpha2: 'kz', alpha3: 'kaz', name: 'Kazakhstan' },
  { id: 404, alpha2: 'ke', alpha3: 'ken', name: 'Kenya' },
  // { id: 296, alpha2: 'ki', alpha3: 'kir', name: 'Kiribati' },
  {
    id: 408,
    alpha2: 'kp',
    alpha3: 'prk',
    name: "Korea (Democratic People's Republic of)",
  },
  { id: 410, alpha2: 'kr', alpha3: 'kor', name: 'Korea, Republic of' },
  { id: 414, alpha2: 'kw', alpha3: 'kwt', name: 'Kuwait' },
  { id: 417, alpha2: 'kg', alpha3: 'kgz', name: 'Kyrgyzstan' },
  {
    id: 418,
    alpha2: 'la',
    alpha3: 'lao',
    name: "Lao People's Democratic Republic",
  },
  { id: 428, alpha2: 'lv', alpha3: 'lva', name: 'Latvia' },
  { id: 422, alpha2: 'lb', alpha3: 'lbn', name: 'Lebanon' },
  { id: 426, alpha2: 'ls', alpha3: 'lso', name: 'Lesotho' },
  { id: 430, alpha2: 'lr', alpha3: 'lbr', name: 'Liberia' },
  { id: 434, alpha2: 'ly', alpha3: 'lby', name: 'Libya' },
  // { id: 438, alpha2: 'li', alpha3: 'lie', name: 'Liechtenstein' },
  { id: 440, alpha2: 'lt', alpha3: 'ltu', name: 'Lithuania' },
  { id: 442, alpha2: 'lu', alpha3: 'lux', name: 'Luxembourg' },
  { id: 450, alpha2: 'mg', alpha3: 'mdg', name: 'Madagascar' },
  { id: 454, alpha2: 'mw', alpha3: 'mwi', name: 'Malawi' },
  { id: 458, alpha2: 'my', alpha3: 'mys', name: 'Malaysia' },
  { id: 462, alpha2: 'mv', alpha3: 'mdv', name: 'Maldives' },
  { id: 466, alpha2: 'ml', alpha3: 'mli', name: 'Mali' },
  { id: 470, alpha2: 'mt', alpha3: 'mlt', name: 'Malta' },
  { id: 584, alpha2: 'mh', alpha3: 'mhl', name: 'Marshall Islands' },
  { id: 478, alpha2: 'mr', alpha3: 'mrt', name: 'Mauritania' },
  { id: 480, alpha2: 'mu', alpha3: 'mus', name: 'Mauritius' },
  { id: 484, alpha2: 'mx', alpha3: 'mex', name: 'Mexico' },
  // {
  //   id: 583,
  //   alpha2: 'fm',
  //   alpha3: 'fsm',
  //   name: 'Micronesia (Federated States of)',
  // },
  // { id: 498, alpha2: 'md', alpha3: 'mda', name: 'Moldova, Republic of' },
  // { id: 492, alpha2: 'mc', alpha3: 'mco', name: 'Monaco' },
  { id: 496, alpha2: 'mn', alpha3: 'mng', name: 'Mongolia' },
  // { id: 499, alpha2: 'me', alpha3: 'mne', name: 'Montenegro' },
  { id: 504, alpha2: 'ma', alpha3: 'mar', name: 'Morocco' },
  { id: 508, alpha2: 'mz', alpha3: 'moz', name: 'Mozambique' },
  { id: 104, alpha2: 'mm', alpha3: 'mmr', name: 'Myanmar' },
  { id: 516, alpha2: 'na', alpha3: 'nam', name: 'Namibia' },
  // { id: 520, alpha2: 'nr', alpha3: 'nru', name: 'Nauru' },
  { id: 524, alpha2: 'np', alpha3: 'npl', name: 'Nepal' },
  { id: 528, alpha2: 'nl', alpha3: 'nld', name: 'Netherlands' },
  { id: 554, alpha2: 'nz', alpha3: 'nzl', name: 'New Zealand' },
  { id: 558, alpha2: 'ni', alpha3: 'nic', name: 'Nicaragua' },
  { id: 562, alpha2: 'ne', alpha3: 'ner', name: 'Niger' },
  { id: 566, alpha2: 'ng', alpha3: 'nga', name: 'Nigeria' },
  { id: 807, alpha2: 'mk', alpha3: 'mkd', name: 'North Macedonia' },
  { id: 578, alpha2: 'no', alpha3: 'nor', name: 'Norway' },
  { id: 512, alpha2: 'om', alpha3: 'omn', name: 'Oman' },
  { id: 586, alpha2: 'pk', alpha3: 'pak', name: 'Pakistan' },
  // { id: 585, alpha2: 'pw', alpha3: 'plw', name: 'Palau' },
  { id: 0, alpha2: 'ps', alpha3: 'pse', name: 'Palestine' },
  { id: 591, alpha2: 'pa', alpha3: 'pan', name: 'Panama' },
  { id: 598, alpha2: 'pg', alpha3: 'png', name: 'Papua New Guinea' },
  { id: 600, alpha2: 'py', alpha3: 'pry', name: 'Paraguay' },
  { id: 604, alpha2: 'pe', alpha3: 'per', name: 'Peru' },
  { id: 608, alpha2: 'ph', alpha3: 'phl', name: 'Philippines' },
  { id: 616, alpha2: 'pl', alpha3: 'pol', name: 'Poland' },
  { id: 620, alpha2: 'pt', alpha3: 'prt', name: 'Portugal' },
  { id: 634, alpha2: 'qa', alpha3: 'qat', name: 'Qatar' },
  { id: 642, alpha2: 'ro', alpha3: 'rou', name: 'Romania' },
  { id: 643, alpha2: 'ru', alpha3: 'rus', name: 'Russian Federation' },
  { id: 646, alpha2: 'rw', alpha3: 'rwa', name: 'Rwanda' },
  // { id: 659, alpha2: 'kn', alpha3: 'kna', name: 'Saint Kitts and Nevis' },
  // { id: 662, alpha2: 'lc', alpha3: 'lca', name: 'Saint Lucia' },
  // {
  //   id: 670,
  //   alpha2: 'vc',
  //   alpha3: 'vct',
  //   name: 'Saint Vincent and the Grenadines',
  // },
  // { id: 882, alpha2: 'ws', alpha3: 'wsm', name: 'Samoa' },
  // { id: 674, alpha2: 'sm', alpha3: 'smr', name: 'San Marino' },
  // { id: 678, alpha2: 'st', alpha3: 'stp', name: 'Sao Tome and Principe' },
  { id: 682, alpha2: 'sa', alpha3: 'sau', name: 'Saudi Arabia' },
  { id: 686, alpha2: 'sn', alpha3: 'sen', name: 'Senegal' },
  { id: 688, alpha2: 'rs', alpha3: 'srb', name: 'Serbia' },
  { id: 690, alpha2: 'sc', alpha3: 'syc', name: 'Seychelles' },
  { id: 694, alpha2: 'sl', alpha3: 'sle', name: 'Sierra Leone' },
  { id: 702, alpha2: 'sg', alpha3: 'sgp', name: 'Singapore' },
  { id: 703, alpha2: 'sk', alpha3: 'svk', name: 'Slovakia' },
  { id: 705, alpha2: 'si', alpha3: 'svn', name: 'Slovenia' },
  { id: 90, alpha2: 'sb', alpha3: 'slb', name: 'Solomon Islands' },
  { id: 706, alpha2: 'so', alpha3: 'som', name: 'Somalia' },
  { id: 710, alpha2: 'za', alpha3: 'zaf', name: 'South Africa' },
  { id: 728, alpha2: 'ss', alpha3: 'ssd', name: 'South Sudan' },
  { id: 724, alpha2: 'es', alpha3: 'esp', name: 'Spain' },
  { id: 144, alpha2: 'lk', alpha3: 'lka', name: 'Sri Lanka' },
  { id: 729, alpha2: 'sd', alpha3: 'sdn', name: 'Sudan' },
  { id: 740, alpha2: 'sr', alpha3: 'sur', name: 'Suriname' },
  { id: 752, alpha2: 'se', alpha3: 'swe', name: 'Sweden' },
  { id: 756, alpha2: 'ch', alpha3: 'che', name: 'Switzerland' },
  { id: 760, alpha2: 'sy', alpha3: 'syr', name: 'Syrian Arab Republic' },
  { id: 762, alpha2: 'tj', alpha3: 'tjk', name: 'Tajikistan' },
  {
    id: 834,
    alpha2: 'tz',
    alpha3: 'tza',
    name: 'Tanzania, United Republic of',
  },
  { id: 764, alpha2: 'th', alpha3: 'tha', name: 'Thailand' },
  // { id: 626, alpha2: 'tl', alpha3: 'tls', name: 'Timor-Leste' },
  { id: 768, alpha2: 'tg', alpha3: 'tgo', name: 'Togo' },
  { id: 776, alpha2: 'to', alpha3: 'ton', name: 'Tonga' },
  // { id: 780, alpha2: 'tt', alpha3: 'tto', name: 'Trinidad and Tobago' },
  { id: 788, alpha2: 'tn', alpha3: 'tun', name: 'Tunisia' },
  { id: 792, alpha2: 'tr', alpha3: 'tur', name: 'Türkiye' },
  // { id: 795, alpha2: 'tm', alpha3: 'tkm', name: 'Turkmenistan' },
  // { id: 798, alpha2: 'tv', alpha3: 'tuv', name: 'Tuvalu' },
  { id: 800, alpha2: 'ug', alpha3: 'uga', name: 'Uganda' },
  { id: 804, alpha2: 'ua', alpha3: 'ukr', name: 'Ukraine' },
  { id: 784, alpha2: 'ae', alpha3: 'are', name: 'United Arab Emirates' },
  {
    id: 826,
    alpha2: 'gb',
    alpha3: 'gbr',
    name: 'United Kingdom of Great Britain and Northern Ireland',
  },
  { id: 840, alpha2: 'us', alpha3: 'usa', name: 'United States of America' },
  { id: 858, alpha2: 'uy', alpha3: 'ury', name: 'Uruguay' },
  { id: 860, alpha2: 'uz', alpha3: 'uzb', name: 'Uzbekistan' },
  // { id: 548, alpha2: 'vu', alpha3: 'vut', name: 'Vanuatu' },
  {
    id: 862,
    alpha2: 've',
    alpha3: 'ven',
    name: 'Venezuela (Bolivarian Republic of)',
  },
  { id: 704, alpha2: 'vn', alpha3: 'vnm', name: 'Viet Nam' },
  { id: 887, alpha2: 'ye', alpha3: 'yem', name: 'Yemen' },
  { id: 894, alpha2: 'zm', alpha3: 'zmb', name: 'Zambia' },
  { id: 716, alpha2: 'zw', alpha3: 'zwe', name: 'Zimbabwe' },
];

var CountryCurrencies = {
  BD: 'BDT',
  BE: 'EUR',
  BF: 'XOF',
  BG: 'BGN',
  BA: 'BAM',
  BB: 'BBD',
  WF: 'XPF',
  BL: 'EUR',
  BM: 'BMD',
  BN: 'BND',
  BO: 'BOB',
  BH: 'BHD',
  BI: 'BIF',
  BJ: 'XOF',
  BT: 'BTN',
  JM: 'JMD',
  BV: 'NOK',
  BW: 'BWP',
  WS: 'WST',
  BQ: 'USD',
  BR: 'BRL',
  BS: 'BSD',
  JE: 'GBP',
  BY: 'BYR',
  BZ: 'BZD',
  RU: 'RUB',
  RW: 'RWF',
  RS: 'RSD',
  TL: 'USD',
  RE: 'EUR',
  TM: 'TMT',
  TJ: 'TJS',
  RO: 'RON',
  TK: 'NZD',
  GW: 'XOF',
  GU: 'USD',
  GT: 'GTQ',
  GS: 'GBP',
  GR: 'EUR',
  GQ: 'XAF',
  GP: 'EUR',
  JP: 'JPY',
  GY: 'GYD',
  GG: 'GBP',
  GF: 'EUR',
  GE: 'GEL',
  GD: 'XCD',
  GB: 'GBP',
  GA: 'XAF',
  SV: 'USD',
  GN: 'GNF',
  GM: 'GMD',
  GL: 'DKK',
  GI: 'GIP',
  GH: 'GHS',
  OM: 'OMR',
  TN: 'TND',
  JO: 'JOD',
  HR: 'HRK',
  HT: 'HTG',
  HU: 'HUF',
  HK: 'HKD',
  HN: 'HNL',
  HM: 'AUD',
  VE: 'VEF',
  PR: 'USD',
  PS: 'ILS',
  PW: 'USD',
  PT: 'EUR',
  SJ: 'NOK',
  PY: 'PYG',
  IQ: 'IQD',
  PA: 'PAB',
  PF: 'XPF',
  PG: 'PGK',
  PE: 'PEN',
  PK: 'PKR',
  PH: 'PHP',
  PN: 'NZD',
  PL: 'PLN',
  PM: 'EUR',
  ZM: 'ZMK',
  EH: 'MAD',
  EE: 'EUR',
  EG: 'EGP',
  ZA: 'ZAR',
  EC: 'USD',
  IT: 'EUR',
  VN: 'VND',
  SB: 'SBD',
  ET: 'ETB',
  SO: 'SOS',
  ZW: 'ZWL',
  SA: 'SAR',
  ES: 'EUR',
  ER: 'ERN',
  ME: 'EUR',
  MD: 'MDL',
  MG: 'MGA',
  MF: 'EUR',
  MA: 'MAD',
  MC: 'EUR',
  UZ: 'UZS',
  MM: 'MMK',
  ML: 'XOF',
  MO: 'MOP',
  MN: 'MNT',
  MH: 'USD',
  MK: 'MKD',
  MU: 'MUR',
  MT: 'EUR',
  MW: 'MWK',
  MV: 'MVR',
  MQ: 'EUR',
  MP: 'USD',
  MS: 'XCD',
  MR: 'MRO',
  IM: 'GBP',
  UG: 'UGX',
  TZ: 'TZS',
  MY: 'MYR',
  MX: 'MXN',
  IL: 'ILS',
  FR: 'EUR',
  IO: 'USD',
  SH: 'SHP',
  FI: 'EUR',
  FJ: 'FJD',
  FK: 'FKP',
  FM: 'USD',
  FO: 'DKK',
  NI: 'NIO',
  NL: 'EUR',
  NO: 'NOK',
  NA: 'NAD',
  VU: 'VUV',
  NC: 'XPF',
  NE: 'XOF',
  NF: 'AUD',
  NG: 'NGN',
  NZ: 'NZD',
  NP: 'NPR',
  NR: 'AUD',
  NU: 'NZD',
  CK: 'NZD',
  XK: 'EUR',
  CI: 'XOF',
  CH: 'CHF',
  CO: 'COP',
  CN: 'CNY',
  CM: 'XAF',
  CL: 'CLP',
  CC: 'AUD',
  CA: 'CAD',
  CG: 'XAF',
  CF: 'XAF',
  CD: 'CDF',
  CZ: 'CZK',
  CY: 'EUR',
  CX: 'AUD',
  CR: 'CRC',
  CW: 'ANG',
  CV: 'CVE',
  CU: 'CUP',
  SZ: 'SZL',
  SY: 'SYP',
  SX: 'ANG',
  KG: 'KGS',
  KE: 'KES',
  SS: 'SSP',
  SR: 'SRD',
  KI: 'AUD',
  KH: 'KHR',
  KN: 'XCD',
  KM: 'KMF',
  ST: 'STD',
  SK: 'EUR',
  KR: 'KRW',
  SI: 'EUR',
  KP: 'KPW',
  KW: 'KWD',
  SN: 'XOF',
  SM: 'EUR',
  SL: 'SLL',
  SC: 'SCR',
  KZ: 'KZT',
  KY: 'KYD',
  SG: 'SGD',
  SE: 'SEK',
  SD: 'SDG',
  DO: 'DOP',
  DM: 'XCD',
  DJ: 'DJF',
  DK: 'DKK',
  VG: 'USD',
  DE: 'EUR',
  YE: 'YER',
  DZ: 'DZD',
  US: 'USD',
  UY: 'UYU',
  YT: 'EUR',
  UM: 'USD',
  LB: 'LBP',
  LC: 'XCD',
  LA: 'LAK',
  TV: 'AUD',
  TW: 'TWD',
  TT: 'TTD',
  TR: 'TRY',
  LK: 'LKR',
  LI: 'CHF',
  LV: 'EUR',
  TO: 'TOP',
  LT: 'LTL',
  LU: 'EUR',
  LR: 'LRD',
  LS: 'LSL',
  TH: 'THB',
  TF: 'EUR',
  TG: 'XOF',
  TD: 'XAF',
  TC: 'USD',
  LY: 'LYD',
  VA: 'EUR',
  VC: 'XCD',
  AE: 'AED',
  AD: 'EUR',
  AG: 'XCD',
  AF: 'AFN',
  AI: 'XCD',
  VI: 'USD',
  IS: 'ISK',
  IR: 'IRR',
  AM: 'AMD',
  AL: 'ALL',
  AO: 'AOA',
  AQ: '',
  AS: 'USD',
  AR: 'ARS',
  AU: 'AUD',
  AT: 'EUR',
  AW: 'AWG',
  IN: 'INR',
  AX: 'EUR',
  AZ: 'AZN',
  IE: 'EUR',
  ID: 'IDR',
  UA: 'UAH',
  QA: 'QAR',
  MZ: 'MZN',
  XX: 'SLS',
};

var SwedishMunicipalityList = [
  ['Ale'],
  ['Alingsås', 'Alingsas'],
  ['Älmhult', 'Almhult'],
  ['Älvdalen', 'Alvdalen'],
  ['Alvesta'],
  ['Älvkarleby', 'Alvkarleby'],
  ['Älvsbyn', 'Alvsbyn'],
  ['Åmål', 'Amal'],
  ['Aneby'],
  ['Ånge', 'Ange'],
  ['Ängelholm', 'Angelholm'],
  ['Arboga'],
  ['Åre', 'Are'],
  ['Årjäng', 'Arjang'],
  ['Arjeplog'],
  ['Arvidsjaur'],
  ['Arvika'],
  ['Åsele', 'Asele'],
  ['Askersund'],
  ['Åstorp', 'Astorp'],
  ['Åtvidaberg', 'Atvidaberg'],
  ['Avesta'],
  ['Båstad', 'Bastad'],
  ['Bengtsfors'],
  ['Berg'],
  ['Bjurholm'],
  ['Bjuv'],
  ['Boden'],
  ['Bollebygd'],
  ['Bollnäs', 'Bollnas'],
  ['Borås', 'Boras'],
  ['Borgholm'],
  ['Borlänge', 'Borlange'],
  ['Botkyrka'],
  ['Boxholm'],
  ['Bräcke', 'Bracke'],
  ['Bromölla', 'Bromolla'],
  ['Burlöv', 'Burlov'],
  ['Dals-Ed', 'Dals Ed'],
  ['Danderyd'],
  ['Degerfors'],
  ['Dorotea'],
  ['Eda'],
  ['Ekerö', 'Ekero'],
  ['Eksjö', 'Eksjo'],
  ['Emmaboda'],
  ['Enköping', 'Enkoping', 'Enk÷ping'],
  ['Eskilstuna'],
  ['Eslöv', 'Eslov'],
  ['Essunga'],
  ['Fagersta'],
  ['Falkenberg'],
  ['Falköping', 'Falkoping'],
  ['Falun'],
  ['Färgelanda', 'Fargelanda'],
  ['Filipstad', 'Filipistad'],
  ['Finspång', 'Finspang'],
  ['Flen'],
  ['Forshaga'],
  ['Gagnef'],
  ['Gällivare', 'Gallivare'],
  ['Gävle', 'Gavle'],
  ['Gislaved'],
  ['Gnesta'],
  ['Gnosjö', 'Gnosjo'],
  ['Göteborg', 'Goteborg', 'Gothenburg'],
  ['Götene', 'Gotene'],
  ['Gotland'],
  ['Grästorp', 'Grastorp'],
  ['Grums'],
  ['Gullspång', 'Gullspang'],
  ['Habo'],
  ['Håbo', 'Habo'],
  ['Hagfors'],
  ['Hällefors', 'Hallefors'],
  ['Hallsberg'],
  ['Hallstahammar'],
  ['Halmstad'],
  ['Hammarö', 'Hammaro'],
  ['Haninge'],
  ['Haparanda'],
  ['Härjedalen', 'Harjedalen'],
  ['Härnösand', 'Harnosand'],
  ['Härryda', 'Harryda'],
  ['Hässleholm', 'Hassleholm'],
  ['Heby'],
  ['Hedemora'],
  ['Helsingborg'],
  ['Herrljunga'],
  ['Hjo'],
  ['Hofors'],
  ['Höganäs', 'Hoganas'],
  ['Högsby', 'Hogsby'],
  ['Höör', 'Hoor'],
  ['Hörby', 'Horby'],
  ['Huddinge'],
  ['Hudiksvall'],
  ['Hultsfred'],
  ['Hylte'],
  ['Järfälla', 'Jarfalla'],
  ['Jokkmokk'],
  ['Jönköping', 'Jonkoping'],
  ['Kalix'],
  ['Kalmar'],
  ['Karlsborg'],
  ['Karlshamn'],
  ['Karlskoga'],
  ['Karlskrona'],
  ['Karlstad'],
  ['Katrineholm'],
  ['Kävlinge', 'Kavlinge'],
  ['Kil'],
  ['Kinda'],
  ['Kiruna'],
  ['Klippan'],
  ['Knivsta'],
  ['Köping', 'Koping'],
  ['Kramfors'],
  ['Kristianstad'],
  ['Kristinehamn'],
  ['Krokom'],
  ['Kumla'],
  ['Kungälv', 'Kungalv'],
  ['Kungsbacka'],
  ['Kungsör', 'Kungsor'],
  ['Laholm'],
  ['Landskrona'],
  ['Laxå', 'Laxa'],
  ['Lekeberg'],
  ['Leksand'],
  ['Lerum'],
  ['Lessebo'],
  ['Lidingö', 'Lidingo'],
  ['Lidköping', 'Lidkoping'],
  ['Lilla Edet'],
  ['Lindesberg'],
  ['Linköping', 'Linkoping'],
  ['Ljungby'],
  ['Ljusdal'],
  ['Ljusnarsberg'],
  ['Lomma'],
  ['Ludvika'],
  ['Luleå', 'Lulea'],
  ['Lund'],
  ['Lycksele'],
  ['Lysekil'],
  ['Malå', 'Mala'],
  ['Malmö', 'Malmo'],
  ['Malung-Sälen', 'Malung-Salen', 'Malung Sälen', 'Malung Salen'],
  ['Mariestad'],
  ['Mark'],
  ['Markaryd'],
  ['Mellerud'],
  ['Mjölby', 'Mjolby'],
  ['Mölndal', 'Molndal'],
  ['Mönsterås', 'Monsteras'],
  ['Mora'],
  ['Mörbylånga', 'Morbylanga'],
  ['Motala'],
  ['Mullsjö', 'Mullsjo'],
  ['Munkedal'],
  ['Munkfors'],
  ['Nacka'],
  ['Nässjö', 'Nassjo'],
  ['Nora'],
  ['Norberg'],
  ['Nordanstig'],
  ['Nordmaling'],
  ['Norrköping', 'Norrkoping'],
  ['Norrtälje', 'Norrtalje'],
  ['Norsjö', 'Norsjo'],
  ['Nybro'],
  ['Nyköping', 'Nykoping'],
  ['Nykvarn'],
  ['Nynäshamn', 'Nynashamn'],
  ['Ockelbo'],
  ['Öckerö', 'Ockero'],
  ['Ödeshög', 'Odeshog'],
  ['Olofström', 'Olofstrom'],
  ['Örebro', 'Orebro'],
  ['Örkelljunga', 'Orkelljunga'],
  ['Örnsköldsvik', 'Ornskoldsvik'],
  ['Orsa'],
  ['Orust'],
  ['Osby'],
  ['Oskarshamn'],
  ['Österåker', 'Osteraker'],
  ['Östersund', 'Ostersund'],
  ['Östhammar', 'Osthammar'],
  ['Östra Göinge', 'Ostra Goinge'],
  ['Ovanåker', 'Ovanaker'],
  ['Överkalix', 'Overkalix'],
  ['Övertorneå', 'Overtornea'],
  ['Oxelösund', 'Oxelosund'],
  ['Pajala'],
  ['Partille'],
  ['Perstorp'],
  ['Piteå', 'Pitea'],
  ['Ragunda'],
  ['Rättvik', 'Rattvik'],
  ['Robertsfors'],
  ['Ronneby'],
  ['Säffle', 'Saffle'],
  ['Sala'],
  ['Salem'],
  ['Sandviken'],
  ['Säter', 'Sater'],
  ['Sävsjö', 'Savsjo'],
  ['Sigtuna'],
  ['Simrishamn'],
  ['Sjöbo', 'Sjobo'],
  ['Skara'],
  ['Skellefteå', 'Skelleftea'],
  ['Skinnskatteberg'],
  ['Skövde', 'Skovde'],
  ['Skurup'],
  ['Smedjebacken'],
  ['Söderhamn', 'Soderhamn'],
  ['Söderköping', 'Soderkoping'],
  ['Södertälje', 'Sodertalje'],
  ['Sollefteå', 'Solleftea'],
  ['Sollentuna'],
  ['Solna'],
  ['Sölvesborg', 'Solvesborg'],
  ['Sorsele'],
  ['Sotenäs', 'Sotenas'],
  ['Staffanstorp'],
  ['Stenungsund'],
  ['Stockholm'],
  ['Storfors'],
  ['Storuman'],
  ['Strängnäs', 'Strangnas'],
  ['Strömstad', 'Stromstad'],
  ['Strömsund', 'Stromsund'],
  ['Sundbyberg'],
  ['Sundsvall'],
  ['Sunne'],
  ['Surahammar'],
  ['Svalöv', 'Svalov'],
  ['Svedala'],
  ['Svenljunga'],
  ['Täby', 'Taby'],
  ['Tanum'],
  ['Tibro'],
  ['Tidaholm'],
  ['Tierp'],
  ['Timrå', 'Timra'],
  ['Tingsryd'],
  ['Tjörn', 'Tjorn'],
  ['Tomelilla'],
  ['Töreboda', 'Toreboda'],
  ['Torsås', 'Torsas'],
  ['Torsby'],
  ['Tranås', 'Tranas'],
  ['Tranemo'],
  ['Trelleborg'],
  ['Trollhättan', 'Trollhattan'],
  ['Trosa'],
  ['Tyresö', 'Tyreso'],
  ['Uddevalla'],
  ['Ulricehamn'],
  ['Umeå', 'Umea'],
  ['Upplands Väsby', 'Upplands Vasby'],
  ['Upplands-Bro', 'Upplands Bro'],
  ['Uppsala'],
  ['Uppvidinge'],
  ['Vadstena'],
  ['Vaggeryd'],
  ['Valdemarsvik'],
  ['Vallentuna'],
  ['Vänersborg', 'Vanersborg'],
  ['Vännäs', 'Vannas'],
  ['Vansbro'],
  ['Vara'],
  ['Varberg'],
  ['Vårgårda', 'Vargarda'],
  ['Värmdö', 'Varmdo'],
  ['Värnamo', 'Varnamo'],
  ['Västerås', 'Vasteras'],
  ['Västervik', 'Vastervik'],
  ['Vaxholm'],
  ['Växjö', 'Vaxjo'],
  ['Vellinge'],
  ['Vetlanda'],
  ['Vilhelmina'],
  ['Vimmerby'],
  ['Vindeln'],
  ['Vingåker', 'Vingaker'],
  ['Ydre'],
  ['Ystad'],
];

const PurposeList = [
  { name: 'Family Support', value: 'Family Support' },
  { name: 'Salary Payment', value: 'Salary Payment' },
  { name: 'Treasury Payment', value: 'Treasury Payment' },
  { name: 'Advance Payment', value: 'Advance Payment' },
  { name: 'Bonus Payment', value: 'Bonus Payment' },
  { name: 'Charity Payment', value: 'Charity Payment' },
  { name: 'Commercial Payment', value: 'Commercial Payment' },
  { name: 'Commission', value: 'Commission' },
  { name: 'Costs', value: 'Costs' },
  { name: 'Purchase Sale Of Goods', value: 'Purchase Sale Of Goods' },
  { name: 'Government Payment', value: 'Government Payment' },
  { name: 'Intra Company Payment', value: 'Intra Company Payment' },
  { name: 'Loan', value: 'Loan' },
  { name: 'Loan Repayment', value: 'Loan Repayment' },
  { name: 'Netting', value: 'Netting' },
  { name: 'Payroll', value: 'Payroll' },
  { name: 'Pension Payment', value: 'Pension Payment' },
  { name: 'Refund', value: 'Refund' },
  { name: 'Rent', value: 'Rent' },
  { name: 'Purchase Sale Of Services', value: 'Purchase Sale Of Services' },
  { name: 'Securities', value: 'Securities' },
  { name: 'Social Security Benefit', value: 'Social Security Benefit' },
  { name: 'Subscription', value: 'Subscription' },
  { name: 'Tax Payment', value: 'Tax Payment' },
  { name: 'Value Added Tax Payment', value: 'Value Added Tax Payment' },
  { name: 'Card Payment', value: 'Card Payment' },
  { name: 'Hospital Care', value: 'Hospital Care' },
  { name: 'Cable TV Bill', value: 'Cable TV Bill' },
  { name: 'Electricity Bill', value: 'Electricity Bill' },
  { name: 'Gas Bill', value: 'Gas Bill' },
  { name: 'Telephone Bill', value: 'Telephone Bill' },
  { name: 'Other Telecom Related Bill', value: 'Other Telecom Related Bill' },
  { name: 'Water Bill', value: 'Water Bill' },
  { name: 'Study', value: 'Study' },
  { name: 'Price Payment', value: 'Price Payment' },
  { name: 'Installment', value: 'Installment' },
  { name: 'Cancellation Fee', value: 'Cancellation Fee' },
  { name: 'Government Insurance', value: 'Government Insurance' },
  { name: 'Insurance Premium Car', value: 'Insurance Premium Car' },
  { name: 'Labor Insurance', value: 'Labor Insurance' },
  { name: 'Life Insurance', value: 'Life Insurance' },
  { name: 'Property Insurance', value: 'Property Insurance' },
  { name: 'Health Insurance', value: 'Health Insurance' },
  { name: 'Estate Tax', value: 'Estate Tax' },
  { name: 'Housing Tax', value: 'Housing Tax' },
  { name: 'Income Tax', value: 'Income Tax' },
  { name: 'Net Income Tax', value: 'Net Income Tax' },
  { name: 'Business Expenses', value: 'Business Expenses' },
  { name: 'Trust Fund', value: 'Trust Fund' },
  { name: 'Network Charge', value: 'Network Charge' },
  { name: 'Network Communication', value: 'Network Communication' },
  { name: 'Receipt Payment', value: 'Receipt Payment' },
  { name: 'Payment Terms', value: 'Payment Terms' },
  { name: 'Other', value: 'Other' },
  { name: 'With Holding', value: 'With Holding' },
];

const _SwedishMunicipalityList = SwedishMunicipalityList;
export { _SwedishMunicipalityList as SwedishMunicipalityList };
const _SovereignCountriesList = SovereignCountriesList;
export { _SovereignCountriesList as SovereignCountriesList };
const _NationalityCountryCodes = NationalityCountryCodes;
export { _NationalityCountryCodes as NationalityCountryCodes };
const _CountryDialIsoFlags = CountryDialIsoFlags;
export { _CountryDialIsoFlags as CountryDialIsoFlags };
const _CountryCurrencies = CountryCurrencies;
export { _CountryCurrencies as CountryCurrencies };
const _PurposeList = PurposeList;
export { _PurposeList as PurposeList };

export const DefactoCountryFlag = (code) => {
  return require(`../assets/images/flags/${code}.svg.png`).default;
};
