import { makeStyles } from '@mui/styles';

const mainLayoutStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
    },
  };
});

export default mainLayoutStyles;
