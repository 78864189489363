import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  countries: [],
  senderCountries: [],
  senderCountriesFetched: false,
  sendingCountries: [],
  sendingCountriesFetched: false,
  sendingCities: {},
  sendingBranches: {},
  receivingCountries: [],
  receivingCountriesFetched: false,
  recipientCountries: [],
  recipientCountriesFetched: false,
  receivingCities: {},
  receivingCityCodes: {},
  payoutChannels: {},
  receivingPickupBranches: {},
  receivingAgentBranches: {},
  loading: false,
  error: false,
  errorMessage: '',
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.error = false;
        state.errorMessage = '';
      }
      state.loading = isLoading;
    },
    setError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    setCountries(state, action) {
      state.countries = action.payload;
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    setSenderCountries(state, action) {
      state.senderCountries = action.payload;
      state.senderCountriesFetched = true;
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    setSendingCountries(state, action) {
      state.sendingCountries = action.payload;
      state.sendingCountriesFetched = true;
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    setReceivingCountries(state, action) {
      state.receivingCountries = action.payload;
      state.receivingCountriesFetched = true;
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    setRecipientCountries(state, action) {
      state.recipientCountries = action.payload;
      state.recipientCountriesFetched = true;
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    setSendingCities(state, action) {
      const { country, cities } = action.payload;

      state.sendingCities = { ...state.sendingCities, [country]: cities };
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    setReceivingCities(state, action) {
      const { country, cities } = action.payload;

      state.receivingCities = { ...state.receivingCities, [country]: cities };
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    setReceivingCityCodes(state, action) {
      const { country, cityCodes } = action.payload;

      state.receivingCityCodes = {
        ...state.receivingCityCodes,
        [country]: cityCodes,
      };
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    setPayoutChannels(state, action) {
      const { countryCode, payoutChannels } = action.payload;

      state.payoutChannels = {
        ...state.payoutChannels,
        [countryCode]: payoutChannels,
      };
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    setSendingBranches(state, action) {
      const { city, branches } = action.payload;

      state.sendingBranches = { ...state.sendingBranches, [city]: branches };
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    setReceivingBranches(state, action) {
      const { city, branches } = action.payload;

      state.receivingPickupBranches = {
        ...state.receivingPickupBranches,
        [city]: branches,
      };
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    setReceivingAgentBranches(state, action) {
      const { countryCode, branches } = action.payload;

      state.receivingAgentBranches = {
        ...state.receivingAgentBranches,
        [countryCode]: branches,
      };
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    clearRecipientData(state, action) {
      state.recipientCountries = [];
      state.recipientCountriesFetched = false;
      state.receivingCities = {};
      state.receivingPickupBranches = {};
    },
    logout: (state) => initialState,
  },
});

export const locationActions = locationSlice.actions;
export default locationSlice;
