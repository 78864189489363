//import Jsona from 'jsona';

import config from '../../config';
import http from '../api/http';
import { rateActions } from '../slices/rate-slice';
import { requestQuote } from './quote-actions';

export const fetchLocalCountries = () => {
  const apiBaseUrl =
    config.REACT_APP_IDENTITY_BASE_URL +
    config.REACT_APP_FOREX_LOCAL_COUNTRIES +
    config.REACT_APP_TENANT.toLowerCase();

  const request = http.get(apiBaseUrl);

  return (dispatch) => {
    dispatch(
      rateActions.setLoadingLocalCountries({
        isLoading: true,
        willClearError: true,
      })
    );

    return request.then(
      ({ data }) => {
        dispatch(rateActions.setLocalCountries(data.data));
      },
      (error) => {
        let errorMessage = config.generalError;

        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            errorMessage = error.response.data.message;
          } else if (
            error.response.data.errors.message &&
            typeof error.response.data.errors.message === 'string'
          ) {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Fetch localCountries failed!';
          }
        }

        console.log('Rate fetchLocalCountries error: ' + errorMessage);

        dispatch(rateActions.setErrorLocalCountries(errorMessage));
      }
    );
  };
};

export const fetchRemitOptions = (countryCode) => {
  const apiBaseUrl =
    config.REACT_APP_IDENTITY_BASE_URL +
    config.REACT_APP_FOREX_OPTIONS +
    config.REACT_APP_TENANT.toLowerCase();

  let params = {};
  if (countryCode) {
    params = {
      countryCode,
    };
  }

  const request = http.get(apiBaseUrl, { params });

  return (dispatch) => {
    dispatch(
      rateActions.setLoadingRemitOptions({
        isLoading: true,
        willClearError: true,
      })
    );

    return request.then(
      ({ data }) => {
        dispatch(rateActions.setRemitOptions(data.data));
      },
      (error) => {
        let errorMessage = config.generalError;

        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            errorMessage = error.response.data.message;
          } else if (
            error.response.data.errors.message &&
            typeof error.response.data.errors.message === 'string'
          ) {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Fetch remitOptions failed!';
          }
        }

        console.log('Rate fetchRemitOptions error: ' + errorMessage);

        dispatch(rateActions.setErrorRemitOptions(errorMessage));
      }
    );
  };
};

export const computeRate = (
  productId,
  serviceId,
  sendingCurrency,
  receivingCurrency,
  amount,
  isSending,
  receivingCountryCode = null,
  channelId,
  businessCode,
  isLoggedIn,
  userId
) => {
  const apiBaseUrl =
    config.REACT_APP_IDENTITY_BASE_URL + config.REACT_APP_FOREX_COMPUTE;

  const params = {
    data: {
      type: 'Fx',
      attributes: {
        productId,
        serviceId,
        sendingCurrency,
        receivingCurrency,
        amount,
        isSending,
        receivingCountryCode,
        businessCode,
        channelId,
        tenant: config.REACT_APP_TENANT,
      },
    },
  };

  const request = http.post(apiBaseUrl, params);

  return (dispatch) => {
    dispatch(
      rateActions.setLoadingComputedRate({
        isLoading: true,
        willClearError: true,
      })
    );

    return request.then(
      ({ data }) => {
        dispatch(rateActions.setComputedRate({ ...data.data, isSending }));
      },
      (error) => {
        if (isLoggedIn) {
          dispatch(
            requestQuote(
              {
                product: productId,
                channelid: channelId,
                sendingcurrency: sendingCurrency,
                receivingcurrency: receivingCurrency,
                serviceid: serviceId,
                issending: isSending,
                amount,
                receivingCountryCode,
                businessCode,
              },
              receivingCountryCode,
              true,
              userId
            )
          );
        } else {
          let errorMessage = config.generalError;

          try {
            if (error.messages && typeof error.messages === 'string') {
              errorMessage = error.messages;
            } else if (
              error.response.data.message &&
              typeof error.response.data.message === 'string'
            ) {
              errorMessage = error.response.data.message;
            } else if (
              error.response.data.errors.message &&
              typeof error.response.data.errors.message === 'string'
            ) {
              errorMessage = error.response.data.errors.message;
            }
          } catch {
            console.log('error.response: ', error.response);
            console.log('error.request: ', error.request);
            console.log('error.config: ', error.config);
            if (error.response) {
              errorMessage = 'Fetch computedRate failed!';
            }
          }

          console.log('Rate computeRate error: ' + errorMessage);

          dispatch(rateActions.setErrorComputedRate(errorMessage));
        }
      }
    );
  };
};

export const resetRemitOptions = () => {
  return (dispatch) => {
    dispatch(rateActions.clearRemitOptions());
  };
};

export const resetComputedRate = () => {
  return (dispatch) => {
    dispatch(rateActions.clearComputedRate());
  };
};
