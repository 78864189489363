import Jsona from 'jsona';

import http from '../api/http';
import { kycActions } from '../slices/kyc-slice';
import { locationActions } from '../slices/location-slice';

import config from '../../config';
import {
    CountryDialIsoFlags,
    NationalityCountryCodes,
    SwedishMunicipalityList,
} from '../../library/dssc-country-nationality-municipality-codes';
import { isEmpty } from '../../library/utils';

export const fetchCountries = (businessCode, type = 'STANDARD') => {
    let apiBaseUrl = config.REACT_APP_IDENTITY_BASE_URL;

    switch (type.toUpperCase()) {
        case 'SENDING':
            apiBaseUrl += config.REACT_APP_SENDER_COUNTRIES_LIST_URI;
            break;
        case 'SENDER':
            apiBaseUrl += config.REACT_APP_SOURCE_COUNTRIES_LISTINGS_URI;
            break;

        case 'RECEIVING':
        case 'RECIPIENT':
            apiBaseUrl += config.REACT_APP_DESTINATION_COUNTRIES_LISTINGS_URI;
            break;

        default:
            apiBaseUrl += config.REACT_APP_RECIPIENT_COUNTRIES_LIST_URI;
            break;
    }

    let params = {
        tenant: config.REACT_APP_TENANT,
    };

    if (businessCode) {
        params = {
            ...params,
            businessCode,
        };
    }

    const request = http.get(apiBaseUrl, { params });

    return (dispatch) => {
        dispatch(
            locationActions.setLoading({
                isLoading: true,
                willClearError: true,
            }),
        );

        return request.then(
            ({ data }) => {
                const countries = new Jsona().deserialize(data);

                switch (type.toUpperCase()) {
                    case 'SENDER':
                        dispatch(
                            locationActions.setSenderCountries(
                                countries.map((c) => {
                                    return {
                                        id: c.id,
                                        code: c.code,
                                        isoCode: c.isoCode ?? c.code,
                                        countryName: c.name,
                                    };
                                }),
                            ),
                        );
                        break;

                    case 'RECEIVING':
                        let tempReceivingCountries = [...countries];

                        if (config.REACT_APP_FX_RATE_MODE === 'LOCAL') {
                            tempReceivingCountries = [
                                ...tempReceivingCountries,
                                ...CountryDialIsoFlags.filter((c) => !tempReceivingCountries.some((trc) => trc.isoCode === c.isoCode)).map(
                                    (c) => {
                                        return {
                                            id: c.isoCode,
                                            code: c.isoCode,
                                            isoCode: c.isoCode,
                                            countryName: c.name,
                                        };
                                    },
                                ),
                            ];
                        }
                        dispatch(
                            locationActions.setReceivingCountries(
                                tempReceivingCountries.sort((a, b) =>
                                    a.countryName > b.countryName ? 1 : b.countryName > a.countryName ? -1 : 0,
                                ),
                            ),
                        );
                        break;

                    case 'SENDING':
                        dispatch(locationActions.setSendingCountries(countries));
                        break;

                    case 'RECIPIENT':
                        dispatch(locationActions.setRecipientCountries(countries));
                        break;

                    default:
                        dispatch(locationActions.setCountries(countries));
                        break;
                }
            },
            (error) => {
                let errorMessage = config.generalError;

                try {
                    if (error.messages && typeof error.messages === 'string') {
                        errorMessage = error.messages;
                    } else if (error.response.data.message && typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (error.response.data.errors.message && typeof error.response.data.errors.message === 'string') {
                        errorMessage = error.response.data.errors.message;
                    }
                } catch {
                    console.log('error.response: ', error.response);
                    console.log('error.request: ', error.request);
                    console.log('error.config: ', error.config);
                    if (error.response) {
                        errorMessage = 'Fetch ' + type.toLowerCase() + ' countries failed!';
                    }
                }

                console.log('Location fetchCountries error: ' + errorMessage);

                dispatch(locationActions.setError(errorMessage));
            },
        );
    };
};

export const fetchAgentCountries = (type = 'STANDARD') => {
    let apiBaseUrl = config.REACT_APP_IDENTITY_BASE_URL;

    switch (type.toUpperCase()) {
        case 'RECEIVING':
        case 'RECIPIENT':
            apiBaseUrl += config.REACT_APP_RECEIVING_AGENT_COUNTRIES_LISTINGS_URI;
            break;

        default:
            apiBaseUrl += config.REACT_APP_RECEIVING_AGENT_COUNTRIES_LISTINGS_URI;
            break;
    }

    const data = {
        userId: config.REACT_APP_RECEIVING_AGENT_USERID,
        companyID: config.REACT_APP_RECEIVING_AGENT_COMPANY_ID,
    };

    const configs = {
        params: {
            tenant: config.REACT_APP_TENANT,
        },
    };

    const request = http.post(apiBaseUrl, data, configs);

    return (dispatch) => {
        dispatch(
            locationActions.setLoading({
                isLoading: true,
                willClearError: true,
            }),
        );

        return request.then(
            ({ data }) => {
                const countries = data.countryList;

                switch (type.toUpperCase()) {
                    case 'RECEIVING':
                        dispatch(
                            locationActions.setReceivingCountries(
                                countries
                                    ?.filter((c) => c.countryCode)
                                    ?.map((c) => {
                                        return {
                                            id: c.id || c.countryCode,
                                            code: c.code || c.countryCode,
                                            isoCode: c.isoCode || c.code || c.countryCode,
                                            countryName: c.name || c.country,
                                        };
                                    }),
                            ),
                        );
                        break;

                    case 'RECIPIENT':
                        dispatch(
                            locationActions.setRecipientCountries(
                                countries
                                    ?.filter((c) => c.countryCode)
                                    ?.map((c) => {
                                        return {
                                            id: c.id || c.countryCode,
                                            code: c.code || c.countryCode,
                                            isoCode: c.isoCode || c.code || c.countryCode,
                                            countryName: c.name || c.country,
                                        };
                                    }),
                            ),
                        );
                        break;

                    default:
                        dispatch(locationActions.setCountries(countries));
                        break;
                }
            },
            (error) => {
                let errorMessage = config.generalError;

                try {
                    if (error.messages && typeof error.messages === 'string') {
                        errorMessage = error.messages;
                    } else if (error.response.data.message && typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (error.response.data.errors.message && typeof error.response.data.errors.message === 'string') {
                        errorMessage = error.response.data.errors.message;
                    }
                } catch {
                    console.log('error.response: ', error.response);
                    console.log('error.request: ', error.request);
                    console.log('error.config: ', error.config);
                    if (error.response) {
                        errorMessage = 'Fetch ' + type.toLowerCase() + ' agent countries failed!';
                    }
                }

                console.log('Location fetchAgentCountries error: ' + errorMessage);

                dispatch(locationActions.setError(errorMessage));
            },
        );
    };
};

export const fetchCountriesFromLibrary = (type = 'STANDARD', isFromLibrary = false) => {
    return (dispatch) => {
        dispatch(
            locationActions.setLoading({
                isLoading: true,
                willClearError: true,
            }),
        );

        const countries = CountryDialIsoFlags.map((c) => {
            return {
                id: c.isoCode,
                code: NationalityCountryCodes.find((cc) => cc.alpha_2_code === c.isoCode)?.alpha_3_code ?? c.isoCode,
                isoCode: c.isoCode,
                countryName: c.name,
            };
        });

        switch (type.toUpperCase()) {
            case 'SENDING':
                const countryConfigList = config.REACT_APP_SOURCE_COUNTRIES_LIST ? config.REACT_APP_SOURCE_COUNTRIES_LIST.split(',') : [];

                const returnCountries = isEmpty(countryConfigList)
                    ? countries
                    : countries.filter((c) => countryConfigList.includes(c.isoCode));

                dispatch(locationActions.setSendingCountries(returnCountries));
                break;

            case 'RECEIVING':
                dispatch(locationActions.setReceivingCountries(countries));
                break;

            case 'RECIPIENT':
                dispatch(locationActions.setRecipientCountries(countries));
                break;

            default:
                dispatch(locationActions.setCountries(countries));
                break;
        }
    };
};

export const fetchCities = (businessCode, countryCode, countryId, tenant, type = 'STANDARD', mode, cityString, userData) => {
    let apiBaseUrl = config.REACT_APP_IDENTITY_BASE_URL;

    switch (type.toUpperCase()) {
        case 'SENDING':
            apiBaseUrl += config.REACT_APP_SOURCE_CITIES_LISTINGS_URI;
            break;

        case 'RECEIVING':
        case 'CITYCODE':
            apiBaseUrl += config.REACT_APP_DESTINATION_CITIES_LISTINGS_URI;
            break;

        default:
            apiBaseUrl += config.REACT_APP_DESTINATION_CITIES_LISTINGS_URI;
            break;
    }

    let params = {};

    if (businessCode) {
        params = {
            ...params,
            businessCode,
        };
    }
    if (countryCode) {
        params = {
            ...params,
            countryCode,
        };
    }
    if (countryId) {
        params = {
            ...params,
            countryId,
        };
    }
    if (tenant) {
        params = {
            ...params,
            tenant,
        };
    }

    const request = http.get(apiBaseUrl, { params });

    return (dispatch) => {
        dispatch(
            locationActions.setLoading({
                isLoading: true,
                willClearError: true,
            }),
        );

        return request.then(
            ({ data }) => {
                const cities = new Jsona().deserialize(data);

                switch (type.toUpperCase()) {
                    case 'SENDING':
                        if (mode === 'kyc') {
                            dispatch(
                                locationActions.setLoading({
                                    isLoading: false,
                                    willClearError: true,
                                }),
                            );

                            let foundCity = cities.find((c) => c.name === cityString);

                            if (foundCity) {
                                userData.addressCity = foundCity.id.toString();
                            } else {
                                const cityArray = SwedishMunicipalityList.find((a) => a.includes(cityString));
                                if (cityArray) {
                                    foundCity = cities.find((c) => cityArray.includes(c.name));
                                    if (foundCity) {
                                        userData.addressCity = foundCity.id.toString();
                                    } else {
                                        userData.addressCity = '';
                                    }
                                }
                            }

                            dispatch(kycActions.setUserKYCData(userData));
                        } else {
                            dispatch(
                                locationActions.setSendingCities({
                                    country: countryId,
                                    cities: cities ?? [],
                                }),
                            );
                        }
                        break;

                    case 'RECEIVING':
                        dispatch(
                            locationActions.setReceivingCities({
                                country: countryCode,
                                cities: cities ?? [],
                            }),
                        );
                        break;

                    case 'CITYCODE':
                        dispatch(
                            locationActions.setReceivingCityCodes({
                                country: countryCode,
                                cityCodes: cities ?? [],
                            }),
                        );
                        break;

                    default:
                        dispatch(
                            locationActions.setSendingCities({
                                country: countryCode,
                                cities: cities ?? [],
                            }),
                        );
                        dispatch(
                            locationActions.setReceivingCities({
                                country: countryCode,
                                cities: cities ?? [],
                            }),
                        );
                        break;
                }
            },
            (error) => {
                let errorMessage = config.generalError;

                try {
                    if (error.messages && typeof error.messages === 'string') {
                        errorMessage = error.messages;
                    } else if (error.response.data.message && typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (error.response.data.errors.message && typeof error.response.data.errors.message === 'string') {
                        errorMessage = error.response.data.errors.message;
                    }
                } catch {
                    console.log('error.response: ', error.response);
                    console.log('error.request: ', error.request);
                    console.log('error.config: ', error.config);
                    if (error.response) {
                        errorMessage = 'Fetch ' + type.toLowerCase() + ' cities failed!';
                    }
                }

                console.log('Location fetchCities error: ' + errorMessage);

                dispatch(locationActions.setError(errorMessage));
            },
        );
    };
};

export const fetchPickupBranches = (businessCode, cityCode, type = 'STANDARD') => {
    let apiBaseUrl = config.REACT_APP_IDENTITY_BASE_URL;

    switch (type.toUpperCase()) {
        case 'SENDING':
            apiBaseUrl += config.REACT_APP_SOURCE_BRANCHES_LISTINGS_URI;
            break;

        case 'RECEIVING':
            apiBaseUrl += config.REACT_APP_DESTINATION_PICKUP_BRANCHES_LISTINGS_URI;
            break;

        default:
            break;
    }

    const params = {
        businessCode,
        cityCode,
    };

    const request = http.get(apiBaseUrl, { params });

    return (dispatch) => {
        dispatch(
            locationActions.setLoading({
                isLoading: true,
                willClearError: true,
            }),
        );

        return request.then(
            ({ data }) => {
                const branches = new Jsona().deserialize(data);

                switch (type.toUpperCase()) {
                    case 'SENDING':
                        dispatch(locationActions.setSendingBranches({ city: cityCode, branches }));
                        break;

                    case 'RECEIVING':
                        dispatch(
                            locationActions.setReceivingBranches({
                                city: cityCode,
                                branches,
                            }),
                        );
                        break;

                    default:
                        break;
                }
            },
            (error) => {
                let errorMessage = config.generalError;

                try {
                    if (error.messages && typeof error.messages === 'string') {
                        errorMessage = error.messages;
                    } else if (error.response.data.message && typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (error.response.data.errors.message && typeof error.response.data.errors.message === 'string') {
                        errorMessage = error.response.data.errors.message;
                    }
                } catch {
                    console.log('error.response: ', error.response);
                    console.log('error.request: ', error.request);
                    console.log('error.config: ', error.config);
                    if (error.response) {
                        errorMessage = 'Fetch ' + type.toLowerCase() + ' branches failed!';
                    }
                }

                console.log('Location fetchPickupBranches error: ' + errorMessage);

                dispatch(locationActions.setError(errorMessage));
            },
        );
    };
};

export const fetchReceivingAgentBranches = (countryCode) => {
    const apiBaseUrl = config.REACT_APP_IDENTITY_BASE_URL + config.REACT_APP_RECEIVING_AGENT_BRANCHES_LISTINGS_URI;

    const data = {
        userId: config.REACT_APP_RECEIVING_AGENT_USERID,
        companyID: config.REACT_APP_RECEIVING_AGENT_COMPANY_ID,
        countryCode,
    };

    const configs = {
        params: {
            tenant: config.REACT_APP_TENANT,
        },
    };

    const request = http.post(apiBaseUrl, data, configs);

    return (dispatch) => {
        dispatch(
            locationActions.setLoading({
                isLoading: true,
                willClearError: true,
            }),
        );

        return request.then(
            ({ data }) => {
                dispatch(
                    locationActions.setReceivingAgentBranches({
                        countryCode,
                        branches: data.branch_List,
                    }),
                );
            },
            (error) => {
                let errorMessage = config.generalError;

                try {
                    if (error.messages && typeof error.messages === 'string') {
                        errorMessage = error.messages;
                    } else if (error.response.data.message && typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (error.response.data.errors.message && typeof error.response.data.errors.message === 'string') {
                        errorMessage = error.response.data.errors.message;
                    }
                } catch {
                    console.log('error.response: ', error.response);
                    console.log('error.request: ', error.request);
                    console.log('error.config: ', error.config);
                    if (error.response) {
                        errorMessage = 'Fetch agent branches failed!';
                    }
                }

                console.log('Location fetchAgentBranches error: ' + errorMessage);

                dispatch(locationActions.setError(errorMessage));
            },
        );
    };
};

export const fetchPayoutChannels = (countryCode, businessCode) => {
    const apiBaseUrl = config.REACT_APP_IDENTITY_BASE_URL + config.REACT_APP_PAYOUT_CHANNEL_LISTINGS_URI;

    let params = {};
    if (businessCode) {
        params.businessCode = businessCode;
    }
    if (countryCode) {
        params.countryCode = countryCode;
    }

    const request = http.get(apiBaseUrl, { params });

    return (dispatch) => {
        dispatch(
            locationActions.setLoading({
                isLoading: true,
                willClearError: true,
            }),
        );

        return request.then(
            ({ data }) => {
                const payoutChannels = new Jsona().deserialize(data);

                dispatch(
                    locationActions.setPayoutChannels({
                        countryCode,
                        payoutChannels:
                            payoutChannels?.sort((a, b) =>
                                a.bankName?.toLowerCase() < b.bankName?.toLowerCase()
                                    ? -1
                                    : b.bankName?.toLowerCase() < a.bankName?.toLowerCase()
                                    ? 1
                                    : 0,
                            ) ?? [],
                    }),
                );
            },
            (error) => {
                let errorMessage = config.generalError;

                try {
                    if (error.messages && typeof error.messages === 'string') {
                        errorMessage = error.messages;
                    } else if (error.response.data.message && typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (error.response.data.errors.message && typeof error.response.data.errors.message === 'string') {
                        errorMessage = error.response.data.errors.message;
                    }
                } catch {
                    console.log('error.response: ', error.response);
                    console.log('error.request: ', error.request);
                    console.log('error.config: ', error.config);
                    if (error.response) {
                        errorMessage = 'Fetch payout channels failed!';
                    }
                }

                console.log('Location fetchPayoutChannels error: ' + errorMessage);

                dispatch(locationActions.setError(errorMessage));
            },
        );
    };
};

export const resetRecipientData = () => {
    return (dispatch) => {
        dispatch(locationActions.clearRecipientData());
    };
};
