import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentCountryCode: '',
  authProcessorStarted: false,
  authProcessorTransactionConfirmStarted: false,
  transactionConfirmed: false,
  authProcessorCancelled: false,
  authProcessorFireCheck: false,
  authProcessorAuthCode: '',
  authProcessorRequest: {},
  authProcessorRedirectUrl: '',
  registrationRedirectDetails: {},
  authProcessorLoading: false,
  authProcessorError: false,
  authProcessorErrorMessage: '',
};

const authProcessorSlice = createSlice({
  name: 'authProcessor',
  initialState,
  reducers: {
    setAuthProcessorLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.authProcessorError = false;
        state.authProcessorErrorMessage = '';
      }
      state.authProcessorLoading = isLoading;
    },
    setAuthProcessorCurrentCountryCode(state, action) {
      state.currentCountryCode = action.payload;
    },
    setAuthProcessorError(state, action) {
      state.authProcessorLoading = false;
      state.authProcessorError = true;
      state.authProcessorErrorMessage = action.payload;
    },
    clearAuthProcessorError(state, action) {
      state.authProcessorError = false;
      state.authProcessorErrorMessage = '';
    },
    setAuthProcessorStarted(state, action) {
      const {
        authProcessorRedirectUrl,
        // ssn,
        // sessionId,
        // externalReference,
        // isLoginOnly,
      } = action.payload;
      localStorage.setItem(
        'authProcessor',
        JSON.stringify({ ...action.payload })
      );
      state.authProcessorStarted = true;
      state.authProcessorFireCheck = true;
      state.authProcessorRequest = action.payload;
      state.authProcessorRedirectUrl = authProcessorRedirectUrl || '';
    },
    clearAuthProcessorStarted(state, action) {
      state.authProcessorStarted = false;
    },
    setAuthProcessorTransactionConfirmationStarted(state, action) {
      const {
        authProcessorRedirectUrl,
        // ssn,
        // sessionId,
        // externalReference,
        // isLoginOnly,
      } = action.payload;
      localStorage.setItem(
        'authProcessor',
        JSON.stringify({ ...action.payload })
      );
      state.authProcessorTransactionConfirmStarted = true;
      state.authProcessorRequest = action.payload;
      state.authProcessorRedirectUrl = authProcessorRedirectUrl || '';
    },
    clearAuthProcessorTransactionConfirmationStarted(state, action) {
      state.authProcessorTransactionConfirmStarted = false;
    },
    setAuthProcessorTransactionConfirmed(state, action) {
      state.transactionConfirmed = true;
      state.authProcessorTransactionConfirmStarted = false;
    },
    setAuthProcessorAuthCode(state, action) {
      state.authProcessorAuthCode = action.payload;
      state.authProcessorStarted = false;
      state.authProcessorFireCheck = false;
    },
    setAuthProcessorCancelled(state, action) {
      state.authProcessorStarted = false;
      state.authProcessorFireCheck = false;
      state.authProcessorCancelled = true;
    },
    setAuthProcessorFireCheck(state, action) {
      state.authProcessorFireCheck = true;
    },
    clearAuthProcessorFireCheck(state, action) {
      state.authProcessorFireCheck = false;
    },
    setRegistrationRedirectDetails(state, action) {
      state.registrationRedirectDetails = action.payload;
    },
    clearRegistrationRedirectDetails(state, action) {
      state.registrationRedirectDetails = {};
    },
    clearAuthProcessor: (state) => initialState,
  },
});

export const authProcessorActions = authProcessorSlice.actions;
export default authProcessorSlice;
