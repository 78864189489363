import { useEffect } from 'react';
import { Route } from 'react-router-dom';

const CommonRoute = (props) => {
  const { title, ...rest } = props;

  useEffect(() => {
    document.title = title;
  }, [title]);

  return <Route {...rest} />;
};

export default CommonRoute;
