import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  result: {},
  receivingCountryCode: null,
  loading: false,
  error: false,
  errorMessage: '',
  quoted: false,
  confirmed: false,
  sendingAmountLimitError: false,
};

const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.error = false;
        state.errorMessage = '';
      }
      state.loading = isLoading;
    },
    setError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    setSendingAmountLimitError(state, action) {
      state.sendingAmountLimitError = true;
    },
    clearSendingAmountLimitError(state) {
      state.sendingAmountLimitError = false;
    },
    setResult(state, action) {
      const { result, receivingCountryCode, confirmed } = action.payload;

      state.result = result;
      state.receivingCountryCode = receivingCountryCode;
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
      state.quoted = true;
      state.confirmed = confirmed;
    },
    confirmResult(state, action) {
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
      state.confirmed = true;
    },
    clearResult(state, action) {
      state.sendingAmountLimitError = false;
      state.result = {};
      state.receivingCountryCode = null;
      state.error = false;
      state.errorMessage = '';
      state.quoted = false;
      state.confirmed = false;
    },
    logout: (state) => initialState,
  },
});

export const quoteActions = quoteSlice.actions;
export default quoteSlice;
