import Jsona from 'jsona';
import _ from 'lodash';
import { isEmpty } from '../../library/utils';

import config from '../../config';
import http from '../api/http';
import { quoteActions } from '../slices/quote-slice';
import { rateActions } from '../slices/rate-slice';
import { setAppNotification } from './app-actions';
import {
  checkSendingLimitAndInitiateTransaction,
  fetchGlobalAmountLimits,
  initiateTransaction,
} from './transaction-actions';

export const requestQuote = (
  quoteData,
  receivingCountryCode,
  isComputedRate,
  confirmed = false,
  resendPayload = {
    transaction: {},
    isOTPBypassed: false,
    history: null,
    rerouteURL: null,
  },
  showFailInNotification,
  userId
) => {
  const apiBaseUrl =
    config.REACT_APP_IDENTITY_BASE_URL + config.REACT_APP_QUOTE;

  const requestData = {
    data: {
      type: 'Quote',
      attributes: {
        ...quoteData,
      },
    },
  };

  const request = http.post(apiBaseUrl, requestData);

  return (dispatch) => {
    if (isComputedRate) {
      dispatch(
        rateActions.setLoadingComputedRate({
          isLoading: true,
          willClearError: true,
        })
      );
    } else {
      dispatch(
        quoteActions.setLoading({
          isLoading: true,
          willClearError: true,
        })
      );
    }

    return request.then(
      ({ data }) => {
        const result = new Jsona().deserialize(data);

        if (isComputedRate) {
          dispatch(
            rateActions.setComputedRate({
              ...result,
              marketFx: result.transactionFx,
              isSending: quoteData.issending,
            })
          );
        } else {
          if (
            config.REACT_APP_SENDING_AMOUNT_LIMITS_ENABLED === 'true' &&
            !quoteData.issending
          ) {
            dispatch(
              checkSendingLimitAndRequestQuote(
                userId,
                quoteData.sendingcurrency,
                result.senderAmount,
                !quoteData.issending,
                () => {
                  dispatch(
                    quoteActions.setResult({
                      result,
                      receivingCountryCode,
                      confirmed,
                    })
                  );
                },
                null,
                null,
                null,
                null,
                null,
                showFailInNotification
              )
            );
          } else {
            dispatch(
              quoteActions.setResult({
                result,
                receivingCountryCode,
                confirmed,
              })
            );
          }

          if (!isEmpty(resendPayload.transaction)) {
            if (config.REACT_APP_SENDING_AMOUNT_LIMITS_ENABLED === 'true') {
              dispatch(
                checkSendingLimitAndInitiateTransaction(
                  userId,
                  result.sendingCurrency?.code,
                  result.senderAmount,
                  {
                    ...resendPayload.transaction,
                    quoteId: result.id,
                  },
                  resendPayload.isOTPBypassed,
                  resendPayload.history,
                  resendPayload.rerouteURL
                )
              );
            } else {
              dispatch(
                initiateTransaction(
                  {
                    ...resendPayload.transaction,
                    quoteId: result.id,
                  },
                  resendPayload.isOTPBypassed,
                  resendPayload.history,
                  resendPayload.rerouteURL,
                  showFailInNotification
                )
              );
            }
          }
        }
      },
      (error) => {
        let errorMessage = config.generalError;

        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            errorMessage = error.response.data.message;
          } else if (
            error.response.data.errors.message &&
            typeof error.response.data.errors.message === 'string'
          ) {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Request quote failed!';
          }
        }

        console.log('Quote requestQuote error: ' + errorMessage);

        if (isComputedRate) {
          dispatch(rateActions.setErrorComputedRate(errorMessage));
        } else {
          dispatch(quoteActions.setError('Fetch quote failed!'));
        }

        if (showFailInNotification) {
          dispatch(
            setAppNotification(
              'Something went wrong. Please contact system admin.',
              'error'
            )
          );
        }
      }
    );
  };
};

export const checkSendingLimitAndRequestQuote = (
  userId,
  sendingCurrency,
  sendAmount,
  isReceiving,
  receivingCallback,
  quoteData,
  receivingCountryCode,
  isComputedRate,
  confirmed = false,
  resendPayload = {
    transaction: {},
    isOTPBypassed: false,
    history: null,
    rerouteURL: null,
  },
  showFailInNotification
) => {
  const apiBaseUrl =
    config.REACT_APP_IDENTITY_BASE_URL + config.REACT_APP_USER_URI + userId;

  const requestConfig = {
    params: {
      include: 'account.amountLimits,account',
    },
  };

  const request = http.get(apiBaseUrl, requestConfig);

  return (dispatch) => {
    dispatch(quoteActions.clearSendingAmountLimitError());
    dispatch(
      quoteActions.setLoading({
        isLoading: true,
        willClearError: true,
      })
    );

    return request.then(
      ({ data }) => {
        let fetchGlobal = false;
        let isAmountLimitError = false;
        let userAmountLimit = null;

        if (config.REACT_APP_SENDING_AMOUNT_LIMITS_ENABLED === 'true') {
          const amountLimitIncludedList = data.included.filter(
            (i) => i.type === 'amountlimit'
          );

          if (amountLimitIncludedList && amountLimitIncludedList.length > 0) {
            let amountLimitRelationship = [];

            if (
              data.data.relationships &&
              data.data.relationships.amountlimit &&
              data.data.relationships.amountlimit.data
            ) {
              amountLimitRelationship = new Jsona().deserialize({
                data: _.isArray(data.data.relationships.amountlimit.data)
                  ? [
                      ...amountLimitIncludedList.filter((al) =>
                        data.data.relationships.amountlimit.data.some(
                          (al2) => al2.data.id === al.id
                        )
                      ),
                    ]
                  : [
                      amountLimitIncludedList.find(
                        (al) =>
                          al.id === data.data.relationships.amountlimit.data.id
                      ),
                    ],
              });
            }

            if (
              sendingCurrency &&
              amountLimitRelationship.some(
                (al) =>
                  al.currencyCode.toUpperCase() ===
                  sendingCurrency.toUpperCase()
              )
            ) {
              const amountLimit = amountLimitRelationship.find(
                (al) =>
                  al.currencyCode.toUpperCase() ===
                  sendingCurrency.toUpperCase()
              );

              if (!amountLimit.isLocked) {
                userAmountLimit = amountLimit;
                fetchGlobal = true;
              } else if (
                amountLimit.isLocked &&
                sendAmount > amountLimit.maximum - amountLimit.used
              ) {
                isAmountLimitError = true;
              }
            } else {
              fetchGlobal = true;
            }
          } else {
            fetchGlobal = true;
          }
        }

        if (fetchGlobal) {
          dispatch(
            fetchGlobalAmountLimits((globalAmountLimits) => {
              if (
                globalAmountLimits.amountlimit.some(
                  (al) =>
                    al.currencyCode.toUpperCase() ===
                    sendingCurrency.toUpperCase()
                )
              ) {
                const globalAmountLimit = globalAmountLimits.amountlimit.find(
                  (al) =>
                    al.currencyCode.toUpperCase() ===
                    sendingCurrency.toUpperCase()
                );

                if (
                  (!isEmpty(userAmountLimit) &&
                    sendAmount >
                      globalAmountLimit.maximum - userAmountLimit.used) ||
                  sendAmount > globalAmountLimit.maximum
                ) {
                  dispatch(quoteActions.setSendingAmountLimitError());
                  dispatch(
                    quoteActions.setLoading({
                      isLoading: false,
                      willClearError: true,
                    })
                  );
                }

                if (!isReceiving) {
                  dispatch(
                    requestQuote(
                      quoteData,
                      receivingCountryCode,
                      isComputedRate,
                      confirmed,
                      resendPayload,
                      showFailInNotification,
                      userId
                    )
                  );
                } else {
                  receivingCallback();
                }
              }
            }, showFailInNotification)
          );
        } else {
          if (isAmountLimitError) {
            dispatch(quoteActions.setSendingAmountLimitError());
            dispatch(
              quoteActions.setLoading({
                isLoading: false,
                willClearError: true,
              })
            );
          }

          if (!isReceiving) {
            dispatch(
              requestQuote(
                quoteData,
                receivingCountryCode,
                isComputedRate,
                confirmed,
                resendPayload,
                showFailInNotification,
                userId
              )
            );
          } else {
            receivingCallback();
          }
        }
      },
      (error) => {
        let errorMessage = config.generalError;

        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            errorMessage = error.response.data.message;
          } else if (
            error.response.data.errors.message &&
            typeof error.response.data.errors.message === 'string'
          ) {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Get user data failed!';
          }
        }

        console.log(
          'Quote checkSendingLimitAndRequestQuote error: ' + errorMessage
        );

        dispatch(quoteActions.setError('Get user data failed!'));

        if (showFailInNotification) {
          dispatch(
            setAppNotification(
              'Something went wrong. Please contact system admin.',
              'error'
            )
          );
        }
      }
    );
  };
};

export const confirmResult = (quotationId) => {
  const apiBaseUrl =
    config.REACT_APP_IDENTITY_BASE_URL +
    config.REACT_APP_QUOTE +
    '/' +
    quotationId;

  const request = http.patch(apiBaseUrl);

  return (dispatch) => {
    dispatch(
      quoteActions.setLoading({
        isLoading: true,
        willClearError: true,
      })
    );

    return request.then(
      ({ data }) => {
        dispatch(quoteActions.confirmResult());
      },
      (error) => {
        let errorMessage = config.generalError;

        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            errorMessage = error.response.data.message;
          } else if (
            error.response.data.errors.message &&
            typeof error.response.data.errors.message === 'string'
          ) {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Confirm quote failed!';
          }
        }

        console.log('Quote confirmResult error: ' + errorMessage);

        dispatch(quoteActions.setError('Confirm quote failed!'));
      }
    );
  };
};

export const setQuoteResult = (quoteResult) => {
  return (dispatch) => {
    dispatch(quoteActions.setResult(quoteResult));
  };
};

export const resetQuoteSendingAmountLimitError = () => {
  return (dispatch) => {
    dispatch(quoteActions.clearSendingAmountLimitError());
  };
};

export const resetQuoteResult = () => {
  return (dispatch) => {
    dispatch(quoteActions.clearResult());
  };
};
