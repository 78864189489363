import { lazy } from 'react';
import config from '../config';

const ConfirmRemit = lazy(() => import('../containers/SendMoney/Confirm/ConfirmRemit'));
const Documents = lazy(() => import('../containers/Account/Documents/Documents'));
const Otp = lazy(() => import('../containers/SendMoney/Otp/Otp'));
const Payment = lazy(() => import('../containers/SendMoney/Payment/Payment'));
const PaymentLink = lazy(() => import('../containers/Payment/PaymentLink/PaymentLink'));
const PaymentReturn = lazy(() => import('../containers/Payment/PaymentReturn/PaymentReturn'));
const Pin = lazy(() => import('../containers/SendMoney/Pin/Pin'));
const Profile = lazy(() => import('../containers/Account/Profile/Profile'));
const Questionnaire = lazy(() => import('../containers/Account/Questionnaire/Questionnaire'));
const RecipientList = lazy(() => import('../containers/Account/Recipient/List/RecipienList'));
const SelectRecipient = lazy(() => import('../containers/SendMoney/Recipient/Select/SelectRecipient'));
const SelectPaymentMethod = lazy(() => import('../containers/SendMoney/Payment/SelectPaymentMethod/SelectPaymentMethod'));
const SendMoney = lazy(() => import('../containers/SendMoney/SendMoney'));
const SetPin = lazy(() => import('../containers/Account/SetPin/SetPin'));
const Settings = lazy(() => import('../containers/Account/Settings/Settings'));
const Transactions = lazy(() => import('../containers/Account/Transactions/Transactions'));
const TransactionDocuments = lazy(() => import('../containers/Account/Transactions/TransactionDocuments/TransactionDocuments'));
const UpsertRecipient = lazy(() => import('../containers/SendMoney/Recipient/Upsert/UpsertRecipient'));
const UpsertAccountRecipient = lazy(() => import('../containers/Account/Recipient/Upsert/UpsertRecipient'));

const defaultTitle = 'Send And Receive Money With ' + config.REACT_APP_TENANT_SHORT_NAME;

const routes = [
    {
        path: '/settings/pin',
        exact: true,
        name: 'Set PIN',
        title: 'Set PIN - ' + defaultTitle,
        component: SetPin,
    },
    {
        path: '/account/settings',
        exact: true,
        name: 'Settings',
        title: 'Settings - ' + defaultTitle,
        component: Settings,
    },
    {
        path: '/account/profile',
        exact: true,
        name: 'Profile',
        title: 'Profile - ' + defaultTitle,
        component: Profile,
    },
    {
        path: '/account/documents',
        exact: true,
        name: 'Account Documents',
        title: 'KYC Documents - ' + defaultTitle,
        component: Documents,
    },
    {
        path: '/account/questionnaire',
        exact: true,
        name: 'Account Questionnaire',
        title: 'KYC Questionnaire - ' + defaultTitle,
        component: Questionnaire,
    },
    {
        path: '/account/recipients',
        exact: true,
        name: 'Recipients',
        title: 'Recipients - ' + defaultTitle,
        component: RecipientList,
    },
    {
        path: '/account/recipients/:mode',
        exact: true,
        name: 'Add/Update Recipient',
        title: 'Add/Update Recipient - ' + defaultTitle,
        component: UpsertAccountRecipient,
    },
    {
        path: '/account/transactions',
        exact: true,
        name: 'Transactions',
        title: 'Transactions - ' + defaultTitle,
        component: Transactions,
        componentProps: {
            title: 'Transactions',
        },
    },
    {
        path: '/account/transactions/:transactionId/documents',
        exact: true,
        name: 'Transaction Documents',
        title: 'Transaction Documents - ' + defaultTitle,
        component: TransactionDocuments,
        componentProps: {
            title: 'Transaction Documents',
        },
    },
    {
        path: '/remit/quote/:countryCode',
        exact: true,
        name: 'Send Money',
        title: defaultTitle,
        component: SendMoney,
    },
    {
        path: '/remit/quote',
        exact: true,
        name: 'Send Money',
        title: defaultTitle,
        component: SendMoney,
    },
    {
        path: '/remit/recipient/add',
        exact: true,
        name: 'Add Recipient',
        title: 'Add Recipient - ' + defaultTitle,
        component: UpsertRecipient,
    },
    // {
    //   path: '/remit/recipient/update',
    //   exact: true,
    //   name: 'Update Recipient',
    //   component: UpsertRecipient,
    // },
    {
        path: '/remit/recipient',
        exact: true,
        name: 'Select Recipient',
        title: 'Select Recipient - ' + defaultTitle,
        component: SelectRecipient,
    },
    {
        path: '/remit/paymentmethod',
        exact: true,
        name: 'Select Payment Method',
        title: 'Select Payment Method - ' + defaultTitle,
        component: SelectPaymentMethod,
    },
    {
        path: '/remit/confirm',
        exact: true,
        name: 'Confirm Remittance',
        title: 'Confirm Remittance - ' + defaultTitle,
        component: ConfirmRemit,
    },
    {
        path: '/remit/otp',
        exact: true,
        name: 'Verify OTP',
        title: 'Verify OTP - ' + defaultTitle,
        component: Otp,
    },
    {
        path: '/remit/pin',
        exact: true,
        name: 'Confirm with PIN',
        title: 'Confirm with PIN - ' + defaultTitle,
        component: Pin,
    },
    {
        path: '/remit/payment',
        exact: true,
        name: 'Payment',
        title: 'Payment - ' + defaultTitle,
        component: Payment,
    },
    {
        path: '/remit/paymentlink/:transactionId',
        exact: true,
        name: 'Payment Link',
        title: 'Payment Link - ' + defaultTitle,
        component: PaymentLink,
    },
    {
        path: '/payment/:psp/:status/:ref',
        exact: true,
        name: 'Payment Verification',
        title: 'Payment Verification - ' + defaultTitle,
        component: PaymentReturn,
    },
    {
        path: '/payment/:psp/:status',
        exact: true,
        name: 'Payment Verification',
        title: 'Payment Verification - ' + defaultTitle,
        component: PaymentReturn,
    },
];

export default routes;
