import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clientIpAddress: '',
  transactionsInCompliancePending: false,
  transactionsInComplianceHold: false,
  isPinRequired: false,
  isOtpRequired: false,
  isMobile: false,
  notificationOpen: false,
  notificationSeverity: 'info',
  notificationMessage: '',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setClientIpAddress(state, action) {
      state.clientIpAddress = action.payload;
    },
    setTransactionsInCompliancePending(state, action) {
      state.transactionsInCompliancePending = action.payload;
      // state.error = false;
      // state.errorMessage = '';
      // state.loading = false;
    },
    setTransactionsInComplianceHold(state, action) {
      state.transactionsInComplianceHold = action.payload;
      // state.error = false;
      // state.errorMessage = '';
      // state.loading = false;
    },
    setIsPinRequired(state, action) {
      state.isPinRequired = action.payload;
    },
    setIsOtpRequired(state, action) {
      state.isOtpRequired = action.payload;
    },
    setIsMobile(state, action) {
      state.isMobile = action.payload;
    },
    setNotification(state, action) {
      const { notificationMessage, notificationSeverity } = action.payload;

      state.notificationOpen = true;
      state.notificationSeverity = notificationSeverity;
      state.notificationMessage = notificationMessage;
    },
    resetNotification(state, action) {
      state.notificationOpen = false;
      state.notificationSeverity = 'info';
      state.notificationMessage = '';
    },
    setError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    clearError(state, action) {
      state.error = action.payload;
      state.errorMessage = '';
    },
    logout: (state) => initialState,
  },
});

export const appActions = appSlice.actions;
export default appSlice;
