import { createSlice } from '@reduxjs/toolkit';
import jwt from 'jwt-decode';
import config from '../../config';
import { isEmpty } from '../../library/utils';
import { validateNationalIdNumberFormat } from '../../library/national-id';

const initialState = {
    isLoggedIn: false,
    kycPending: false,
    kycExpired: false,
    kycQnAPending: false,
    userPending: false,
    userProfilePending: false,
    nationalIdInvalid: false,
    forceLinkAuthProcessor: false,
    jwt: null,
    user: {
        id: undefined,
        fullName: undefined,
        photoUrl: undefined,
    },
    isAccountPinSet: false,
    loading: false,
    error: false,
    errorMessage: '',
    resetPasswordSent: false,
    changePasswordDone: false,
    emailConfirmed: false,
    recaptchaTokenValidated: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoading(state, action) {
            const { isLoading, willClearError } = action.payload;

            if (willClearError) {
                state.error = false;
                state.errorMessage = '';
            }
            state.loading = isLoading;
        },
        setError(state, action) {
            state.loading = false;
            state.error = true;
            state.errorMessage = action.payload;
        },
        clearError(state, action) {
            state.error = action.payload;
            state.errorMessage = '';
        },
        login(state, action) {
            const { userLoginData, userInfoData, decodedJWT: jwtPayload, bypassLoggedIn } = action.payload;
            const decodedJWT = jwtPayload ? jwtPayload : jwt(userLoginData.accessToken);

            state.isLoggedIn = !!!bypassLoggedIn;
            state.jwt = decodedJWT;
            state.user = {
                fullname: userInfoData.Fullname,
                firstname: userInfoData.Firstname,
                middlename: userInfoData.Middlename || '',
                lastname: userInfoData.Lastname,
                gender: userInfoData.Gender,
                birthDate: userInfoData.BirthDate,
                nationality: userInfoData.Nationality,
                id: userInfoData.id,
                account: { ...userInfoData.account },
                amountlimit: userInfoData.amountlimit ? [...userInfoData.amountlimit] : null,
                photo: { ...userInfoData.photo },
                document: userInfoData.document,
                phoneNumber: userInfoData.phonenumber?.value,
                email: userInfoData.email?.value,
                addressId: userInfoData.address?.id,
                address1: userInfoData.address?.address1,
                address2: userInfoData.address?.address2,
                city: userInfoData.address?.city,
                country: userInfoData.address?.country,
                zipCode: userInfoData.address?.zipCode,
                state: userInfoData.address?.state,
            };
            state.userProfilePending = !(
                (
                    userInfoData.Firstname &&
                    userInfoData.Lastname &&
                    userInfoData.Gender &&
                    userInfoData.BirthDate &&
                    userInfoData.Nationality &&
                    // userInfoData.phonenumber?.value &&
                    // userInfoData.email?.value &&
                    userInfoData.address?.address1 &&
                    userInfoData.address?.city &&
                    // userInfoData.address?.country &&
                    userInfoData.address?.zipCode
                )
                // &&
                // (userInfoData.address?.state ||
                //   config.REACT_APP_SENDER_STATE_VISIBILITY === 'HIDE')
            );
            state.userPending = isEmpty(userInfoData.account) || !userInfoData.account.status || userInfoData.account.status !== 8;
            state.kycPending =
                isEmpty(userInfoData.document) ||
                userInfoData.document.length === 0 ||
                isEmpty(userInfoData.account) ||
                (!isEmpty(userInfoData.account) && (userInfoData.account.status === 256 || userInfoData.account.showClearKYCReject)) ||
                !userInfoData.document.some((d) => d.expiryDate && new Date(d.expiryDate?.trim().replace(/-/g, '/')) > new Date());
            state.kycExpired =
                !isEmpty(userInfoData.document) &&
                !userInfoData.document.some((d) => d.expiryDate && new Date(d.expiryDate?.trim().replace(/-/g, '/')) > new Date());
            state.kycQnAPending =
                config.REACT_APP_KYC_QNA_ENABLED === 'true' &&
                (!!!userInfoData.kycqna ||
                    userInfoData.kycqna.length === 0 ||
                    !!!userInfoData.KycQnaExpiry ||
                    new Date() > new Date(userInfoData.KycQnaExpiry));
            const ssn = userInfoData.document
                ?.filter((d) => d.type === 'nationalidcard')
                ?.sort((a, b) =>
                    new Date(a.expiryDate?.trim().replace(/-/g, '/')).getTime() <
                    new Date(b.expiryDate?.trim().replace(/-/g, '/')).getTime()
                        ? 1
                        : new Date(b.expiryDate?.trim().replace(/-/g, '/')).getTime() <
                          new Date(a.expiryDate?.trim().replace(/-/g, '/')).getTime()
                        ? -1
                        : 0,
                )[0]?.number;
            if (
                config.REACT_APP_FORCE_CHECK_VALID_IDENTITY_DOCUMENT === 'true' &&
                config.REACT_APP_AUTH_PROCESSOR_COUNTRIES &&
                config.REACT_APP_AUTH_PROCESSOR_COUNTRIES.split(',').includes(userInfoData.address?.country?.toUpperCase()) // &&
                // !!userInfoData.account?.isBankIdRegistered &&
                // userInfoData.account?.bankIdProviders
                //   ?.map((bp) => bp.toLowerCase())
                //   ?.includes(config.REACT_APP_AUTH_PROCESSOR?.toLowerCase())
            ) {
                if (!!ssn) {
                    state.nationalIdInvalid = !validateNationalIdNumberFormat(ssn, userInfoData.address?.country);
                } else {
                    state.nationalIdInvalid = true;
                }
                // } else if (
                //   !!userInfoData.account?.isBankIdRegistered &&
                //   userInfoData.account?.bankIdProviders
                //     ?.map((bp) => bp.toLowerCase())
                //     ?.includes(config.REACT_APP_AUTH_PROCESSOR?.toLowerCase())
                // ) {
                //   state.nationalIdInvalid = true;
                // }
            }

            if (
                config.REACT_APP_FORCE_LINK_AUTH_PROCESSOR === 'true' &&
                config.REACT_APP_AUTH_PROCESSOR_COUNTRIES &&
                config.REACT_APP_AUTH_PROCESSOR_COUNTRIES.split(',').includes(userInfoData.address?.country?.toUpperCase()) &&
                decodedJWT['require-id-provider'] &&
                decodedJWT['require-id-provider']
                    .split(',')
                    .map((c) => c?.toUpperCase())
                    .includes(userInfoData.address?.country?.toUpperCase()) &&
                !!!userInfoData.account?.isBankIdRegistered
            ) {
                state.forceLinkAuthProcessor = true;
            } else {
                state.forceLinkAuthProcessor = false;
            }

            state.isAccountPinSet = decodedJWT['client_account-has-pin'] === 'true';

            state.error = false;
            state.errorMessage = '';
            state.loading = false;
        },
        setConfirmedEmail(state) {
            state.emailConfirmed = true;
            state.error = false;
            state.errorMessage = '';
            state.loading = false;
        },
        clearConfirmedEmail(state) {
            state.emailConfirmed = false;
        },
        setResetPasswordSent(state, action) {
            state.resetPasswordSent = true;
            state.error = false;
            state.errorMessage = '';
            state.loading = false;
        },
        clearResetPasswordSent(state, action) {
            state.resetPasswordSent = false;
        },
        setChangePasswordDone(state, action) {
            state.changePasswordDone = true;
            state.error = false;
            state.errorMessage = '';
            state.loading = false;
        },
        clearChangePasswordDone(state, action) {
            state.changePasswordDone = false;
        },
        setRecaptchaTokenValidated(state, action) {
            state.recaptchaTokenValidated = true;
        },
        clearRecaptchaTokenValidated(state, action) {
            state.recaptchaTokenValidated = false;
        },
        logout: (state) => initialState,
    },
});

export const authActions = authSlice.actions;
export default authSlice;
