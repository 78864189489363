import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const AuthenticatedRoute = (props) => {
  const { title, component: C, props: childProps, path, ...rest } = props;
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const nationalIdInvalid = useSelector(
    (state) => state.auth.nationalIdInvalid
  );
  const forceLinkAuthProcessor = useSelector(
    (state) => state.auth.forceLinkAuthProcessor
  );

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isLoggedIn ? (
          nationalIdInvalid && path !== '/account/documents' ? (
            <Redirect to="/account/documents" />
          ) : forceLinkAuthProcessor && path !== '/account/settings' ? (
            <Redirect to="/account/settings" />
          ) : (
            <C {...routeProps} {...childProps} />
          )
        ) : (
          <Redirect
            to={`/login?redirect=${routeProps.location.pathname}${routeProps.location.search}`}
          />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
