import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Badge, Button, IconButton, ListItemIcon, ListItemText, Toolbar, useTheme } from '@mui/material';
import { AccountBox, AccountCircle, Description, ExitToApp, Menu, People, Quiz, Receipt, Settings } from '@mui/icons-material';
import { StyledEngineProvider } from '@mui/material/styles';

import topNavBarStyles from './topNavBarStyles';
import { HeaderLogo, MobileHeaderLogo } from '../../../assets/assetSelector';
import StyledMenu from '../../../components/StyledMenu';
import StyledMenuItem from '../../../components/StyledMenuItem';
import { authLogout } from '../../../store/actions/auth-actions';
import config from '../../../config';

const TopNavBar = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const styles = topNavBarStyles();
    const theme = useTheme();

    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const [anchorElMain, setAnchorElMain] = useState(null);
    const [anchorElProfile, setAnchorElProfile] = useState(null);
    const isMainMenuOpen = Boolean(anchorElMain);
    const isProfileMenuOpen = Boolean(anchorElProfile);

    const user = useSelector((state) => state.auth.user);
    const userProfilePending = useSelector((state) => state.auth.userProfilePending);
    const isPinRequired = useSelector((state) => state.app.isPinRequired);
    const isAccountPinSet = useSelector((state) => state.auth.isAccountPinSet);
    const kycPending = useSelector((state) => state.auth.kycPending);
    const kycQnAPending = useSelector((state) => state.auth.kycQnAPending);
    const nationalIdInvalid = useSelector((state) => state.auth.nationalIdInvalid);
    const transactionsInCompliancePending = useSelector((state) => state.app.transactionsInCompliancePending);
    const isBankIdRegistered = useSelector((state) => state.auth.user?.account?.isBankIdRegistered);

    const processors = config.REACT_APP_AUTH_PROCESSOR
        ? config.REACT_APP_AUTH_PROCESSOR.split(',').map((ps) => {
              const stringSplit = ps.split('|');
              return {
                  processor: stringSplit[0],
                  countries: stringSplit[1].split('/'),
              };
          })
        : [];
    const processor = processors.find((p) => p.countries.includes(user.country?.toUpperCase()))?.processor;

    const handleLogout = () => {
        handleProfileMenuClose();
        window.location.replace('/');
        dispatch(authLogout());
    };

    const handleMainMenuOpen = (event) => {
        setAnchorElMain(event.currentTarget);
    };

    const handleMainMenuClose = () => {
        setAnchorElMain(null);
    };

    const handleProfileMenuOpen = (event) => {
        setAnchorElProfile(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorElProfile(null);
    };

    const renderMainMenu = (
        <StyledMenu id="menu-desktop" anchorEl={anchorElMain} keepMounted open={isMainMenuOpen} onClose={handleMainMenuClose}>
            <StyledMenuItem component={Link} to="/account/recipients">
                <ListItemIcon>
                    <People fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Recipients" />
            </StyledMenuItem>
            <StyledMenuItem component={Link} to="/account/transactions">
                <ListItemIcon>
                    <Receipt fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Transactions" />
            </StyledMenuItem>
        </StyledMenu>
    );

    const renderProfileMenu = (
        <StyledMenu id="menu-desktop" anchorEl={anchorElProfile} keepMounted open={isProfileMenuOpen} onClose={handleProfileMenuClose}>
            <StyledMenuItem component={Link} to="/account/profile">
                <ListItemIcon>
                    <Badge color="danger" variant="dot" invisible={!userProfilePending}>
                        <AccountBox fontSize="small" />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary="Profile" />
            </StyledMenuItem>
            <StyledMenuItem component={Link} to="/account/documents">
                <ListItemIcon>
                    <Badge color="danger" variant="dot" invisible={!kycPending && !nationalIdInvalid}>
                        <Description fontSize="small" />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary="Documents" />
            </StyledMenuItem>
            {kycQnAPending && (
                <StyledMenuItem component={Link} to="/account/questionnaire">
                    <ListItemIcon>
                        <Badge color="danger" variant="dot" invisible={!kycQnAPending}>
                            <Quiz fontSize="small" />
                        </Badge>
                    </ListItemIcon>
                    <ListItemText primary="Questionnaire" />
                </StyledMenuItem>
            )}
            {transactionsInCompliancePending && (
                <StyledMenuItem component={Link} to="/account/transactions">
                    <ListItemIcon>
                        <Badge color="danger" variant="dot" invisible={!transactionsInCompliancePending}>
                            <Description fontSize="small" />
                        </Badge>
                    </ListItemIcon>
                    <ListItemText primary="Transactions" />
                </StyledMenuItem>
            )}
            {((!!processor &&
                !isBankIdRegistered &&
                !user.account?.bankIdProviders?.map((bp) => bp.toLowerCase())?.includes(processor?.toLowerCase()) &&
                config.REACT_APP_AUTH_PROCESSOR_COUNTRIES &&
                config.REACT_APP_AUTH_PROCESSOR_COUNTRIES.split(',').includes(user.country?.toUpperCase())) ||
                isPinRequired) && (
                <StyledMenuItem component={Link} to="/account/settings">
                    <ListItemIcon>
                        <Badge color="danger" variant="dot" invisible={!isPinRequired || (isPinRequired && isAccountPinSet)}>
                            <Settings fontSize="small" />
                        </Badge>
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                </StyledMenuItem>
            )}
            <StyledMenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <ExitToApp fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </StyledMenuItem>
        </StyledMenu>
    );

    const menuItems = (isMobile, position) => {
        let itemClassName = 'menuItem';
        let itemLinkClassName = 'menuItemLink';
        let itemLinkClassNameLikeWordpress = 'menuItemLinkLikeWordpress';

        if (isMobile) {
            itemClassName += 'Mobile';
            itemLinkClassName += 'Mobile';
        }

        switch (position) {
            case 'left':
                return isLoggedIn ? (
                    <>
                        {!isMobile && [3].includes(theme.config.themeNumber) && (
                            <>
                                <Link to="/account/recipients" className={styles[itemLinkClassName]}>
                                    <Button className={styles[itemClassName]}>Recipients</Button>
                                </Link>
                                <Link to="/account/transactions" className={styles[itemLinkClassName]}>
                                    <Button className={styles[itemClassName]}>Transactions</Button>
                                </Link>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {config.REACT_APP_REMIT_PAGE_MODE === 'PUBLIC' && (
                            <Link to="/" className={styles[itemLinkClassName]}>
                                <Button className={styles[itemClassName]}>Check Rates</Button>
                            </Link>
                        )}
                    </>
                );

            case 'right':
            default:
                return isLoggedIn ? (
                    <>
                        {[1, 2].includes(theme.config.themeNumber) && (
                            <Link to="/remit" className={styles[itemLinkClassName]}>
                                <Button className={styles[itemClassName]}>Send Now</Button>
                            </Link>
                        )}
                        {isMobile && (
                            <>
                                <IconButton
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls="menu-desktop"
                                    aria-haspopup="true"
                                    onClick={handleMainMenuOpen}
                                    className={styles[itemLinkClassName]}
                                >
                                    <Menu size="small" color={theme.config.topNavBarIconMenusColorPalette} />
                                </IconButton>
                            </>
                        )}
                        {!isMobile && [1, 2].includes(theme.config.themeNumber) && (
                            <>
                                <Link to="/account/recipients" className={styles[itemLinkClassName]}>
                                    <Button className={styles[itemClassName]}>Recipients</Button>
                                </Link>
                                <Link to="/account/transactions" className={styles[itemLinkClassName]}>
                                    <Button className={styles[itemClassName]}>Transactions</Button>
                                </Link>
                            </>
                        )}
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls="menu-desktop"
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            className={styles[itemLinkClassName]}
                        >
                            <Badge
                                color="danger"
                                variant="dot"
                                invisible={
                                    !kycPending &&
                                    !kycQnAPending &&
                                    !userProfilePending &&
                                    !nationalIdInvalid &&
                                    !transactionsInCompliancePending &&
                                    (!isPinRequired || (isPinRequired && isAccountPinSet))
                                }
                            >
                                <AccountCircle size="small" color={theme.config.topNavBarIconMenusColorPalette} />
                            </Badge>
                        </IconButton>
                    </>
                ) : (
                    <>
                        {config.REACT_APP_REMIT_PAGE_MODE === 'PUBLIC' && (
                            <Link to="/" className={styles[itemLinkClassName]}>
                                <Button className={styles[itemClassName]}>Check Rates</Button>
                            </Link>
                        )}
                        {[1].includes(theme.config.themeNumber) && !isMobile && (
                            <>
                                <Button href={config.REACT_APP_TENANT_WEBSITE} className={styles[itemLinkClassNameLikeWordpress]}>
                                    HOME
                                </Button>
                                <Button href={config.REACT_APP_FOOTER_HOW_IT_WORKS} className={styles[itemLinkClassNameLikeWordpress]}>
                                    HOW IT WORKS
                                </Button>
                                <Button href={config.REACT_APP_FOOTER_WHO_WE_ARE} className={styles[itemLinkClassNameLikeWordpress]}>
                                    WHO WE ARE
                                </Button>
                                <Button href={config.REACT_APP_FOOTER_DOWNLOAD} className={styles[itemLinkClassNameLikeWordpress]}>
                                    DOWNLOAD
                                </Button>
                                <Button href={config.REACT_APP_FOOTER_CONTACT_US} className={styles[itemLinkClassNameLikeWordpress]}>
                                    CONTACT US
                                </Button>
                            </>
                        )}
                        {theme.config.themeNumber === 2 && (
                            <Link to="/login" className={styles[itemLinkClassName]}>
                                <Button className={styles[itemClassName]}>Send Now</Button>
                            </Link>
                        )}
                        <Link
                            to={location?.pathname === '/register' ? '/login' : '/register'}
                            className={styles[[1].includes(theme.config.themeNumber) ? itemLinkClassNameLikeWordpress : itemLinkClassName]}
                        >
                            <Button
                                variant={theme.config.topNavBarRegisterButtonVariant}
                                color={theme.config.topNavBarRegisterButtonColor}
                                className={styles[itemClassName + 'Register']}
                                size={theme.config.topNavBarRegisterButtonSize}
                            >
                                {location?.pathname === '/register' ? 'Login' : 'Register'}
                            </Button>
                        </Link>
                    </>
                );
        }
    };

    const desktopLeftMenu = <div className={styles.sectionDesktop}>{menuItems(false, 'left')}</div>;

    const desktopMenu = <div className={styles.sectionDesktop}>{menuItems(false, 'right')}</div>;

    const mobileMenu = <div className={styles.sectionMobile}>{menuItems(true)}</div>;

    return (
        <StyledEngineProvider injectFirst>
            <AppBar enableColorOnDark position={theme.config.isTopNavbarFixed ? 'fixed' : 'relative'} className={styles.root}>
                <Toolbar>
                    <Link to="/">
                        <div className={styles.sectionDesktop}>
                            <img src={HeaderLogo()} alt="home" height={theme.config.topNavBarLogoHeight} />
                        </div>
                        <div className={styles.sectionMobile}>
                            <img src={MobileHeaderLogo()} alt="home" height="50" />
                        </div>
                    </Link>
                    {desktopLeftMenu}
                    <div className={styles.grow} />
                    {desktopMenu}
                    {mobileMenu}
                </Toolbar>
            </AppBar>
            {renderMainMenu}
            {renderProfileMenu}
        </StyledEngineProvider>
    );
};

export default TopNavBar;
