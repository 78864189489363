import { useEffect, useRef } from 'react';

export function isEmpty(obj) {
    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== 'object') return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
}

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export const emailRegex = /^[a-z0-9]((\.|\+|_)?[a-z0-9]){5,}@([\w-]+\.)+[\w-]{2,4}$/;

export function generateAlphaNumeric(length) {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function normalizeString(string) {
    try {
        return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    } catch {
        return string;
    }
}

export function channelIdToEnum(channelId) {
    const tempChannelId = channelId?.toUpperCase();
    switch (tempChannelId) {
        case '2':
            return 'cash';
        case '8':
            return 'bank';
        case '32':
            return 'wallet';
        case '128':
            return 'pwallet';
        case '256':
            return 'payments';
        default:
            return 'none';
    }
}

export const isLeap = (year) => new Date(year, 1, 29).getDate() === 29;

export function onlyLatinCharacters(str) {
    return /^[a-z0-9.\-'\s]+$/i.test(str);
}
