export const validateNationalIdNumberFormat = (
  nationalIdNumber = '',
  countryCode = '',
  checkAllPossible = false
) => {
  if (checkAllPossible) {
    const tempPossibleCountryCodes = [];

    if (validateDenmarkNationalIdNumberFormat(nationalIdNumber)) {
      tempPossibleCountryCodes.push('DK');
    }
    if (validateFinlandNationalIdNumberFormat(nationalIdNumber)) {
      tempPossibleCountryCodes.push('FI');
    }
    // if (validateNorwayNationalIdNumberFormat(nationalIdNumber)) {
    //   tempPossibleCountryCodes.push('NO');
    // }
    if (validateSwedenNationalIdNumberFormat(nationalIdNumber)) {
      tempPossibleCountryCodes.push('SE');
    }

    return tempPossibleCountryCodes;
  }

  switch (countryCode) {
    case 'DK':
      return validateDenmarkNationalIdNumberFormat(nationalIdNumber);
    case 'FI':
      return validateFinlandNationalIdNumberFormat(nationalIdNumber);
    case 'NO':
      return validateNorwayNationalIdNumberFormat(nationalIdNumber);
    case 'SE':
      return validateSwedenNationalIdNumberFormat(nationalIdNumber);
    default:
      return false;
  }
};

export const validateDenmarkNationalIdNumberFormat = (
  nationalIdNumber = ''
) => {
  const tempIdentityNumber = nationalIdNumber?.toUpperCase();
  const tempLength = tempIdentityNumber?.length;

  if (tempLength === 10) {
    const birthDateFirstTwo = tempIdentityNumber.slice(0, 2);
    const birthDateSecondTwo = tempIdentityNumber.slice(2, 4);
    const birthDateThirdTwo = tempIdentityNumber.slice(4, 6);
    const sequence_dk = tempIdentityNumber.slice(6, 10);

    if (
      /^\d+$/.test(birthDateFirstTwo) &&
      parseInt(birthDateFirstTwo) > 0 &&
      parseInt(birthDateFirstTwo) < 32 &&
      /^\d+$/.test(birthDateSecondTwo) &&
      parseInt(birthDateSecondTwo) > 0 &&
      parseInt(birthDateSecondTwo) < 13 &&
      /^\d+$/.test(birthDateThirdTwo) &&
      parseInt(birthDateThirdTwo) >= 0 &&
      parseInt(birthDateThirdTwo) < 100 &&
      /^\d+$/.test(sequence_dk) &&
      parseInt(sequence_dk) >= 0 &&
      parseInt(sequence_dk) < 10000
    ) {
      return true;
    }
  } else if (tempLength === 11) {
    const birthDateFirstTwo = tempIdentityNumber.slice(0, 2);
    const birthDateSecondTwo = tempIdentityNumber.slice(2, 4);
    const birthDateThirdTwo = tempIdentityNumber.slice(4, 6);
    const century = tempIdentityNumber.slice(6, 7);
    const sequence_se_dk = tempIdentityNumber.slice(7, 11);

    if (
      /^\d+$/.test(birthDateFirstTwo) &&
      parseInt(birthDateFirstTwo) > 0 &&
      parseInt(birthDateFirstTwo) < 32 &&
      /^\d+$/.test(birthDateSecondTwo) &&
      parseInt(birthDateSecondTwo) > 0 &&
      parseInt(birthDateSecondTwo) < 13 &&
      /^\d+$/.test(birthDateThirdTwo) &&
      parseInt(birthDateThirdTwo) >= 0 &&
      parseInt(birthDateThirdTwo) < 100 &&
      century === '-' && // - doesnt mean a thing
      /^\d+$/.test(sequence_se_dk)
    ) {
      return true;
    }
  }
};

export const validateFinlandNationalIdNumberFormat = (
  nationalIdNumber = ''
) => {
  const tempIdentityNumber = nationalIdNumber?.toUpperCase();
  const tempLength = tempIdentityNumber?.length;

  if (tempLength === 11) {
    const birthDateFirstTwo = tempIdentityNumber.slice(0, 2);
    const birthDateSecondTwo = tempIdentityNumber.slice(2, 4);
    const birthDateThirdTwo = tempIdentityNumber.slice(4, 6);
    const century = tempIdentityNumber.slice(6, 7);
    const sequence_fi = tempIdentityNumber.slice(7, 10);
    const control = tempIdentityNumber.slice(10, 11);

    if (
      /^\d+$/.test(birthDateFirstTwo) &&
      parseInt(birthDateFirstTwo) > 0 &&
      parseInt(birthDateFirstTwo) < 32 &&
      /^\d+$/.test(birthDateSecondTwo) &&
      parseInt(birthDateSecondTwo) > 0 &&
      parseInt(birthDateSecondTwo) < 13 &&
      /^\d+$/.test(birthDateThirdTwo) &&
      parseInt(birthDateThirdTwo) >= 0 &&
      parseInt(birthDateThirdTwo) < 100 &&
      ['-', 'A'].includes(century) && // + means born in 1800s
      /^\d+$/.test(sequence_fi) &&
      parseInt(sequence_fi) >= 0 &&
      parseInt(sequence_fi) < 1000 &&
      /^[A-Z0-9]+$/.test(control)
    ) {
      return true;
    }
  }
};

export const validateNorwayNationalIdNumberFormat = (nationalIdNumber = '') => {
  const tempIdentityNumber = nationalIdNumber?.toUpperCase();
  const tempLength = tempIdentityNumber?.length;

  if (tempLength === 11) {
    const birthDateFirstTwo = tempIdentityNumber.slice(0, 2);
    const birthDateSecondTwo = tempIdentityNumber.slice(2, 4);
    const birthDateThirdTwo = tempIdentityNumber.slice(4, 6);
    const sequence_no = tempIdentityNumber.slice(6, 11);

    if (
      /^\d+$/.test(birthDateFirstTwo) &&
      parseInt(birthDateFirstTwo) > 0 &&
      parseInt(birthDateFirstTwo) < 32 &&
      /^\d+$/.test(birthDateSecondTwo) &&
      parseInt(birthDateSecondTwo) > 0 &&
      parseInt(birthDateSecondTwo) < 13 &&
      /^\d+$/.test(birthDateThirdTwo) &&
      parseInt(birthDateThirdTwo) >= 0 &&
      parseInt(birthDateThirdTwo) < 100 &&
      /^\d+$/.test(sequence_no)
    ) {
      return true;
    }
  }
};

export const validateSwedenNationalIdNumberFormat = (nationalIdNumber = '') => {
  const tempIdentityNumber = nationalIdNumber?.toUpperCase();
  const tempLength = tempIdentityNumber?.length;

  if (tempLength === 11) {
    const birthDateFirstTwo = tempIdentityNumber.slice(0, 2);
    const birthDateSecondTwo = tempIdentityNumber.slice(2, 4);
    const birthDateThirdTwo = tempIdentityNumber.slice(4, 6);
    const century = tempIdentityNumber.slice(6, 7);
    const sequence_se_dk = tempIdentityNumber.slice(7, 11);

    if (
      /^\d+$/.test(birthDateFirstTwo) &&
      parseInt(birthDateFirstTwo) >= 0 &&
      parseInt(birthDateFirstTwo) < 100 &&
      /^\d+$/.test(birthDateSecondTwo) &&
      parseInt(birthDateSecondTwo) > 0 &&
      parseInt(birthDateSecondTwo) < 13 &&
      /^\d+$/.test(birthDateThirdTwo) &&
      parseInt(birthDateThirdTwo) > 0 &&
      parseInt(birthDateThirdTwo) < 32 &&
      ['-'].includes(century) && // + means 100+ age
      /^\d+$/.test(sequence_se_dk) &&
      parseInt(sequence_se_dk) >= 0 &&
      parseInt(sequence_se_dk) < 10000
    ) {
      return true;
    }
  } else if (tempLength === 12) {
    const birthYear = tempIdentityNumber.slice(0, 4);
    const birthMonth = tempIdentityNumber.slice(4, 6);
    const birthDay = tempIdentityNumber.slice(6, 8);
    const sequence_se = tempIdentityNumber.slice(8, 12);

    if (
      /^\d+$/.test(birthYear) &&
      parseInt(birthYear) >= 1900 &&
      parseInt(birthYear) < 2050 &&
      /^\d+$/.test(birthMonth) &&
      parseInt(birthMonth) > 0 &&
      parseInt(birthMonth) < 13 &&
      /^\d+$/.test(birthDay) &&
      parseInt(birthDay) > 0 &&
      parseInt(birthDay) < 32 &&
      /^\d+$/.test(sequence_se) &&
      parseInt(sequence_se) >= 0 &&
      parseInt(sequence_se) < 10000
    ) {
      return true;
    }
  } else if (tempLength === 13) {
    const birthYear = tempIdentityNumber.slice(0, 4);
    const birthMonth = tempIdentityNumber.slice(4, 6);
    const birthDay = tempIdentityNumber.slice(6, 8);
    const century = tempIdentityNumber.slice(8, 9);
    const sequence_se = tempIdentityNumber.slice(9, 13);

    if (
      /^\d+$/.test(birthYear) &&
      parseInt(birthYear) >= 1900 &&
      parseInt(birthYear) < 2050 &&
      /^\d+$/.test(birthMonth) &&
      parseInt(birthMonth) > 0 &&
      parseInt(birthMonth) < 13 &&
      /^\d+$/.test(birthDay) &&
      parseInt(birthDay) > 0 &&
      parseInt(birthDay) < 32 &&
      ['-'].includes(century) && // + means 100+ age
      /^\d+$/.test(sequence_se) &&
      parseInt(sequence_se) >= 0 &&
      parseInt(sequence_se) < 10000
    ) {
      return true;
    }
  }
};
