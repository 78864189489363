import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  transactions: [],
  selectedTransaction: {},
  transactionsFetched: false,
  initiatedTransactionId: null,
  initiatedTransactionPayinId: null,
  initiatedTransactionStatus: null,
  initiatedTransactionSubStatus: null,
  initiatedTransactionDescription: null,
  initiatedTransactionReference: null,
  authProcessorError: false,
  authProcessorErrorMessage: '',
  loading: false,
  error: false,
  errorMessage: '',
};

const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.error = false;
        state.errorMessage = '';
      }
      state.loading = isLoading;
    },
    clearLoading(state, action) {
      state.loading = false;
    },
    setError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    setTransactions(state, action) {
      state.transactions = action.payload;
      state.transactionsFetched = true;
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    setInitiatedTransaction(state, action) {
      const { id, payinId, statusCode, statusDescription, reference, subStatus } = action.payload;
      state.initiatedTransactionId = id;
      state.initiatedTransactionPayinId = payinId;
      state.initiatedTransactionStatus = statusCode;
      state.initiatedTransactionSubStatus = subStatus;
      state.initiatedTransactionDescription = statusDescription;
      state.initiatedTransactionReference = reference;
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    clearInitiatedTransaction(state, action) {
      state.initiatedTransactionId = null;
      state.initiatedTransactionPayinId = null;
      state.initiatedTransactionStatus = null;
      state.initiatedTransactionSubStatus = null;
      state.initiatedTransactionDescription = null;
      state.initiatedTransactionReference = null;
    },
    setSelectedTransaction(state, action) {
      state.selectedTransaction = action.payload;
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    clearSelectedTransaction(state, action) {
      state.selectedTransaction = {};
    },
    setDocumentUploaded(state, action) {
      state.documentUploaded = true;
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    clearDocumentUploaded(state, action) {
      state.documentUploaded = false;
    },
    setAuthProcessorError(state, action) {
      state.authProcessorError = true;
      state.authProcessorErrorMessage = action.payload;
    },
    clearAuthProcessorError(state, action) {
      state.authProcessorError = false;
      state.authProcessorErrorMessage = '';
    },
    clearError(state, action) {
      state.error = false;
      state.errorMessage = '';
    },
    clearTransactionsState: (state) => initialState,
    logout: (state) => initialState,
  },
});

export const transactionActions = transactionSlice.actions;
export default transactionSlice;
