import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
    palette: {
        primary: {
            light: '#FFAC28',
            main: '#E98400',
            dark: '#C15C00',
            contrastText: '#3F3F3F',
        },
        secondary: {
            light: '#7BFECD',
            main: '#53D6A5',
            dark: '#2BAE7D',
            contrastText: '#FFF',
        },
        danger: {
            main: 'red',
        },
        whiteColor: {
            main: 'white',
        },
        specifics: {
            topNavBarPadding: 1.4,
            topNavBarPaddingLeft: '18%',
            topNavBarPaddingRight: '18%',
            topNavbarRootBackground: '#FFF',
            topNavbarItemColor: '#C15C00',
            topNavbarRegisterItemColor: '#FFF',
            bottomNavbarRootBackground: '#3F3F3F',
            bottomNavbarIconColorMode: '',
            accountNavBarDrawerBackgroundColor: '#FFF',
            accountNavBarDrawerColor: '#3F3F3F',
            accountNavBarItemIconColor: '#3F3F3F',
            sendMoneyCard: 'rgba(230,230,230,0.95)',
            loginCard: 'rgba(230,230,230,0.95)',
            registerCard: 'rgba(230,230,230,0.95)',
            footerDividerColor: '#FFF',
            footerItemColor: '#FFF',
        },
        mode: 'light',
        type: 'light',
    },
    config: {
        themeNumber: 1,
        isLoginAdEnabled: true,
        topNavBarRegisterButtonSize: 'large',
        topNavBarRegisterButtonVariant: 'contained',
        topNavBarRegisterButtonColor: 'primary',
        topNavBarLogoHeight: '',
        isLoginLikeWordPress: true,
        isTopNavbarFixed: false,
        isBottomNavbarFixed: false,
        topNavBarIconMenusColorPalette: 'primary',
    },
    props: {
        MuiMenu: {
            disableScrollLock: true,
        },
    },
    components: {
        MuiAlert: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: '0',
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid white',
                },
            },
        },
    },
    typography: {
        fontSize: 13,
        display4: {
            fontSize: 13,
        },
        display3: {
            fontSize: 13,
        },
        display2: {
            fontSize: 13,
        },
        display1: {
            fontSize: 13,
        },
        headline: {
            fontSize: 13,
        },
        title: {
            fontSize: 13,
        },
        subheading: {
            fontSize: 13,
        },
        body4: {
            fontSize: 35,
        },
        body3: {
            fontSize: 11,
        },
        body2: {
            fontSize: 12,
        },
        body1: {
            fontSize: 13,
        },
        caption: {
            fontSize: 13,
        },
        button: {
            fontSize: 13,
        },
        h7: {
            fontSize: '1rem',
        },
    },
});

export default Theme;
