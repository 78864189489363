import { Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const fallbackStyles = makeStyles((theme) => {
  return {
    root: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.main,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.primary.dark,
    },
  };
});

const Fallback = () => {
  const styles = fallbackStyles();
  return (
    <>
      <div className={styles.root}></div>
      <Backdrop className={styles.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Fallback;
