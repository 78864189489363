import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  remitSendingCountry: '',
  remitRecipient: {},
  remitProduct: {},
  remitService: {},
  remitPaymentOption: {},
  remitAdditionalData: {
    senderBranch: '',
    receiverCountry: '',
    receiverCity: '',
    cashBranchLocation: '',
    senderIdType: '',
    senderIdDesc: '',
    senderIdNumber: '',
    senderIssueDate: '',
    senderExpiryDate: '',
    purpose: '',
    purposeCode: '',
    sourceOfIncome: '',
    remarks: '',
  },
  remitTransaction: {},
  paymentOptions: [],
  paymentOptionsFetched: false,
  loading: false,
  error: false,
  errorMessage: '',
  loadingPaymentOptions: false,
  errorPaymentOptions: false,
  errorMessagePaymentOptions: '',
  loadingRemitRecipient: false,
  errorRemitRecipient: false,
  errorMessageRemitRecipient: '',
  remitRecipientFetched: false,
  confirmedRemittance: false,
  sendingAmountLimitError: false,
  sendingLimitAllowed: false,
};

const remitSlice = createSlice({
  name: 'remit',
  initialState,
  reducers: {
    setLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.error = false;
        state.errorMessage = '';
      }
      state.loading = isLoading;
    },
    setError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    setRemitSendingCountry(state, action) {
      state.remitSendingCountry = action.payload;
    },
    setPaymentOptions(state, action) {
      state.paymentOptions = action.payload;
      state.paymentOptionsFetched = true;
      state.errorPaymentOptions = false;
      state.errorMessagePaymentOptions = '';
      state.loadingPaymentOptions = false;
    },
    setPaymentOptionsLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.errorPaymentOptions = false;
        state.errorMessagePaymentOptions = '';
      }
      state.loadingPaymentOptions = isLoading;
    },
    setPaymentOptionsError(state, action) {
      state.loadingPaymentOptions = false;
      state.errorPaymentOptions = true;
      state.errorMessagePaymentOptions = action.payload;
    },
    setRemitRecipient(state, action) {
      state.remitRecipient = action.payload;
      state.remitRecipientFetched = true;
      state.errorRemitRecipient = false;
      state.errorMessageRemitRecipient = '';
      state.loadingRemitRecipient = false;
      state.remitAdditionalData = {
        ...state.remitAdditionalData,
        receiverCountry: action.payload.country,
        receiverCity: action.payload.city,
        cashBranchLocation: action.payload.cashBranchLocation,
      };
    },
    setRemitRecipientLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.errorRemitRecipient = false;
        state.errorMessageRemitRecipient = '';
      }
      state.loadingRemitRecipient = isLoading;
    },
    setRemitRecipientError(state, action) {
      state.loadingRemitRecipient = false;
      state.errorRemitRecipient = true;
      state.errorMessageRemitRecipient = action.payload;
    },
    setRemitProduct(state, action) {
      state.remitProduct = action.payload;
    },
    setRemitService(state, action) {
      state.remitService = action.payload;
    },
    setRemitPaymentOption(state, action) {
      state.remitPaymentOption = action.payload;
    },
    setRemitAdditionalData(state, action) {
      state.remitAdditionalData = action.payload;
    },
    clearRemitRecipient(state, action) {
      state.remitRecipient = {};
    },
    clearRemitProduct(state, action) {
      state.remitProduct = {};
    },
    clearRemitService(state, action) {
      state.remitService = {};
    },
    clearRemitPaymentOption(state, action) {
      state.remitPaymentOption = {};
    },
    clearRemitAdditionalData(state, action) {
      state.remitAdditionalData = {};
    },
    setRemitTransaction(state, action) {
      state.remitTransaction = action.payload;
    },
    clearRemitTransaction(state, action) {
      state.remitTransaction = {};
    },
    setConfirmedRemittance(state, action) {
      state.confirmedRemittance = true;
    },
    clearRemitKeepRecipient(state, action) {
      state.remitProduct = {};
      state.remitService = {};
      state.remitPaymentOption = {};
      state.remitTransaction = {};
      state.paymentOptions = [];
      state.paymentOptionsFetched = false;
      state.loading = false;
      state.error = false;
      state.errorMessage = '';
      state.loadingPaymentOptions = false;
      state.errorPaymentOptions = false;
      state.errorMessagePaymentOptions = '';
      state.confirmedRemittance = false;
    },
    setSendingAmountLimitError(state, action) {
      state.sendingAmountLimitError = true;
    },
    setSendingLimitAllowed(state, action) {
      state.sendingLimitAllowed = true;
    },
    clearSendingLimitAllowed(state, action) {
      state.sendingLimitAllowed = false;
    },
    clearRemit: (state) => initialState,
    logout: (state) => initialState,
  },
});

export const remitActions = remitSlice.actions;
export default remitSlice;
