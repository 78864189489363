import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import Cookies from 'js-cookie';
import _ from 'lodash';

import MainLayout from './containers/MainLayout/MainLayout';

import { checkTransactionsInComplianceStatus, getClientIpAddress } from './store/actions/app-actions';
import { appActions } from './store/slices/app-slice';
import { authActions } from './store/slices/auth-slice';

import { Favicon } from './assets/assetSelector';
import { authGetCurrentUser } from './store/actions/auth-actions';

import config from './config';
import Router from './router';

const App = () => {
    const dispatch = useDispatch();
    const tenant = config.REACT_APP_TENANT + config.REACT_APP_TENANT_SUFFIX;
    const theme = require(`./themes/${tenant}-theme`).default;

    const hasPublicRoute = window.location.href.includes('public/');
    const [isMobileAuth] = useState(Cookies.get('mobileAuth'));

    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        document.title = 'Send And Receive Money With ' + config.REACT_APP_TENANT_SHORT_NAME;
        document.querySelector('#remittance-favicon').href = Favicon();

        const widthIsMobile = window.innerWidth < 600;
        dispatch(appActions.setIsMobile(widthIsMobile));

        window.addEventListener('resize', () => {
            const widthIsMobile = window.innerWidth < 600;
            dispatch(appActions.setIsMobile(widthIsMobile));
        });
    }, [dispatch]);

    useEffect(() => {
        const storedUserSession = localStorage.getItem('usersession');
        const storedUserInfo = localStorage.getItem('userinfo');

        if (storedUserSession && storedUserInfo) {
            const userLoginData = JSON.parse(storedUserSession);
            const userInfoData = JSON.parse(storedUserInfo);

            dispatch(authActions.login({ userLoginData, userInfoData }));
            dispatch(authGetCurrentUser(userLoginData));
        }
    }, [dispatch]);

    useEffect(() => {
        const storedPaymentsSession = localStorage.getItem('payments');
        const newPaymentsSession = [];

        if (storedPaymentsSession) {
            const storedPaymentsData = JSON.parse(storedPaymentsSession);
            const currentUTCDateTime = new Date().toISOString();
            const epochTime = Date.parse(currentUTCDateTime);
            newPaymentsSession.push(...storedPaymentsData.filter((pd) => epochTime - pd.epochTimeStamp < 604800000));
        }

        localStorage.setItem('payments', JSON.stringify(newPaymentsSession));
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(getClientIpAddress());
            dispatch(checkTransactionsInComplianceStatus());
        }
    }, [dispatch, isLoggedIn]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleActivity = useCallback(
        _.debounce(
            (e) => {
                if ((e?.type === 'visibilitychange' && !document.hidden) || e?.type === 'focus') {
                    const storedUserSession = localStorage.getItem('usersession');
                    const storedUserInfo = localStorage.getItem('userinfo');

                    if (storedUserSession && storedUserInfo) {
                        const userLoginData = JSON.parse(storedUserSession);
                        const userInfoData = JSON.parse(storedUserInfo);

                        dispatch(authActions.login({ userLoginData, userInfoData }));
                        dispatch(authGetCurrentUser(userLoginData));
                    }
                }
            },
            100,
            { leading: false },
        ),
        [],
    );

    useEffect(() => {
        document.addEventListener('visibilitychange', handleActivity);
        document.addEventListener('blur', handleActivity);
        window.addEventListener('blur', handleActivity);
        window.addEventListener('focus', handleActivity);
        document.addEventListener('focus', handleActivity);

        return () => {
            window.removeEventListener('blur', handleActivity);
            document.removeEventListener('blur', handleActivity);
            window.removeEventListener('focus', handleActivity);
            document.removeEventListener('focus', handleActivity);
            document.removeEventListener('visibilitychange', handleActivity);
        };
    }, [handleActivity]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {hasPublicRoute || isMobileAuth ? <Router /> : <MainLayout />}
        </ThemeProvider>
    );
};

export default App;
