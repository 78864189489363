import axios from 'axios';
import Jsona from 'jsona';
import jwt from 'jwt-decode';
import _ from 'lodash';

import config from '../../config';
import http from '../api/http';
import { authActions } from '../slices/auth-slice';
import { appActions } from '../slices/app-slice';

export const authLogin = (username, password) => {
    const apiBaseUrl = config.REACT_APP_IDENTITY_BASE_URL + config.REACT_APP_IDENTITY_LOGIN_URI;

    const objRequest = {
        data: {
            type: 'token',
            attributes: {
                username,
                password,
                clientid: config.REACT_APP_CLIENT_ID,
            },
        },
    };

    const request = axios.post(apiBaseUrl, objRequest);

    return (dispatch) => {
        dispatch(authActions.setLoading({ isLoading: true, willClearError: true }));

        return request.then(
            ({ data }) => {
                const userLoginData = new Jsona().deserialize(data);

                localStorage.removeItem('authProcessor');
                localStorage.setItem('usersession', JSON.stringify(userLoginData));
                dispatch(authGetCurrentUser(userLoginData));

                // const userInfoData = {
                //   id: 'abcd-1234-defg-5678',
                //   Fullname: 'JR Rogacion',
                // };
                // const decodedJWT = jwt(userLoginData.accessToken);
                // localStorage.setItem('userinfo', JSON.stringify(userInfoData));
                // dispatch(
                //   authActions.login({ userLoginData, userInfoData, decodedJWT })
                // );
            },
            (error) => {
                let errorMessage = config.generalError;

                try {
                    if (error.messages && typeof error.messages === 'string') {
                        errorMessage = error.messages;
                    } else if (error.response.data.message && typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (error.response.data.errors.message && typeof error.response.data.errors.message === 'string') {
                        errorMessage = error.response.data.errors.message;
                    }
                } catch {
                    console.log('error.response: ', error.response);
                    console.log('error.request: ', error.request);
                    console.log('error.config: ', error.config);
                    if (error.response) {
                        errorMessage = 'Login failed!';
                    }
                }

                console.log('Auth authLogin error: ' + errorMessage);

                localStorage.removeItem('usersession');
                dispatch(authActions.setError(errorMessage));
            },
        );
    };
};

export const authGetCurrentUser = (userLoginData, accessToken) => {
    const apiBaseUrl = config.REACT_APP_IDENTITY_BASE_URL + config.REACT_APP_USER_URI + userLoginData.id;

    const requestConfig = {
        params: {
            include: `${config.REACT_APP_SENDING_AMOUNT_LIMITS_ENABLED === 'true' ? 'account.amountLimits,' : ''}${
                config.REACT_APP_PROFILE_INCLUDE
            }${config.REACT_APP_KYC_QNA_ENABLED === 'true' ? ',kycqna,kycqnaexpiry' : ''}`,
        },
    };

    let request;
    if (accessToken) {
        request = axios.get(apiBaseUrl, {
            ...requestConfig,
            headers: {
                'X-Frame-Options': 'sameorigin',
                'X-XSS-Protection': '1; mode=block',
                'Strict-Transport-Security': 'max-age=63072000; includeSubDomains; preload',
                'X-Content-Type-Options': 'nosniff',
                Authorization: 'Bearer ' + accessToken,
            },
        });
    } else {
        request = http.get(apiBaseUrl, requestConfig);
    }

    return (dispatch) => {
        return request.then(
            ({ data }) => {
                let userInfoData = new Jsona().deserialize(data);
                const decodedJWT = jwt(userLoginData.accessToken);

                if (config.REACT_APP_SENDING_AMOUNT_LIMITS_ENABLED === 'true') {
                    const amountLimitIncludedList = data.included.filter((i) => i.type === 'amountlimit');

                    if (amountLimitIncludedList && amountLimitIncludedList.length > 0) {
                        let amountLimitRelationship = null;

                        if (data.data.relationships && data.data.relationships.amountlimit && data.data.relationships.amountlimit.data) {
                            amountLimitRelationship = new Jsona().deserialize({
                                data: _.isArray(data.data.relationships.amountlimit.data)
                                    ? [
                                          ...amountLimitIncludedList.filter((al) =>
                                              data.data.relationships.amountlimit.data.some((al2) => al2.data.id === al.id),
                                          ),
                                      ]
                                    : [amountLimitIncludedList.find((al) => al.id === data.data.relationships.amountlimit.data.id)],
                            });
                        }

                        userInfoData = {
                            ...userInfoData,
                            amountlimit: amountLimitRelationship,
                        };
                    }
                }

                localStorage.setItem('userinfo', JSON.stringify(userInfoData));
                dispatch(appActions.setIsPinRequired(decodedJWT['client_require-pin'] === 'true'));
                dispatch(appActions.setIsOtpRequired(decodedJWT['client_require-otp'] === 'true'));
                dispatch(
                    authActions.login({
                        userLoginData,
                        userInfoData,
                        decodedJWT,
                        bypassLoggedIn: !!accessToken,
                    }),
                );
            },
            (error) => {
                let errorMessage = config.generalError;

                try {
                    if (error.messages && typeof error.messages === 'string') {
                        errorMessage = error.messages;
                    } else if (error.response.data.message && typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (error.response.data.errors.message && typeof error.response.data.errors.message === 'string') {
                        errorMessage = error.response.data.errors.message;
                    }
                } catch {
                    console.log('error.response: ', error.response);
                    console.log('error.request: ', error.request);
                    console.log('error.config: ', error.config);
                    if (error.response) {
                        errorMessage = 'Login failed!';
                    }
                }

                console.log('Auth authGetCurrentUser error: ' + errorMessage);

                localStorage.removeItem('userinfo');
                localStorage.removeItem('usersession');
                dispatch(authActions.setError('Login failed!'));
            },
        );
    };
};

export function authResetPassword(email) {
    const apiBaseUrl = config.REACT_APP_IDENTITY_BASE_URL + config.REACT_APP_IDENTITY_FORGET_URI;

    const params = {
        data: {
            type: 'Account',
            attributes: {
                email,
                tenant: config.REACT_APP_TENANT,
                role: 256,
            },
        },
    };

    const request = axios.post(apiBaseUrl, params);

    return (dispatch) => {
        dispatch(authActions.setLoading({ isLoading: true, willClearError: true }));

        request.then(
            ({ data }) => {
                dispatch(authActions.setResetPasswordSent());
            },
            (error) => {
                let errorMessage = config.generalError;

                try {
                    if (error.messages && typeof error.messages === 'string') {
                        errorMessage = error.messages;
                    } else if (error.response.data.message && typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (error.response.data.errors.message && typeof error.response.data.errors.message === 'string') {
                        errorMessage = error.response.data.errors.message;
                    }
                } catch {
                    console.log('error.response: ', error.response);
                    console.log('error.request: ', error.request);
                    console.log('error.config: ', error.config);
                    if (error.response) {
                        errorMessage = 'Reset password failed!';
                    }
                }

                console.log('Auth authResetPassword error: ' + errorMessage);

                dispatch(authActions.setError(errorMessage));
            },
        );
    };
}

export function authChangePassword(password, resetPasswordToken) {
    const apiBaseUrl = config.REACT_APP_IDENTITY_BASE_URL + config.REACT_APP_IDENTITY_RESET_URI;

    const params = {
        data: {
            type: 'Account',
            attributes: {
                password,
                resetPasswordToken,
            },
        },
    };

    const request = axios.patch(apiBaseUrl, params);

    return (dispatch) => {
        dispatch(authActions.setLoading({ isLoading: true, willClearError: true }));

        request.then(
            ({ data }) => {
                dispatch(authActions.setResetPasswordSent());
            },
            (error) => {
                let errorMessage = config.generalError;

                try {
                    if (error.messages && typeof error.messages === 'string') {
                        errorMessage = error.messages;
                    } else if (error.response.data.message && typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (error.response.data.errors.message && typeof error.response.data.errors.message === 'string') {
                        errorMessage = error.response.data.errors.message;
                    }
                } catch {
                    console.log('error.response: ', error.response);
                    console.log('error.request: ', error.request);
                    console.log('error.config: ', error.config);
                    if (error.response) {
                        errorMessage = 'Reset password failed!';
                    }
                }

                console.log('Auth authResetPassword error: ' + errorMessage);

                dispatch(authActions.setError(errorMessage));
            },
        );
    };
}

export function authConfirmEmail(confirmationToken) {
    const apiBaseUrl = config.REACT_APP_IDENTITY_BASE_URL + config.REACT_APP_CONFIRM_ACCOUNT_URI;

    const params = {
        data: {
            type: 'Account',
            attributes: {
                confirmationToken,
                tenant: config.REACT_APP_TENANT,
            },
        },
    };

    const request = axios.patch(apiBaseUrl, params);

    return (dispatch) => {
        dispatch(authActions.setLoading({ isLoading: true, willClearError: true }));

        request.then(
            ({ data }) => {
                dispatch(authActions.setConfirmedEmail());
            },
            (error) => {
                let errorMessage = config.generalError;

                try {
                    if (error.messages && typeof error.messages === 'string') {
                        errorMessage = error.messages;
                    } else if (error.response.data.message && typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (error.response.data.errors.message && typeof error.response.data.errors.message === 'string') {
                        errorMessage = error.response.data.errors.message;
                    }
                } catch {
                    console.log('error.response: ', error.response);
                    console.log('error.request: ', error.request);
                    console.log('error.config: ', error.config);
                    if (error.response) {
                        errorMessage = 'Confirm account failed!';
                    }
                }

                console.log('Auth authConfirmEmail error: ' + errorMessage);

                dispatch(authActions.setError(errorMessage));
            },
        );
    };
}

export const authClearConfirmedEmail = () => {
    return (dispatch) => {
        dispatch(authActions.clearConfirmedEmail());
    };
};

export function authValidateRecaptchaToken(token) {
    const apiBaseUrl = config.REACT_APP_IDENTITY_BASE_URL + config.REACT_APP_VALIDATE_RECAPTCHA_TOKEN_URI;

    const params = {
        data: {
            type: 'recaptcha',
            attributes: {
                token,
                projectId: 'test',
                recaptchaAction: 'LOGIN',
                recaptchaSiteKey: 'test',
                tenant: config.REACT_APP_TENANT,
            },
        },
    };

    const request = axios.post(apiBaseUrl, params);

    return (dispatch) => {
        // dispatch(authActions.setLoading({ isLoading: true, willClearError: true }));

        request.then(
            ({ data }) => {
                if (data.success) {
                    dispatch(authActions.setRecaptchaTokenValidated());
                }
            },
            (error) => {
                let errorMessage = config.generalError;

                try {
                    if (error.messages && typeof error.messages === 'string') {
                        errorMessage = error.messages;
                    } else if (error.response.data.message && typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (error.response.data.errors.message && typeof error.response.data.errors.message === 'string') {
                        errorMessage = error.response.data.errors.message;
                    }
                } catch {
                    console.log('error.response: ', error.response);
                    console.log('error.request: ', error.request);
                    console.log('error.config: ', error.config);
                    if (error.response) {
                        errorMessage = 'Recaptcha validation failed!';
                    }
                }

                console.log('Auth authValidateRecaptchaToken error: ' + errorMessage);

                dispatch(authActions.setError(errorMessage));
            },
        );
    };
}

export const authClearRecaptchaTokenValidated = () => {
    return (dispatch) => {
        dispatch(authActions.clearRecaptchaTokenValidated());
    };
};

export const fetchUpdatedUserProfile = () => {
    let objAuthSession = JSON.parse(localStorage.getItem('usersession'));

    return (dispatch) => {
        dispatch(authGetCurrentUser(objAuthSession));
    };
};

export const authClearResetPasswordSent = () => {
    return (dispatch) => {
        dispatch(authActions.clearResetPasswordSent());
    };
};

export const authClearChangePasswordDone = () => {
    return (dispatch) => {
        dispatch(authActions.clearChangePasswordDone());
    };
};

export const authSetError = (errorMessage) => {
    return (dispatch) => {
        dispatch(authActions.setError(errorMessage));
    };
};

export const authClearError = () => {
    return (dispatch) => {
        dispatch(authActions.logout());
    };
};

export const authLogout = () => {
    localStorage.clear();

    return (dispatch) => {
        dispatch(authActions.logout());
    };
};
