import { makeStyles } from '@mui/styles';

const topNavBarStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.specifics.topNavbarRootBackground,
      padding: theme.spacing(theme.palette.specifics.topNavBarPadding),
      zIndex: 999,
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(
          theme.palette.specifics.topNavBarPaddingLeft
        ),
        paddingRight: theme.spacing(
          theme.palette.specifics.topNavBarPaddingRight
        ),
      },
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    menuItemLink: {
      marginLeft: theme.spacing(3),
      margin: 'auto',
      textDecoration: 'none',
    },
    menuItemLinkLikeWordpress: {
      color: '#000',
      fontWeight: 400,
      marginLeft: theme.spacing(1),
      margin: 'auto',
      textDecoration: 'none',
    },
    menuItemLinkMobile: {
      marginLeft: theme.spacing(1),
      margin: 'auto',
      textDecoration: 'none',
    },
    menuItem: {
      color: theme.palette.specifics.topNavbarItemColor,
      fontWeight: 600,
    },
    menuItemMobile: {
      color: theme.palette.specifics.topNavbarItemColor,
      fontWeight: 600,
      fontSize: 12,
    },
    menuItemRegister: {
      color: theme.palette.specifics.topNavbarRegisterItemColor,
      fontWeight: 600,
    },
    menuItemMobileRegister: {
      color: theme.palette.specifics.topNavbarRegisterItemColor,
      fontWeight: 600,
      fontSize: 12,
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  };
});

export default topNavBarStyles;
