import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  localCountries: [],
  loadingLocalCountries: false,
  localCountriesFetched: false,
  errorLocalCountries: false,
  errorMessageLocalCountries: '',
  remitOptions: {},
  loadingRemitOptions: false,
  errorRemitOptions: false,
  errorMessageRemitOptions: '',
  computedRate: {},
  loadingComputedRate: false,
  errorComputedRate: false,
  errorMessageComputedRate: '',
};

const rateSlice = createSlice({
  name: 'rate',
  initialState,
  reducers: {
    setLoadingLocalCountries(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.errorLocalCountries = false;
        state.errorMessageLocalCountries = '';
      }
      state.loadingLocalCountries = isLoading;
    },
    setErrorLocalCountries(state, action) {
      state.loadingLocalCountries = false;
      state.errorLocalCountries = true;
      state.errorMessageLocalCountries = action.payload;
    },
    setLocalCountries(state, action) {
      state.localCountries = action.payload;
      state.errorLocalCountries = false;
      state.errorMessageLocalCountries = '';
      state.loadingLocalCountries = false;
      state.localCountriesFetched = true;
    },
    setLoadingRemitOptions(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.errorRemitOptions = false;
        state.errorMessageRemitOptions = '';
      }
      state.loadingRemitOptions = isLoading;
    },
    setErrorRemitOptions(state, action) {
      state.loadingRemitOptions = false;
      state.errorRemitOptions = true;
      state.errorMessageRemitOptions = action.payload;
    },
    setRemitOptions(state, action) {
      state.remitOptions = action.payload;
      state.errorRemitOptions = false;
      state.errorMessageRemitOptions = '';
      state.loadingRemitOptions = false;
    },
    setLoadingComputedRate(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.errorComputedRate = false;
        state.errorMessageComputedRate = '';
      }
      state.loadingComputedRate = isLoading;
    },
    setErrorComputedRate(state, action) {
      state.loadingComputedRate = false;
      state.errorComputedRate = true;
      state.errorMessageComputedRate = action.payload;
    },
    setComputedRate(state, action) {
      state.computedRate = action.payload;
      state.errorComputedRate = false;
      state.errorMessageComputedRate = '';
      state.loadingComputedRate = false;
    },
    clearComputedRate(state, action) {
      state.computedRate = {};
      state.errorComputedRate = false;
      state.errorMessageComputedRate = '';
    },
    clearRemitOptions(state, action) {
      state.remitOptions = {};
      state.errorRemitOptions = false;
      state.errorMessageRemitOptions = '';
    },
    logout: (state) => initialState,
  },
});

export const rateActions = rateSlice.actions;
export default rateSlice;
