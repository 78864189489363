import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    recipients: [],
    recipientsFetched: false,
    selectedUpdateRecipient: {},
    createdId: null,
    created: false,
    updated: false,
    deleted: false,
    accountNumberValidated: false,
    accountNumberValidationDone: false,
    accountNameValidationEmpty: false,
    loading: false,
    error: false,
    errorMessage: '',
};

const recipientSlice = createSlice({
    name: 'recipient',
    initialState,
    reducers: {
        setLoading(state, action) {
            const { isLoading, willClearError } = action.payload;

            if (willClearError) {
                state.error = false;
                state.errorMessage = '';
            }
            state.loading = isLoading;
        },
        setError(state, action) {
            state.loading = false;
            state.error = true;
            state.errorMessage = action.payload;
        },
        setRecipients(state, action) {
            state.recipients = action.payload;
            state.recipientsFetched = true;
            state.error = false;
            state.errorMessage = '';
            state.loading = false;
        },
        setSelectedUpdateRecipient(state, action) {
            state.selectedUpdateRecipient = action.payload;
        },
        setCreated(state, action) {
            state.createdId = action.payload;
            state.created = true;
            state.error = false;
            state.errorMessage = '';
            state.loading = false;
        },
        setUpdated(state, action) {
            state.updated = true;
            state.error = false;
            state.errorMessage = '';
            state.loading = false;
        },
        setDeleted(state, action) {
            state.deleted = true;
            state.error = false;
            state.errorMessage = '';
            state.loading = false;
        },
        clearSelectedUpdateRecipient(state, action) {
            state.selectedUpdateRecipient = {};
        },
        clearCRUD(state, action) {
            state.createdId = null;
            state.created = false;
            state.updated = false;
            state.deleted = false;
        },
        setAccountNumberValidated(state, action) {
            const { validated, accountNameEmpty } = action.payload;
            state.accountNumberValidated = validated;
            state.accountNumberValidationDone = true;
            state.accountNameValidationEmpty = accountNameEmpty;
            state.error = false;
            state.errorMessage = '';
            state.loading = false;
        },
        clearAccountNumberValidated(state, action) {
            state.accountNumberValidated = false;
            state.accountNumberValidationDone = false;
            state.accountNameValidationEmpty = false;
        },
        clearError(state, action) {
            state.error = false;
            state.errorMessage = '';
        },
        clearRecipientState: (state) => initialState,
        logout: (state) => initialState,
    },
});

export const recipientActions = recipientSlice.actions;
export default recipientSlice;
