import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  products: [],
  selectedProduct: {},
  productsFetched: false,
  loading: false,
  error: false,
  errorMessage: '',
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.error = false;
        state.errorMessage = '';
      }
      state.loading = isLoading;
    },
    setError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    setProducts(state, action) {
      state.products = action.payload;
      state.productsFetched = true;
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    setSelectedProduct(state, action) {
      state.selectedProduct = action.payload;
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    clearSelectedProduct(state) {
      state.selectedProduct = {};
    },
    logout: (state) => initialState,
  },
});

export const productActions = productSlice.actions;
export default productSlice;
