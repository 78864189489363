import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userKYCData: {},
  loading: false,
  error: false,
  errorMessage: '',
  isQueried: false,
  willCancelIncoming: false,
};

const kycSlice = createSlice({
  name: 'kyc',
  initialState,
  reducers: {
    setLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.error = false;
        state.errorMessage = '';
      }
      state.loading = isLoading;
    },
    setError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    setCancelIncoming(state, action) {
      state.willCancelIncoming = action.payload;
    },
    setUserKYCData(state, action) {
      if (state.willCancelIncoming) {
        state.loading = false;
        state.userKYCData = {};
        state.isQueried = false;
        state.willCancelIncoming = false;
      } else {
        state.userKYCData = action.payload;
        state.error = false;
        state.errorMessage = '';
        state.loading = false;
        state.isQueried = true;
      }
    },
    clearUserKYCData(state, action) {
      state.loading = false;
      state.userKYCData = {};
      state.error = false;
      state.errorMessage = null;
      state.isQueried = false;
    },
    logout: (state) => initialState,
  },
});

export const kycActions = kycSlice.actions;
export default kycSlice;
