import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const UnauthenticatedRoute = (props) => {
  const { title, component: C, props: childProps, ...rest } = props;
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isLoggedIn ? (
          <Redirect to={routeProps.location.search.replace('?redirect=', '')} />
        ) : (
          <C {...routeProps} {...childProps} />
        )
      }
    />
  );
};

export default UnauthenticatedRoute;
