import axios from 'axios';
import qs from 'qs';

import config from '../../config';

// let store;
// export const injectStore = (_store) => {
//   store = _store;
// };

const axiosInstance = axios.create({
    paramsSerializer: function (params) {
        return qs.stringify(params, { encode: false });
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        config.headers = buildHeaders(config);

        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response) => {
        if (response.status < 200 || response.status > 202) {
            return parseError(response.data ? response.data.errors : null);
        } else {
            return response;
        }
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            let errorMSG = 'Session has expired.  You will be logged-off.';
            localStorage.clear();
            localStorage.setItem('error', 'Session has expired.  Log-in again.');
            window.location.reload();
            return Promise.reject({ messages: errorMSG, reload: true });
        }

        if (error.response && error.response.data) {
            return parseError(error.response.data.errors);
        }

        if (error.message && typeof error.message === 'string') {
            return Promise.reject({ messages: error.message });
        }

        return Promise.reject(error);
    },
);

const buildHeaders = (config) => {
    const apiToken = getSessionToken();

    const finalHeaders = {
        'X-Frame-Options': 'sameorigin',
        'X-XSS-Protection': '1; mode=block',
        'Strict-Transport-Security': 'max-age=63072000; includeSubDomains; preload',
        'X-Content-Type-Options': 'nosniff',
    };

    if (apiToken) {
        finalHeaders['Authorization'] = apiToken;
    }

    return finalHeaders;
};

const getSessionToken = () => {
    if (!!localStorage.getItem('usersession')) {
        try {
            const userTenantSession = JSON.parse(localStorage.getItem('usersession'));

            if (!!userTenantSession?.accessToken) {
                return 'Bearer ' + userTenantSession?.accessToken;
            } else {
                return null;
            }
        } catch {
            return null;
        }
    }

    return null;
};

const parseError = (errors) => {
    let returnErrorMessage = config.generalError;

    if (errors) {
        if (errors instanceof Array) {
            returnErrorMessage = errors.map((error) => error.title || error.value).join(' ');
        } else {
            returnErrorMessage = errors.message;
        }
    }

    return Promise.reject({
        messages: returnErrorMessage,
    });
};

const http = axiosInstance;
export default http;
