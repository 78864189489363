import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    initialLoad: true,
    boolNotFoundPhone: false,
    phoneValidationResult: undefined,
    boolIsNVValid: false,
    nameGuide: undefined,
    loading: false,
    error: false,
    errorMessage: '',
    compareError: false,
    compareErrorName: ''
};

const nameValidatorSlice = createSlice({
    name: 'namevalidator',
    initialState,
    reducers: {
        setPhoneValidation(state, action) {
            state.phoneValidationResult = action.payload
            state.boolIsNVValid = true
            state.loading = false
            state.nameGuide = undefined
        },
        foundPhoneIsNotValid(state) {
            state.boolNotFoundPhone = true
            state.loading = false
            state.initialLoad = true
        },
        setGuide(state, action) {
            state.nameGuide = action.payload
            state.boolIsNVValid = true
            state.error = false;
            state.errorMessage = '';
            state.loading = false
        },
        setValidity(state, action) {
            state.boolIsNVValid = action.boolValid
        },
        setLoading(state, action) {
            const { isLoading } = action.payload;
            // console.log('found loading', isLoading)
            state.loading = isLoading;
        },
        setError(state, action) {
            state.loading = false;
            state.error = true;
            state.errorMessage = action.payload;
            state.compareError = false
            state.compareErrorName = ''
            state.nameGuide = undefined
            state.phoneValidationResult = undefined
        },
        setCompareError(state, action) {
            const name = action.payload.refName
            state.compareError = true;
            state.compareErrorName = name;
            state.boolIsNVValid = false;
            state.loading = false;
        },
        clearCompareError(state) {
            state.compareError = false;
            state.compareErrorName = '';
            state.boolIsNVValid = true;
            state.loading = false;
        },
        reset: (state) => initialState,
        logout: (state) => initialState,
    },
});

export const nameValidatorActions = nameValidatorSlice.actions;
export default nameValidatorSlice;
