import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    error: false,
    errorMessage: '',
    success: false,
    invalid: false,
    pinSetSuccess: false,
};

const pinSlice = createSlice({
    name: 'pin',
    initialState,
    reducers: {
        setLoading(state, action) {
            const { isLoading, willClearError } = action.payload;

            if (willClearError) {
                state.error = false;
                state.errorMessage = '';
            }
            state.loading = isLoading;
        },
        setError(state, action) {
            state.loading = false;
            state.error = true;
            state.errorMessage = action.payload;
        },
        setPINSetSuccess(state) {
            state.error = false;
            state.errorMessage = '';
            state.loading = false;
            state.pinSetSuccess = true;
        },
        setPINSuccess(state, action) {
            state.error = false;
            state.errorMessage = '';
            state.loading = false;
            state.received = true;
            state.success = true;
            state.invalid = false;
        },
        setPINInvalid(state, action) {
            state.error = true;
            state.errorMessage = action.payload;
            state.loading = false;
            state.received = true;
            state.success = false;
            state.invalid = true;
        },
        clearPINInvalid(state, action) {
            state.error = false;
            state.errorMessage = '';
            state.invalid = false;
        },
        clearPIN: (state) => initialState,
        logout: (state) => initialState,
    },
});

export const pinActions = pinSlice.actions;
export default pinSlice;
