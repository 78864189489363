import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  receivingBanks: [],
  receivingBanksFetched: false,
  loadingBank: false,
  errorBank: false,
  errorMessageBank: '',
  bankBranches: [],
  bankBranchesFetched: false,
  loadingBankBranch: false,
  errorBankBranch: false,
  errorMessageBankBranch: '',
};

const bankSlice = createSlice({
  name: 'bank',
  initialState,
  reducers: {
    setBankLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.errorBank = false;
        state.errorMessageBank = '';
      }
      state.loadingBank = isLoading;
    },
    setBankError(state, action) {
      state.loadingBank = false;
      state.errorBank = true;
      state.errorMessageBank = action.payload;
    },
    setReceivingBanks(state, action) {
      state.receivingBanks = action.payload;
      state.receivingBanksFetched = true;
      state.errorBank = false;
      state.errorMessageBank = '';
      state.loadingBank = false;
    },
    setBankBranchLoading(state, action) {
      const { isLoading, willClearError } = action.payload;

      if (willClearError) {
        state.errorBankBranch = false;
        state.errorMessageBankBranch = '';
      }
      state.loadingBankBranch = isLoading;
    },
    clearReceivingBanksFetched(state, action) {
      state.receivingBanksFetched = false;
    },
    setBankBranchError(state, action) {
      state.loadingBankBranch = false;
      state.errorBankBranch = true;
      state.errorMessageBankBranch = action.payload;
    },
    setBankBranches(state, action) {
      state.bankBranches = action.payload;
      state.bankBranchesFetched = true;
      state.errorBankBranch = false;
      state.errorMessageBankBranch = '';
      state.loadingBankBranch = false;
    },
    clearBankBranchesFetched(state, action) {
      state.bankBranchesFetched = false;
    },
    logout: (state) => initialState,
  },
});

export const bankActions = bankSlice.actions;
export default bankSlice;
