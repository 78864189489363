import { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import routes from './routes';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';

import Fallback from './Fallback';
import config from '../config';
import CommonRoute from './CommonRoute';

const AuthProcessor = lazy(() => import('../containers/Auth/Processor/AuthProcessor'));
const AuthProcessorMobile = lazy(() => import('../containers/Auth/Processor/Mobile/AuthProcessorMobile'));
const BankSign = lazy(() => import('../containers/Auth/Processor/processor/banksign/BankSign'));
const Freja = lazy(() => import('../containers/Auth/Processor/processor/freja/Freja'));
const Signicat = lazy(() => import('../containers/Auth/Processor/processor/signicat/Signicat'));
const ZignSec = lazy(() => import('../containers/Auth/Processor/processor/zignsec/ZignSec'));
const ConfirmEmail = lazy(() => import('../containers/Auth/ConfirmEmail/ConfirmEmail'));
const ChangePassword = lazy(() => import('../containers/Account/ChangePassword/ChangePassword'));
const ForgotPassword = lazy(() => import('../containers/Account/ForgotPassword/ForgotPassword'));
const Login = lazy(() => import('../containers/Auth/Login/Login'));
const PrivacyPolicy = lazy(() => import('../containers/About/PrivacyPolicy/PrivacyPolicy'));
const Payment = lazy(() => import('../containers/SendMoney/Payment/Payment'));
const PaymentMethodLink = lazy(() => import('../containers/Payment/PaymentMethodLink/PaymentMethodLink'));
const PublicPayment = lazy(() => import('../containers/Payment/PublicPayment/PublicPayment'));
const PublicPaymentSuccess = lazy(() => import('../containers/Payment/PublicPayment/PublicPaymentSuccess'));
const PublicPaymentFail = lazy(() => import('../containers/Payment/PublicPayment/PublicPaymentFail'));
const PaymentReturn = lazy(() => import('../containers/Payment/PaymentReturn/PaymentReturn'));
const Register = lazy(() => import('../containers/Auth/Register/Register'));
const RegisterRedirect = lazy(() => import('../containers/Auth/RegisterRedirect/RegisterRedirect'));
const TermsAndConditions = lazy(() => import('../containers/About/TermsAndConditions/TermsAndConditions'));
const TransactionConfirm = lazy(() => import('../containers/Transaction/TransactionConfirm'));
const Home = lazy(() => import('../containers/Home/Home'));

const defaultTitle = 'Send And Receive Money With ' + config.REACT_APP_TENANT_SHORT_NAME;

const Router = (props) => {
    const { childProps } = props;
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

    return (
        <>
            <Suspense fallback={<Fallback />}>
                <Switch>
                    {routes.map((route, index) => {
                        return (
                            <AuthenticatedRoute
                                path={route.path}
                                exact
                                component={route.component}
                                title={route.title}
                                props={{
                                    ...childProps,
                                    ...route.props,
                                }}
                                key={index}
                            />
                        );
                    })}

                    <UnauthenticatedRoute path="/login" exact component={Login} title={'Login - ' + defaultTitle} props={childProps} />

                    <UnauthenticatedRoute
                        path="/register"
                        exact
                        component={Register}
                        title={'Register - ' + defaultTitle}
                        props={childProps}
                    />

                    <UnauthenticatedRoute
                        path="/register/redirect"
                        exact
                        component={RegisterRedirect}
                        title={'Register Redirect - ' + defaultTitle}
                        props={childProps}
                    />

                    <UnauthenticatedRoute
                        path="/account/forgotpassword"
                        exact
                        component={ForgotPassword}
                        title={'Forgot Password - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path={'/auth/confirm-account/:token'}
                        exact
                        component={ConfirmEmail}
                        title={'Confirm Email - ' + config.REACT_APP_TENANT_SHORT_NAME}
                        props={childProps}
                    />

                    <CommonRoute
                        path={'/auth/newpassword/:token'}
                        exact
                        component={ChangePassword}
                        title={'Set Password - ' + config.REACT_APP_TENANT_SHORT_NAME}
                        props={{ ...childProps, newPassMode: true }}
                    />

                    <CommonRoute
                        path={'/auth/resetpassword/:token'}
                        exact
                        component={ChangePassword}
                        title={'Reset Password - ' + config.REACT_APP_TENANT_SHORT_NAME}
                        props={{ ...childProps, newPassMode: false }}
                    />

                    <CommonRoute
                        path={'/about/privacypolicy'}
                        exact
                        component={PrivacyPolicy}
                        title={'Privacy Policy - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path={'/about/termsandconditions'}
                        exact
                        component={TermsAndConditions}
                        title={'Terms and Conditions - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/auth/processor/banksign"
                        exact
                        component={BankSign}
                        title={'BankSign - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/auth/processor/freja"
                        exact
                        component={Freja}
                        title={'Freja - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/auth/processor/signicat"
                        exact
                        component={Signicat}
                        title={'Authentication - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/auth/processor/zignsec"
                        exact
                        component={ZignSec}
                        title={'Authentication - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/transaction/confirm/:processor"
                        exact
                        component={TransactionConfirm}
                        title={'Transaction Confirm - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/auth/processor/mobile"
                        exact
                        component={AuthProcessorMobile}
                        title={'Auth Processor - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/auth/processor/:authprocessormode"
                        exact
                        component={AuthProcessor}
                        title={'Auth Processor - ' + defaultTitle}
                        props={childProps}
                    />

                    <UnauthenticatedRoute
                        path="/auth/public/processor/mobile"
                        exact
                        component={AuthProcessorMobile}
                        title={'Auth Processor - ' + defaultTitle}
                        props={childProps}
                    />

                    <UnauthenticatedRoute
                        path="/transaction/public/confirm/mobile"
                        exact
                        component={AuthProcessorMobile}
                        title={'Transaction Confirm - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/auth/processor"
                        exact
                        component={AuthProcessor}
                        title={'Auth Processor - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/remit/paymentmethodlink/:transactionId"
                        exact
                        component={PaymentMethodLink}
                        title={'Payment Method Link - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/public/remit/payment"
                        exact
                        component={Payment}
                        title={'Payment - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/public/payment/success"
                        exact
                        component={PublicPaymentSuccess}
                        title={'Payment Successful - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/public/payment/success/:transactionReference/:message"
                        exact
                        component={PublicPaymentSuccess}
                        title={'Payment Successful - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/public/payment/failed"
                        exact
                        component={PublicPaymentFail}
                        title={'Payment Failed - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/public/payment/failed/:errorCode/:errorMessage"
                        exact
                        component={PublicPaymentFail}
                        title={'Payment Failed - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/public/payment/warn"
                        exact
                        component={PublicPaymentFail}
                        title={'Payment Failed - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/public/payment/warn/:errorCode/:errorMessage"
                        exact
                        component={PublicPaymentFail}
                        title={'Payment Failed - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/public/payment/expiry"
                        exact
                        component={PublicPaymentFail}
                        title={'Payment Failed - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/public/payment/expiry/:errorCode/:errorMessage"
                        exact
                        component={PublicPaymentFail}
                        title={'Payment Failed - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/public/payment/cancel"
                        exact
                        component={PublicPaymentFail}
                        title={'Payment Failed - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/public/payment/cancel/:errorCode/:errorMessage"
                        exact
                        component={PublicPaymentFail}
                        title={'Payment Failed - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/public/payment/warning"
                        exact
                        component={PublicPaymentFail}
                        title={'Payment Failed - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/public/payment/warning/:errorCode/:errorMessage"
                        exact
                        component={PublicPaymentFail}
                        title={'Payment Failed - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/public/payment"
                        exact
                        component={PublicPayment}
                        title={'Payment - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/:mode/payment/:psp/:status/:ref"
                        exact
                        component={PaymentReturn}
                        title={'Payment - ' + defaultTitle}
                        props={childProps}
                    />

                    <CommonRoute
                        path="/:mode/payment/:psp/:status"
                        exact
                        component={PaymentReturn}
                        title={'Payment - ' + defaultTitle}
                        props={childProps}
                    />

                    {config.REACT_APP_REMIT_PAGE_MODE === 'PUBLIC' && (
                        <CommonRoute
                            path="/remit/:countryCode"
                            exact
                            component={Home}
                            title={'Send And Receive Money With ' + config.REACT_APP_TENANT_SHORT_NAME}
                            props={childProps}
                        />
                    )}
                    {config.REACT_APP_REMIT_PAGE_MODE === 'PUBLIC' && (
                        <CommonRoute
                            path="/remit"
                            exact
                            component={Home}
                            title={'Send And Receive Money With ' + config.REACT_APP_TENANT_SHORT_NAME}
                            props={childProps}
                        />
                    )}
                    {config.REACT_APP_REMIT_PAGE_MODE === 'PUBLIC' && (
                        <CommonRoute
                            path="/"
                            exact
                            component={Home}
                            title={'Send And Receive Money With ' + config.REACT_APP_TENANT_SHORT_NAME}
                            props={childProps}
                        />
                    )}

                    <Route
                        props={childProps}
                        render={(routeProps) => (
                            <Redirect
                                to={
                                    isLoggedIn
                                        ? config.REACT_APP_REMIT_PAGE_MODE === 'PUBLIC'
                                            ? '/remit'
                                            : '/remit/quote'
                                        : config.REACT_APP_REMIT_PAGE_MODE === 'PUBLIC'
                                        ? '/remit'
                                        : '/login'
                                }
                            />
                        )}
                    />
                </Switch>
            </Suspense>
        </>
    );
};

export default Router;
