import Jsona from 'jsona';
import axios from 'axios';
import _ from 'lodash';
import http from '../api/http';

import {
  checkTransactionsInComplianceStatus,
  setAppNotification,
} from './app-actions';
import { transactionActions } from '../slices/transaction-slice';
import { otpActions } from '../slices/otp-slice';
import { remitActions } from '../slices/remit-slice';
import { isEmpty } from '../../library/utils';
import config from '../../config';

export const fetchTransactions = (params) => {
  const apiBaseUrl =
    config.REACT_APP_IDENTITY_BASE_URL + config.REACT_APP_REMITTANCE;

  const request = http.get(apiBaseUrl, { params });

  return (dispatch) => {
    dispatch(
      transactionActions.setLoading({
        isLoading: true,
        willClearError: true,
      })
    );

    return request.then(
      ({ data }) => {
        const transactionsData = new Jsona().deserialize(data);

        dispatch(transactionActions.setTransactions(transactionsData));
      },
      (error) => {
        let errorMessage = config.generalError;

        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            errorMessage = error.response.data.message;
          } else if (
            error.response.data.errors.message &&
            typeof error.response.data.errors.message === 'string'
          ) {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Fetch transaction failed!';
          }
        }

        console.log('Transaction fetchTransactions error: ' + errorMessage);

        dispatch(transactionActions.setError(errorMessage));
      }
    );
  };
};

export const fetchTransaction = (
  transactionId,
  errorCallback,
  accessToken,
  warningCallback
) => {
  const apiBaseUrl =
    config.REACT_APP_IDENTITY_BASE_URL +
    config.REACT_APP_REMITTANCE +
    '/' +
    transactionId;

  let request;
  if (accessToken) {
    request = axios.get(apiBaseUrl, {
      headers: {
        'X-Frame-Options': 'sameorigin',
        'X-XSS-Protection': '1; mode=block',
        'Strict-Transport-Security':
          'max-age=63072000; includeSubDomains; preload',
        'X-Content-Type-Options': 'nosniff',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  } else {
    request = http.get(apiBaseUrl, { params: { include: 'documents' } });
  }

  return (dispatch) => {
    dispatch(
      transactionActions.setLoading({
        isLoading: true,
        willClearError: true,
      })
    );

    return request.then(
      (response) => {
        const { data } = response;
        const transactionData = new Jsona().deserialize(data);
        const statusId = transactionData?.statusId;

        if (!isEmpty(warningCallback) && statusId === 514) {
          const status = transactionData?.status;
          warningCallback(statusId, status);
        } else {
          dispatch(transactionActions.setSelectedTransaction(transactionData));
        }
      },
      (error) => {
        let errorMessage = config.generalError;
        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            errorMessage = error.response.data.message;
          } else if (
            error.response.data.errors.message &&
            typeof error.response.data.errors.message === 'string'
          ) {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Fetch transaction failed!';
          }
        }

        console.log('Transaction fetchTransaction error: ' + errorMessage);

        dispatch(transactionActions.setError(errorMessage));

        if (!isEmpty(errorCallback)) {
          errorCallback();
        }
      }
    );
  };
};

export const fetchPublicTransaction = (transactionId, token, errorCallback) => {
  const apiBaseUrl =
    config.REACT_APP_IDENTITY_BASE_URL +
    config.REACT_APP_REMITTANCE_CARDPAYMENT +
    '/transactiondetails/' +
    transactionId;

  const configs = {
    params: {
      tenant: config.REACT_APP_TENANT,
      token,
    },
  };

  const request = http.get(apiBaseUrl, configs);

  return (dispatch) => {
    dispatch(
      transactionActions.setLoading({
        isLoading: true,
        willClearError: true,
      })
    );

    return request.then(
      (response) => {
        const { data } = response;
        const transactionData = new Jsona().deserialize(data);

        dispatch(transactionActions.setSelectedTransaction(transactionData));
      },
      (error) => {
        let errorMessage = config.generalError;
        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            errorMessage = error.response.data.message;
          } else if (
            error.response.data.errors.message &&
            typeof error.response.data.errors.message === 'string'
          ) {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Fetch transaction failed!';
          }
        }

        console.log(
          'Transaction fetchPublicTransaction error: ' + errorMessage
        );

        dispatch(transactionActions.setError(errorMessage));

        if (!isEmpty(errorCallback)) {
          errorCallback();
        }
      }
    );
  };
};

export const initiateTransaction = (
  payload,
  isOTPBypassed,
  history,
  rerouteURL,
  showFailInNotification
) => {
  const apiBaseUrl =
    config.REACT_APP_IDENTITY_BASE_URL + config.REACT_APP_REMITTANCE;

  const params = {
    data: {
      type: 'remittance',
      attributes: {
        ...payload,
      },
    },
  };

  const request = http.post(apiBaseUrl, params);

  return (dispatch) => {
    dispatch(
      transactionActions.setLoading({
        isLoading: true,
        willClearError: true,
      })
    );

    return request.then(
      ({ data }) => {
        const transactionData = new Jsona().deserialize(data);

        if (isOTPBypassed) {
          dispatch(otpActions.setOTPSuccess());
        } else {
          dispatch(otpActions.setOTPReceived());
        }

        dispatch(remitActions.setRemitTransaction(payload));
        dispatch(
          transactionActions.setInitiatedTransaction({
            ...transactionData,
            payinId: payload.externalId,
            reference: payload.externalId,
          })
        );

        if (rerouteURL) {
          history.push(rerouteURL);
        }
      },
      (error) => {
        let errorMessage = config.generalError;

        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            errorMessage = error.response.data.message;
          } else if (
            error.response.data.errors.message &&
            typeof error.response.data.errors.message === 'string'
          ) {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Initiate transaction failed!';
          }
        }

        console.log('Transaction initiateTransaction error: ' + errorMessage);

        let setErrorMessage = 'Initiate transaction failed!';

        if (errorMessage?.includes('compliance')) {
          setErrorMessage =
            'You have a transaction pending compliance approval.';
        }

        if (errorMessage?.includes('limit')) {
          setErrorMessage = errorMessage;
        }

        dispatch(transactionActions.setError(setErrorMessage));

        if (showFailInNotification) {
          dispatch(
            setAppNotification(
              'Something went wrong. Please contact system admin.',
              'error'
            )
          );
        }
      }
    );
  };
};

export const checkSendingLimitAndInitiateTransaction = (
  userId,
  sendingCurrency,
  sendAmount,
  payload,
  isOTPBypassed,
  history,
  rerouteURL,
  showFailInNotification
) => {
  const apiBaseUrl =
    config.REACT_APP_IDENTITY_BASE_URL + config.REACT_APP_USER_URI + userId;

  const requestConfig = {
    params: {
      include: 'account.amountLimits,account',
    },
  };

  const request = http.get(apiBaseUrl, requestConfig);

  return (dispatch) => {
    return request.then(
      ({ data }) => {
        let fetchGlobal = false;
        let isAmountLimitError = false;
        let userAmountLimit = null;

        if (config.REACT_APP_SENDING_AMOUNT_LIMITS_ENABLED === 'true') {
          const amountLimitIncludedList = data.included.filter(
            (i) => i.type === 'amountlimit'
          );

          if (amountLimitIncludedList && amountLimitIncludedList.length > 0) {
            let amountLimitRelationship = [];

            if (
              data.data.relationships &&
              data.data.relationships.amountlimit &&
              data.data.relationships.amountlimit.data
            ) {
              amountLimitRelationship = new Jsona().deserialize({
                data: _.isArray(data.data.relationships.amountlimit.data)
                  ? [
                    ...amountLimitIncludedList.filter((al) =>
                      data.data.relationships.amountlimit.data.some(
                        (al2) => al2.data.id === al.id
                      )
                    ),
                  ]
                  : [
                    amountLimitIncludedList.find(
                      (al) =>
                        al.id === data.data.relationships.amountlimit.data.id
                    ),
                  ],
              });
            }

            if (
              sendingCurrency &&
              amountLimitRelationship.some(
                (al) =>
                  al.currencyCode.toUpperCase() ===
                  sendingCurrency.toUpperCase()
              )
            ) {
              const amountLimit = amountLimitRelationship.find(
                (al) =>
                  al.currencyCode.toUpperCase() ===
                  sendingCurrency.toUpperCase()
              );

              if (!amountLimit.isLocked) {
                userAmountLimit = amountLimit;
                fetchGlobal = true;
              } else if (
                amountLimit.isLocked &&
                sendAmount > amountLimit.maximum - amountLimit.used
              ) {
                isAmountLimitError = true;
              }
            } else {
              fetchGlobal = true;
            }
          } else {
            fetchGlobal = true;
          }
        }

        if (fetchGlobal) {
          dispatch(
            fetchGlobalAmountLimits((globalAmountLimits) => {
              if (
                globalAmountLimits.amountlimit.some(
                  (al) =>
                    al.currencyCode.toUpperCase() ===
                    sendingCurrency.toUpperCase()
                )
              ) {
                const globalAmountLimit = globalAmountLimits.amountlimit.find(
                  (al) =>
                    al.currencyCode.toUpperCase() ===
                    sendingCurrency.toUpperCase()
                );

                if (
                  (!isEmpty(userAmountLimit) &&
                    sendAmount >
                    globalAmountLimit.maximum - userAmountLimit.used) ||
                  sendAmount > globalAmountLimit.maximum
                ) {
                  dispatch(remitActions.setSendingAmountLimitError());
                  // dispatch(
                  //   remitActions.setLoading({
                  //     isLoading: false,
                  //     willClearError: true,
                  //   })
                  // );
                } else {
                  dispatch(
                    initiateTransaction(
                      payload,
                      isOTPBypassed,
                      history,
                      rerouteURL,
                      showFailInNotification
                    )
                  );
                }
              }
            }, showFailInNotification)
          );
        } else {
          if (isAmountLimitError) {
            dispatch(remitActions.setSendingAmountLimitError());
          } else {
            dispatch(
              initiateTransaction(
                payload,
                isOTPBypassed,
                history,
                rerouteURL,
                showFailInNotification
              )
            );
          }
        }
      },
      (error) => {
        let errorMessage = config.generalError;

        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            errorMessage = error.response.data.message;
          } else if (
            error.response.data.errors.message &&
            typeof error.response.data.errors.message === 'string'
          ) {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Get user data failed!';
          }
        }

        console.log(
          'Transaction checkSendingLimitAndInitiateTransaction error: ' +
          errorMessage
        );

        dispatch(transactionActions.setError('Get user data failed!'));

        if (showFailInNotification) {
          dispatch(
            setAppNotification(
              'Something went wrong. Please contact system admin.',
              'error'
            )
          );
        }
      }
    );
  };
};

export const fetchGlobalAmountLimits = (callback, showFailInNotification) => {
  const apiBaseUrl =
    config.REACT_APP_IDENTITY_BASE_URL + config.REACT_APP_USER_MANAGER_SETTINGS;

  const request = http.get(apiBaseUrl);

  return (dispatch) => {
    return request.then(
      ({ data }) => {
        const res = new Jsona().deserialize(data);

        callback(res);
      },
      (error) => {
        let errorMessage = config.generalError;

        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            errorMessage = error.response.data.message;
          } else if (
            error.response.data.errors.message &&
            typeof error.response.data.errors.message === 'string'
          ) {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Login failed!';
          }
        }

        console.log('Auth fetchGlobalAmountLimits error: ' + errorMessage);

        dispatch(
          transactionActions.setError('Get global settings data failed!')
        );

        if (showFailInNotification) {
          dispatch(
            setAppNotification(
              'Something went wrong. Please contact system admin.',
              'error'
            )
          );
        }
      }
    );
  };
};

export const editTransactionStatus = (transactionId, values) => {
  const apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL.concat(
    `api/v1/bank-payouts/${transactionId}`
  );

  const params = {
    data: {
      type: 'Transaction',
      attributes: {
        ...values,
      },
    },
  };

  const request = http.patch(apiBaseUrl, params);

  return (dispatch) => {
    dispatch(
      transactionActions.setLoading({
        isLoading: true,
        willClearError: true,
      })
    );

    request.then(
      ({ data }) => {
        dispatch(transactionActions.clearLoading());
      },
      (error) => {
        let errorMessage = config.generalError;

        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            errorMessage = error.response.data.message;
          } else if (
            error.response.data.errors.message &&
            typeof error.response.data.errors.message === 'string'
          ) {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Edit transaction status failed!';
          }
        }

        console.log('Transaction editTransactionStatus error: ' + errorMessage);

        dispatch(
          transactionActions.setError('Edit transaction status  failed!')
        );
      }
    );
  };
};

export const updateDeferredTransaction = (
  paymentOptionId,
  remitTransaction,
  isOTPBypassed,
  history,
  rerouteURL
) => {
  const apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL.concat(
    `api/v1/bank-payouts/deferred-payments/${remitTransaction.id}`
  );

  const params = {
    data: {
      type: 'Transaction',
      attributes: {
        paymentOptionId,
        statusDescription: remitTransaction.status,
      },
    },
  };

  const request = http.patch(apiBaseUrl, params);

  return (dispatch) => {
    dispatch(
      transactionActions.setLoading({
        isLoading: true,
        willClearError: true,
      })
    );

    request.then(
      ({ data }) => {
        const transactionData = new Jsona().deserialize(data);

        if (isOTPBypassed) {
          dispatch(otpActions.setOTPSuccess());
        } else {
          dispatch(otpActions.setOTPReceived());
        }

        dispatch(
          remitActions.setRemitTransaction({
            ...remitTransaction,
            payinId: remitTransaction.payinId,
            statusCode: transactionData.statusId,
            statusDescription: transactionData.status,
            paymentOptionId,
          })
        );
        dispatch(
          transactionActions.setInitiatedTransaction({
            ...transactionData,
            statusCode: transactionData.statusId,
            statusDescription: transactionData.status,
            payinId: remitTransaction.externalId,
            reference: remitTransaction.externalId,
          })
        );

        if (rerouteURL) {
          history.push(rerouteURL);
        }
      },
      (error) => {
        let errorMessage = config.generalError;

        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            errorMessage = error.response.data.message;
          } else if (
            error.response.data.errors.message &&
            typeof error.response.data.errors.message === 'string'
          ) {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Update deferred transaction status failed!';
          }
        }

        console.log(
          'Transaction updateDeferredTransaction error: ' + errorMessage
        );

        dispatch(
          transactionActions.setError(
            'Update deferred transaction status failed!'
          )
        );
      }
    );
  };
};

export const downloadTransactionReceipt = (
  transactionId,
  callback = () => { }
) => {
  const apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_TRANSACTION_RECEIPT +
    '/' +
    transactionId;

  const request = http.get(apiBaseUrl, { responseType: 'blob' });

  return (dispatch) => {
    dispatch(
      transactionActions.setLoading({
        isLoading: true,
        willClearError: true,
      })
    );
    request.then(
      ({ data }) => {
        if (callback) {
          callback(data);
        }

        dispatch(
          transactionActions.setLoading({
            isLoading: false,
            willClearError: true,
          })
        );
      },
      (error) => {
        let errorMessage = config.generalError;

        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            errorMessage = error.response.data.message;
          } else if (
            error.response.data.errors.message &&
            typeof error.response.data.errors.message === 'string'
          ) {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Download Transaction Receipt failed!';
          }
        }

        console.log(
          'Transaction downloadTransactionReceipt error: ' + errorMessage
        );

        dispatch(
          transactionActions.setError('Download Transaction Receipt failed!')
        );
      }
    );
  };
};

export const setTransactionForApproval = (
  transactionId,
  callback = () => { }
) => {
  const apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL.concat(
    `api/v1/bank-payouts/compliance-approval/${transactionId}`
  );

  const params = {
    data: {
      type: 'Transaction',
      attributes: {
        actionTakenDescription: 'Required documents uploaded',
      },
    },
  };

  const request = http.patch(apiBaseUrl, params);

  return (dispatch) => {
    dispatch(
      transactionActions.setLoading({
        isLoading: true,
        willClearError: true,
      })
    );
    request.then(
      ({ data }) => {
        if (callback) {
          callback();
        }

        dispatch(fetchTransaction(transactionId));
        dispatch(checkTransactionsInComplianceStatus());

        dispatch(
          transactionActions.setLoading({
            isLoading: false,
            willClearError: true,
          })
        );
      },
      (error) => {
        let errorMessage = config.generalError;

        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            errorMessage = error.response.data.message;
          } else if (
            error.response.data.errors.message &&
            typeof error.response.data.errors.message === 'string'
          ) {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Set Transaction For Approval failed!';
          }
        }

        console.log(
          'Transaction setTransactionForApproval error: ' + errorMessage
        );

        dispatch(
          transactionActions.setError('Set Transaction For Approval failed!')
        );
      }
    );
  };
};

export const uploadTransactionDocuments = (
  transactionId,
  documentType,
  documentTitle,
  documentNumber,
  issueDate,
  expiryDate,
  file,
  callback
) => {
  const apiBaseUrl =
    config.REACT_APP_IDENTITY_BASE_URL +
    config.REACT_APP_DOCUMENT_UPLOAD_URI +
    'transactions/' +
    transactionId;

  var formData = new FormData();

  formData.append('issueDate', issueDate);
  formData.append('expiryDate', expiryDate);
  formData.append('documentType', documentType);
  formData.append('documentTitle', documentTitle);
  formData.append('documentNumber', documentNumber);

  formData.append('file', file);

  //build form header
  http.interceptors.request.use((config) => {
    config.headers = { 'Content-Type': 'multipart/form-data' };
    return config;
  });

  const request = http.post(apiBaseUrl, formData);

  return (dispatch) => {
    dispatch(
      transactionActions.setLoading({
        isLoading: true,
        willClearError: true,
      })
    );
    dispatch(transactionActions.clearDocumentUploaded());

    request.then(
      ({ data }) => {
        dispatch(transactionActions.setDocumentUploaded());
        dispatch(fetchTransaction(transactionId));
        if (callback) {
          callback();
        }
      },
      (error) => {
        let errorMessage = config.generalError;

        try {
          if (error.messages && typeof error.messages === 'string') {
            errorMessage = error.messages;
          } else if (
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            errorMessage = error.response.data.message;
          } else if (
            error.response.data.errors.message &&
            typeof error.response.data.errors.message === 'string'
          ) {
            errorMessage = error.response.data.errors.message;
          }
        } catch {
          console.log('error.response: ', error.response);
          console.log('error.request: ', error.request);
          console.log('error.config: ', error.config);
          if (error.response) {
            errorMessage = 'Upload document failed!';
          }
        }

        console.log(
          'Transaction uploadTransactionDocuments error: ' + errorMessage
        );

        dispatch(transactionActions.setError(errorMessage));
      }
    );
  };
};

export const setInitiatedTransactionData = (transaction) => {
  return (dispatch) => {
    dispatch(transactionActions.setInitiatedTransaction(transaction));
  };
};

export const resetInitiatedTransaction = () => {
  return (dispatch) => {
    dispatch(transactionActions.clearInitiatedTransaction());
  };
};

export const resetSelectedTransaction = () => {
  return (dispatch) => {
    dispatch(transactionActions.clearSelectedTransaction());
  };
};

export const transactionSetAuthProcessorError = (errorMessage) => {
  return (dispatch) => {
    dispatch(transactionActions.setAuthProcessorError(errorMessage));
  };
};

export const transactionClearAuthProcessorError = () => {
  return (dispatch) => {
    dispatch(transactionActions.clearAuthProcessorError());
  };
};

export const resetTransactionError = () => {
  return (dispatch) => {
    dispatch(transactionActions.clearError());
  };
};

export const resetTransactions = () => {
  return (dispatch) => {
    dispatch(transactionActions.clearTransactionsState());
  };
};
